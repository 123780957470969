export const randomValue = (obj: Record<string, string>) => {
  const keys = Object.keys(obj)
  return obj[keys[(keys.length * Math.random()) << 0]]
}

export const randomValueArray = (list: Array<string>) => {
  return list[Math.floor(Math.random() * list.length)]
}

/**
 * Generates an image frmo our serverless handler
 * https://d31pkvr764fj0m.cloudfront.net/index.html
 */
const cloudfrontUrl = "https://d7lplzyvmh3pr.cloudfront.net"
const bucket = "featureeditor56e96836589c4e1ca49e3a21f83a15f5121955-dev"

export const generateServerlessImageUrl = (
  imageKey: string,
  options: Record<string, any> = {}
) => {
  const key = "public/" + imageKey?.split("s3:")[1]
  return (
    key &&
    `${cloudfrontUrl}/${btoa(JSON.stringify({ bucket, key, edits: options }))}`
  )
}

/**
  * 
  
 ApiEndpoint
 https://d7lplzyvmh3pr.cloudfront.net
 Link to API endpoint for sending image requests to.
 -
 CorsEnabled
 Yes
 Indicates whether Cross-Origin Resource Sharing (CORS) has been enabled for the image handler API.
 -
 CorsOrigin
 *
 Origin value returned in the Access-Control-Allow-Origin header of image handler API responses.
 -
 DemoUrl
 https://d31pkvr764fj0m.cloudfront.net/index.html
 Link to the demo user interface for the solution.
 -
 LogRetentionPeriod
 1
 Number of days for event logs from Lambda to be retained in CloudWatch.
 -
 SourceBuckets
 featureeditor56e96836589c4e1ca49e3a21f83a15f5121955-dev
 Amazon S3 bucket location containing original image files
  * 
  */
