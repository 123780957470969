import React from "react"
import { Box, useTheme } from "@material-ui/core"
import { InfoButton } from "./InfoButton"
import { Skrim } from "@citydna/common"
import { useConfig } from "@citydna/experience"
import { ReferencesButton } from "./References/ReferencesButton"
import { FilterButton } from "./FilterButton"
import { useCurrentRoom } from "@citydna/platform"
import { ProjectionButton } from "./ProjectionButton"
import { AvatarButton } from "./AvatarButton"

export const Controls: React.FC = () => {
  const config = useConfig()
  const { me, subscribed } = useCurrentRoom()
  const theme = useTheme()

  return (
    <>
      <Box
        display="flex"
        width={1}
        justifyContent="center"
        alignItems="flex-end"
        position="fixed"
        bottom={48}
        left={0}
        mb={2}
        zIndex={9}
      >
        <AvatarButton subscribed={subscribed} icon={me?.info.icon || ""} />
        {config?.filterComponent ? <FilterButton /> : null}
        {["aboriginal-melbourne"].includes(config?.id) && <ReferencesButton />}
        {["citydna"].includes(config?.id) && <ProjectionButton />}
        <InfoButton />
      </Box>
      <Skrim
        angle={0}
        position="fixed"
        bottom={0}
        left={0}
        height={140}
        zIndex={8}
        width={1}
        skrimColor={theme.palette.type}
      />
    </>
  )
}
