import React, { forwardRef } from "react";
import { Box, useTheme, IconButton, makeStyles, darken, } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    // @ts-ignore
    root: props => ({
        "&:hover": {
            backgroundColor: darken(
            // @ts-ignore
            props.color || theme.palette.background.paper, 0.1),
        },
    }),
}));
export const MarkerDot = forwardRef(({ boxShadow = 4, color, children, title, ...props }, ref) => {
    const classes = useStyles({ color });
    const theme = useTheme();
    return (React.createElement(IconButton, { title: title, component: Box, ref: ref, position: "relative", zIndex: 2, width: 48, height: 48, borderRadius: 24, boxShadow: boxShadow, bgcolor: color ? color : theme.palette.background.paper, display: "flex", alignItems: "center", justifyContent: "center", className: classes.root, ...props }, children));
});
