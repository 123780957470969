import React from "react"
import { Box, Typography, Button, makeStyles, Theme } from "@material-ui/core"
import { Player } from "@lottiefiles/react-lottie-player"

const useStyles = makeStyles((theme: Theme) => ({
  buttonStyle: {
    color: theme.palette.common.white,
    backgroundColor: "#277BB4",
    width: 157,
  },
}))

interface ScreenProps {
  graphicUrl: string
  title: string
  description: string
  buttonText: string
  buttonAction: () => void
}

export const Screen: React.FC<ScreenProps> = ({
  graphicUrl,
  title,
  description,
  buttonText,
  buttonAction,
}) => {
  const classes = useStyles()

  return (
    <Box height={1} display="flex" alignItems="center">
      <Box
        height="80%"
        p={4}
        display="flex"
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
      >
        <Player autoplay loop src={graphicUrl} style={{ height: 200 }} />
        <Box>
          <Box pb={2}>
            <Typography variant="h1" align="center">
              {title}
            </Typography>
          </Box>
          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Box>
        <Button
          className={classes.buttonStyle}
          variant="contained"
          color="secondary"
          onClick={buttonAction}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}
