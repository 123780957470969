import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react"
import { useConfig } from "@citydna/experience"
import { useQuery } from "react-query"
import { getThemesData } from "./api/queries"
import { useTranslation } from "react-i18next"

type Theme = {
  title: string
  summary?: string
  id: string
  disabled: boolean
}
interface ThemesContextValue {
  themes: Array<Theme>
  activeThemes: Array<Theme> | undefined
  setActiveThemes: React.Dispatch<Array<Theme>>
}

const ThemesContext = createContext<ThemesContextValue | undefined>(undefined)

/**
 * Provides the the experience with information relevant to the categorisation of items.
 *
 * For AM this is 'themes'
 */
export const ThemesProvider: React.FC = ({ children }) => {
  const config = useConfig()
  const { t } = useTranslation<string>()

  const { data } = useQuery(
    ["appThemes", config],
    () => getThemesData(config),
    { enabled: !!config }
  )

  const themes = useMemo(() => {
    if (config?.id === "aboriginal-melbourne") {
      return data?.data?.map((theme: Record<string, any>) => ({
        title: theme.attributes.title,
        id: theme.attributes.theme_id,
        disabled: true,
        summary: theme.attributes.summary,
      }))
    }
    if (config?.id === "state-library-images") {
      return data?.data?.map((theme: Record<string, any>) => ({
        title: theme?.attributes?.type,
        id: theme?.attributes?.type,
        summary: theme?.attributes?.summary,
        disabled: true,
      }))
    }
    return data?.features.map((f: Record<string, any>) => ({
      title: f?.attributes?.dna_category,
      id: f?.attributes?.dna_category,
      disabled: true,
    }))
  }, [config?.id, data?.data, data?.features])

  const [activeThemes, setActiveThemes] = useState<Array<Theme> | undefined>()

  /** When themes become available, set the default state */
  useEffect(() => {
    setActiveThemes(themes)
  }, [themes])

  return (
    <ThemesContext.Provider value={{ themes, activeThemes, setActiveThemes }}>
      {children}
    </ThemesContext.Provider>
  )
}

export default ThemesProvider

/**
 * Retrieve information about categorisation of items, including which categories are
 * active
 */
export const useThemes = () => {
  const context = useContext(ThemesContext)
  if (!context) throw Error("Not inside <ThemesProvider />")
  return context
}
