import React, { createContext, useState, useEffect } from "react";
// create the context - this is what both the platform and the app use simultaneously.
export const AppContext = createContext(undefined);
/**
 * App context provider - passes relevant information from platform to app
 */
export const AppContextProvider = ({ children, appSettings: forcedAppSettings }) => {
    const [appSettings, setAppSettings] = useState(forcedAppSettings);
    useEffect(() => {
        setAppSettings(forcedAppSettings);
    }, [forcedAppSettings]);
    return (React.createElement(AppContext.Provider, { value: {
            appSettings,
            setAppSettings: (value) => {
                setAppSettings(value);
            },
        } }, children));
};
