/** @module @citydna/common/CounterInput */
import React from "react";
import { Box, ButtonBase, FilledInput, FormLabel, useTheme, makeStyles, } from "@material-ui/core";
import { Remove as RemoveIcon } from "@material-ui/icons";
import { Add as AddIcon } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: 0,
        width: theme.spacing(13),
    },
    input: {
        textAlign: "center",
    },
    icon: {
        fontSize: theme.spacing(5),
    },
}));
/**
 * For when you need a counter. Extends [`@material-ui/core/FilledInput`](https://material-ui.com/api/filled-input/) so you can pass a `value` and `onChange` as usual.
 * Also exposes `onIncrement` and `onDecrement` so you have full control, allowing you to use decimals if you require.
 */
export const CounterInput = ({ label, onDecrement, onIncrement, ...props }) => {
    const classes = useStyles({});
    const theme = useTheme();
    return (React.createElement(Box, null,
        label && React.createElement(FormLabel, null, label),
        React.createElement(Box, { display: "flex" },
            React.createElement(ButtonBase, { onClick: onDecrement, title: "minus" },
                React.createElement(Box, { bgcolor: theme.palette.type === "dark" ? "grey.600" : "grey.300", p: 3, pl: 4, pr: 4, borderRadius: "10px 0 0 10px" },
                    React.createElement(RemoveIcon, { className: classes.icon }))),
            React.createElement(FilledInput, { disableUnderline: true, className: classes.root, inputProps: {
                    className: classes.input,
                }, ...props }),
            React.createElement(ButtonBase, { onClick: onIncrement, title: "plus" },
                React.createElement(Box, { bgcolor: theme.palette.type === "dark" ? "grey.600" : "grey.300", p: 3, pl: 4, pr: 4, borderRadius: "0 10px 10px 0" },
                    React.createElement(AddIcon, { className: classes.icon }))))));
};
