import React, { useEffect, useState, FC, memo, useCallback } from "react"
import {
  Map as WrappedMap,
  MELBOURNE_BOUNDS,
  MELBOURNE_LATITUDE,
  MELBOURNE_LONGITUDE,
  useSetViewState,
  useSetGlobalBoundsState,
  useMap,
} from "@citydna/maps"
import { useConfig } from "@citydna/experience"
import { makeStyles, Theme, Box } from "@material-ui/core"
import { useWindowHeight } from "@react-hook/window-size"
import { MapLayerMouseEvent } from "react-map-gl"
import { useTourCard } from "../common/TourCard/TourCardProvider"
import { Layers } from "./Layers"
import { Toggle3D } from "./Toggle3D"
import { SearchLayer } from "./SearchLayer"

const useStyles = makeStyles<Theme, { windowHeight: number }>((theme) => ({
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    transformOrigin: "left top",
    zIndex: 1051,
  },
  map: {
    [theme.breakpoints.only("xs")]: {
      height: ({ windowHeight }) => windowHeight - theme.spacing(4) - 4,
    },
    [theme.breakpoints.up("sm")]: {
      height: ({ windowHeight }) => windowHeight - theme.spacing(6) - 2,
    },
  },
}))

export const MAP_EASE_TO_TRANSITION_DURATION = 500

export const Map: FC = memo(() => {
  const setViewState = useSetViewState()
  const setGlobalBounds = useSetGlobalBoundsState()
  const map = useMap()
  const [is3D, setIs3D] = useState(false)
  const windowHeight = useWindowHeight()
  const classes = useStyles({ windowHeight })
  const { onOpenTourCard } = useTourCard()
  const config = useConfig()

  const interactiveMapIds = config.resources.map((item) => item.id)

  useEffect(() => {
    // this is the same viewport as the DAM mobile build
    setViewState({
      latitude: MELBOURNE_LATITUDE,
      longitude: MELBOURNE_LONGITUDE,
      zoom: 13,
    })
    setGlobalBounds(MELBOURNE_BOUNDS)
  }, [setViewState, setGlobalBounds])

  /** when 3D is toggled switch pitch and bearing at current location */
  useEffect(() => {
    if (map) {
      if (map.isMoving() || map.isZooming() || map.isRotating()) {
        map.stop()
      }
      map.easeTo({
        pitch: is3D ? 40 : 0,
        bearing: is3D ? 30 : 0,
        duration: MAP_EASE_TO_TRANSITION_DURATION,
      })
    }
  }, [map, is3D])

  const handleMapClick = ({ features, point }: MapLayerMouseEvent) => {
    if (features) {
      const { properties } = features[0]
      if (properties) {
        onOpenTourCard(properties, point)
      }
    }
  }

  const toggleViewDimension = useCallback(() => {
    setIs3D(!is3D)
  }, [setIs3D, is3D])

  return (
    <Box className={classes.map} flexGrow={1}>
      <Toggle3D is3D={is3D} toggle={toggleViewDimension} />
      <WrappedMap
        style={{
          width: "100%",
          height: "100%",
        }}
        attributionControl={false}
        mapStyle={process.env.REACT_APP_MAPBOX_STYLE_URL}
        onClick={handleMapClick}
        mapboxApiAccessToken={
          process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN_BASEMAP as string
        }
        minZoom={12}
        interactiveLayerIds={interactiveMapIds}
      >
        <SearchLayer />
        <Layers is3D={is3D} />
      </WrappedMap>
    </Box>
  )
})
