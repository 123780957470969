import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpApi from "i18next-http-backend"
import { ENVIRONMENT, S3_CONFIG_BUCKET_URL } from "./constants"

export default i18n
  .use(HttpApi)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en",
    nsSeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: (languages: string[]) => {
        const params = new URLSearchParams(window.location.search)
        const experience = params.get("e") || "citydna"
        return `${S3_CONFIG_BUCKET_URL}/${ENVIRONMENT}/${experience}/{{lng}}.json`
      },
    },
  })
