import React from "react";
import { Box } from "@material-ui/core";
export const Polygon = ({ color, width = 6, height = 6 }) => {
    return React.createElement(Box, { width: 6, height: 6, bgcolor: color });
};
export const Line = ({ color, width = 15, height = 0 }) => {
    return (React.createElement(Box, { display: "flex", justifyContent: "center" },
        React.createElement(Box, { borderBottom: 2, color: color, lineHeight: "0.1em", textAlign: "left", width: width })));
};
export const Point = ({ color, width, height }) => {
    return (React.createElement(Box, { width: width, height: height, borderRadius: "50%", bgcolor: color }));
};
