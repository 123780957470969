import * as React from "react";
function SvgPickContentFlat({ title, titleId, ...props }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 349.237 480", "aria-labelledby": titleId, ...props },
        title ? React.createElement("title", { id: titleId }, title) : null,
        React.createElement("g", { fill: "#f2f3f4" },
            React.createElement("rect", { x: 110.772, y: 140.322, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 156.295, y: 140.322, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 201.819, y: 140.322, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 110.772, y: 186.414, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 156.295, y: 186.414, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 201.819, y: 186.414, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 110.772, y: 232.507, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 156.295, y: 232.507, width: 36.646, height: 36.685, rx: 3.894 }),
            React.createElement("rect", { x: 201.819, y: 232.507, width: 36.646, height: 36.685, rx: 3.894 })),
        React.createElement("g", { fill: "#f2f3f4" },
            React.createElement("path", { d: "M269.726 63.117H79.511A9.511 9.511 0 0070 72.628v100.225l19.021 10.984v-61.788h171.194v275.813H89.021V225.677L70 236.661v170.711a9.511 9.511 0 009.511 9.511h190.215a9.511 9.511 0 009.511-9.511V72.628a9.511 9.511 0 00-9.511-9.511zm-118.884 40.4H104.5a5.246 5.246 0 110-10.491h46.343a5.246 5.246 0 010 10.491z" }),
            React.createElement("path", { d: "M57.99 228.584l41.263-23.827L57.99 180.93v15.863H18.927v15.928H57.99v15.863z" }))));
}
const MemoSvgPickContentFlat = React.memo(SvgPickContentFlat);
export default MemoSvgPickContentFlat;
