import React from "react";
import { Portal, Dialog as MuiDialog, DialogContent, IconButton, DialogTitle, makeStyles, Typography, DialogActions, } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles(theme => ({
    title: {
        paddingRight: ({ onClose }) => (onClose ? theme.spacing(8) : 0),
    },
}));
/**
 * A full-screen dialog with an optional close button (pass a function to `onClose` prop to display it).
 * Extends [`@material-ui/core/Dialog`](https://material-ui.com/api/dialog/).
 */
export const Dialog = ({ open, onClose, children, fullScreen = true, title, actions, ...rest }) => {
    /** Get scoped CSS classes */
    const classes = useStyles({ onClose: Boolean(onClose) });
    return (React.createElement(Portal, null,
        React.createElement(MuiDialog, { fullScreen: fullScreen, open: open, onClose: onClose, ...rest },
            title && (React.createElement(DialogTitle, { disableTypography: true, className: classes.title },
                React.createElement(Typography, { variant: "h2" }, title))),
            React.createElement(DialogContent, null,
                onClose && (React.createElement(IconButton, { style: {
                        position: "absolute",
                        right: fullScreen ? 24 : 0,
                        top: fullScreen ? 24 : 0,
                    }, onClick: onClose, title: "Close dialog" },
                    React.createElement(CloseIcon, { style: { fontSize: 36 } }))),
                children),
            actions && React.createElement(DialogActions, null, actions))));
};
