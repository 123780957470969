import * as React from "react";
function SvgContentQueuedFlat({ title, titleId, ...props }) {
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 800 535.374", "aria-labelledby": titleId, ...props },
        title ? React.createElement("title", { id: titleId }, title) : null,
        React.createElement("path", { d: "M762.405 288.274H653.552a24 24 0 00-24 24v108.853a24 24 0 0024 24h108.853a24 24 0 0024-24V312.274a24 24 0 00-24-24z", fill: "#f2f3f4", "data-name": "Square 3" }),
        React.createElement("path", { d: "M582.782 288.274H473.929a24 24 0 00-24 24v108.853a24 24 0 0024 24h108.853a24 24 0 0024-24V312.274a24 24 0 00-24-24z", fill: "#f2f3f4", "data-name": "Square 2" }),
        React.createElement("path", { d: "M403.158 288.274H294.305a24 24 0 00-24 24v108.853a24 24 0 0024 24h108.853a24 24 0 0024-24V312.274a24 24 0 00-24-24z", fill: "#f2f3f4", "data-name": "Square 1" }),
        React.createElement("path", { d: "M216.652 104.458v316.669H45.6V104.458h171.052m0-24H45.6a24 24 0 00-24 24v316.669a24 24 0 0024 24h171.052a24 24 0 0024-24V104.458a24 24 0 00-24-24z", fill: "#f2f3f4" }),
        React.createElement("path", { fill: "none", stroke: "#f2f3f4", strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: 24, d: "M82.187 246.365h97.872M82.187 305.365h97.872M82.187 364.366h97.872" }),
        React.createElement("path", { d: "M162.18 179.752l-12.5-1.126a8.9 8.9 0 010-12.589l8.393-8.393 2.1-2.1a2.967 2.967 0 00-4.2-4.2l4.2-4.2a2.967 2.967 0 00-4.2-4.2l4.2-4.2a2.967 2.967 0 00-4.2-4.2l-10.491 10.491-13.653 13.653H122.7a4.414 4.414 0 00-4.151-2.967h-4.451a8.9 8.9 0 00-8.9 8.9h13.353a4.375 4.375 0 001.484-.3v6.173L107.728 182.8l-10.497 10.513a2.967 2.967 0 104.2 4.2l4.2-4.2a2.967 2.967 0 104.2 4.2l4.2-4.2a2.967 2.967 0 104.2 4.2l2.1-2.1 8.393-8.393a8.9 8.9 0 0112.589 0l1.126 12.5a4.312 4.312 0 004.27 3.7h14.861a4.314 4.314 0 004.314-4.314v-14.884a4.314 4.314 0 00-3.704-4.27zm-46.443-17.758a1.643 1.643 0 111.643-1.643 1.643 1.643 0 01-1.643 1.643z", fill: "#f2f3f4" })));
}
const MemoSvgContentQueuedFlat = React.memo(SvgContentQueuedFlat);
export default MemoSvgContentQueuedFlat;
