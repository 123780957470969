import React, { FC, useEffect, useState } from "react"
import { useTimer } from "use-timer"
import { useCurrentRoom } from "@citydna/platform"
import { ProjectionTogglePayload } from "@citydna/experience"
import { useApp, actionCreators } from "./AppState"
import { PROJECTION_DEFAULT_TIMER } from "./constants"

/**
 * Used to keep track of the platform projection state.
 *
 * TODO: An idea is to change this to a hook, then have a general 'provider' that maintains different states
 */
export const ProjectionContainer: FC = function () {
  const [, dispatch] = useApp()
  const { useEvent, emit, myID } = useCurrentRoom()
  const { start, advanceTime, status } = useTimer({
    endTime: 0,
    initialTime: PROJECTION_DEFAULT_TIMER,
    timerType: "DECREMENTAL",
    onTimeOver: () => {
      dispatch(actionCreators.projectionFinished())
    },
    onTimeUpdate: (time) => {
      dispatch(actionCreators.updateProjectionTime(time))
    },
  })
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    if (myID && !mounted) {
      emit("PROJECTION_SYNC", { member: { id: myID } })
      setMounted(true)
    }
  }, [emit, mounted, myID])

  useEvent("PROJECTION_SYNC_CONFIRM", (payload: any) => {
    if (payload.member.id === myID && payload.state.time) {
      dispatch(
        actionCreators.projectionStarted(
          payload.state.id,
          payload.state.categoryId,
          PROJECTION_DEFAULT_TIMER - payload.state.time
        )
      )
      start()
      advanceTime(PROJECTION_DEFAULT_TIMER - payload.state.time)
    }
  })

  useEvent<ProjectionTogglePayload>(
    "PROJECTION_TOGGLE_CONFIRMATION",
    (payload) => {
      if (payload.visible) {
        dispatch(
          actionCreators.projectionStarted(payload.id, payload.categoryId)
        )
        start()
      }
    }
  )

  return null
}
