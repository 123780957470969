import React, { createContext, useContext } from "react";
import { useRemoteConfig } from "../hooks/useRemoteConfig";
const ConfigContext = createContext(undefined);
/** Provider to wrap your app with */
export const ConfigProvider = ({ bucketName, experience, environment, children, }) => {
    /** Grab the config from the remote bucket */
    const [config] = useRemoteConfig({ bucketName, experience, environment });
    /**
     * null is returned as the default value so it's empty,
     * but doesn't get caught by the context === undefined
     * Error in the hook.
     */
    return (React.createElement(ConfigContext.Provider, { value: config || null }, children));
};
/** Hook to consume the context */
export const useConfig = () => {
    const context = useContext(ConfigContext);
    if (context === undefined)
        throw Error("Not inside <ConfigProvider />");
    return context;
};
