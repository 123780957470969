/** @module @citydna/common/Alert */
import React from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import { Button, Title } from "../";
/**
 * A CityDNA branded alert box. Extends the [@material-ui/core/Dialog](https://material-ui.com/components/dialogs/) component.
 * Drop it into any component and it'll work - it uses a [@material-ui/core/Portal](https://material-ui.com/components/portal/) to always be on top.
 */
export const Alert = ({ title, text, confirmText = "OK", onConfirm, cancelText = "Cancel", onCancel, open, loading = false, onClose = undefined, }) => {
    return (React.createElement(Dialog, { open: open, onClose: onClose, fullScreen: false },
        React.createElement(Box, { p: 4 },
            React.createElement(Title, { primary: title, pr: 4 }),
            text && React.createElement(Typography, { paragraph: true }, text),
            React.createElement(Button, { variant: "contained", color: "primary", onClick: onConfirm, loading: loading }, confirmText),
            onCancel && (React.createElement(Button, { onClick: onCancel, disabled: loading }, cancelText)))));
};
export default Alert;
