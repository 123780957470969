import React, { FC, useLayoutEffect } from "react"
import { Logo, useSetTheme } from "@citydna/common"
import { Box, makeStyles, Theme, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { Player } from "@lottiefiles/react-lottie-player"

const useStyles = makeStyles<Theme>((theme) => ({
  homePageContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "316px",
    margin: "-50px auto 0",
  },
  title: {
    width: "70%",
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    fontSize: "16px",
    lineHeight: "24px",
    width: "60%",
  },
  logo: {
    width: "150px",
    height: "50px",
    position: "absolute",
    bottom: "50px",
  },
}))

export const Home: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation<string>()
  const setTheme = useSetTheme()

  useLayoutEffect(() => {
    // ensure that the home page is always default dark theme
    setTheme("dark")
  }, [setTheme])

  const graphic = t<any>("welcome.graphic")

  return (
    <Box className={classes.homePageContainer}>
      <Player
        autoplay
        loop
        src={graphic}
        style={{ height: "200px", marginBottom: "16px" }}
      />
      <Typography variant="h1" align="center" className={classes.title}>
        {t("welcome.title")}
      </Typography>
      <Typography variant="h2" align="center" className={classes.subtitle}>
        {t("welcome.subtitle")}
      </Typography>
      <Box className={classes.logo}>
        <Logo />
      </Box>
    </Box>
  )
}
