import React from "react";
import { Popup as MapboxPopup } from "react-map-gl";
import { Box, Fade, makeStyles, Typography } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    root: ({ color }) => ({
        "& .mapboxgl-popup-content": {
            backgroundColor: theme.palette[color].main,
            borderRadius: 0,
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
            boxShadow: theme.shadows[3],
        },
        "& .mapboxgl-popup-anchor-top .mapboxgl-popup-tip": {
            borderBottomColor: theme.palette[color].main,
        },
        "& .mapboxgl-popup-anchor-left .mapboxgl-popup-tip": {
            borderRightColor: theme.palette[color].main,
        },
        "& .mapboxgl-popup-anchor-right .mapboxgl-popup-tip": {
            borderLeftColor: theme.palette[color].main,
        },
        "& .mapboxgl-popup-anchor-bottom .mapboxgl-popup-content": {
            left: `calc(50% - ${theme.spacing(2)}px)`,
        },
        "& .mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip": {
            borderTopColor: theme.palette[color].main,
        },
        "& .mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip": {
            borderTopColor: theme.palette[color].main,
        },
        "& .mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip": {
            borderTopColor: theme.palette[color].main,
        },
    }),
    box: {},
}));
/**
 * CityDNA branded popup or tooltip for the map. Simply pass a Title and away you go.
 */
export const Popup = ({ title, color = "primary", visible = true, children, onClick, ...props }) => {
    const classes = useStyles({ color });
    return (React.createElement(Fade, { in: visible },
        React.createElement(Box, { className: classes.root, onClick: onClick },
            React.createElement(MapboxPopup, { ...props },
                React.createElement(Box, { className: classes.box }, title && React.createElement(Typography, { variant: "h6" }, title))))));
};
export default Popup;
