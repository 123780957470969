import { atom } from "recoil";
export const viewState = atom({
    key: "viewState",
    default: {
        longitude: 0,
        /** Latitude at map center */
        latitude: 0,
        /** Map zoom level */
        zoom: 0,
        /** Map rotation bearing in degrees counter-clockwise from north */
        bearing: 0,
        /** Map angle in degrees at which the camera is looking at the ground */
        pitch: 0,
        /** Dimensions in pixels applied on each side of the viewport for shifting the vanishing point. */
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
    },
});
export const globalBoundsState = atom({
    key: "globalBoundsState",
    default: undefined,
});
export const zoomState = atom({
    key: "zoomState",
    default: undefined,
});
export const bearingState = atom({
    key: "bearingState",
    default: undefined,
});
export const deckGlLayerState = atom({
    key: "deckGlLayerState",
    default: [],
    dangerouslyAllowMutability: true,
});
