import React, { useState, FC } from "react"
import { makeStyles, Theme, Modal, Box } from "@material-ui/core"
import { Screen } from "./Screen"
import { useTranslation } from "react-i18next"
import SwiperCore, { Pagination } from "swiper"
import SwiperClass from "swiper/types/swiper-class"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"
import { actionCreators, useApp } from "../common/AppState"
import { PORTAL_OUTLET_ID } from "../common/constants"
import { useOnboarding } from "./useOnboarding"

SwiperCore.use([Pagination])

const useStyles = makeStyles((theme: Theme) => ({
  swiperContainer: {
    height: "100%",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
    "& .swiper-pagination": {
      position: "absolute",
      bottom: theme.spacing(2),
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& > span": {
        margin: 5,
        display: "block",
        borderRadius: 4,
        width: 8,
        height: 8,
        backgroundColor:
          theme.palette.type === "dark"
            ? theme.palette.grey[400]
            : theme.palette.grey[200],
        transition: theme.transitions.create(["background-color", "transform"]),
        "&.swiper-pagination-bullet-active": {
          backgroundColor: theme.palette.common.white,
        },
      },
    },
  },
  swiperSlide: {
    opacity: 0,
    transition: theme.transitions.create("opacity"),
    "&.swiper-slide-active": {
      opacity: 1,
    },
  },
  staticContainer: {
    height: "100%",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[800]
        : theme.palette.grey[100],
  },
}))

type DefaultOnboardingProps = {
  screens: any[]
  handleHideOnboarding: () => void
}

type SwipedOnboardingProps = DefaultOnboardingProps & {
  swiperProps: any
}

const SwipedOnboarding: FC<SwipedOnboardingProps> = ({
  screens,
  swiperProps,
  handleHideOnboarding,
}) => {
  const classes = useStyles()
  const [swiper, setSwiper] = useState<SwiperClass | undefined>()

  const handleClick = (index: number) => {
    if (swiper && index < screens.length - 1) {
      swiper.slideTo(index + 1)
    } else {
      handleHideOnboarding()
    }
  }

  return (
    <Swiper
      onSwiper={setSwiper}
      className={classes.swiperContainer}
      pagination
      {...swiperProps}
    >
      {Array.isArray(screens) &&
        screens.map((screen, i) => (
          <SwiperSlide key={i} className={classes.swiperSlide}>
            <Screen
              graphicUrl={screen.graphic}
              title={screen.title}
              description={screen.description}
              buttonText={screen.button}
              buttonAction={() => handleClick(i)}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

const StaticOnboarding: FC<DefaultOnboardingProps> = ({
  screens,
  handleHideOnboarding,
}) => {
  const classes = useStyles()

  const handleClick = () => {
    handleHideOnboarding()
  }

  return (
    <>
      {Array.isArray(screens) &&
        screens.map((screen, index) => (
          <Box key={index} className={classes.staticContainer}>
            <Screen
              graphicUrl={screen.graphic}
              title={screen.title}
              description={screen.description}
              buttonText={screen.button}
              buttonAction={handleClick}
            />
          </Box>
        ))}
    </>
  )
}

export const Onboarding: React.FC<{ swiperProps?: any }> = ({
  swiperProps,
}) => {
  const [, setShowOnboarding] = useOnboarding()
  const { t } = useTranslation<string>()
  const [{ onboardingOpen }, dispatch] = useApp()

  const screens = t<any>("onboarding.pages", {
    returnObjects: true,
  })

  const handleHideOnboarding = () => {
    dispatch(actionCreators.updateState({ onboardingOpen: false }))
    setShowOnboarding("false")
  }

  return (
    <Modal
      container={document.getElementById(PORTAL_OUTLET_ID)}
      open={onboardingOpen}
    >
      {screens?.length > 1 ? (
        <SwipedOnboarding
          screens={screens as any}
          swiperProps={swiperProps}
          handleHideOnboarding={handleHideOnboarding}
        />
      ) : (
        <StaticOnboarding
          screens={screens as any}
          handleHideOnboarding={handleHideOnboarding}
        />
      )}
    </Modal>
  )
}

export default Onboarding
