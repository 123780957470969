import React, { FC } from "react"
import { animated } from "@react-spring/web"
import { Box } from "@material-ui/core"
import { useProgressBar } from "./useProgressBar"

const AnimatedBox = animated(Box)

export const ProjectionProgressBar: FC<{ timeLeft: number }> = ({
  timeLeft,
}) => {
  const [progressBarProps] = useProgressBar(timeLeft)

  return (
    <AnimatedBox
      style={progressBarProps}
      height={6}
      bgcolor="secondary.main"
      position="absolute"
      bottom={0}
      left={0}
      marginLeft="0!important"
    />
  )
}
