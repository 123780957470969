import { useEffect } from "react";
import { useSnackbar } from "notistack";
export function useSnackbarError(
/** Error object */
error, 
/** Snack error to show */
message = "An error occurred", 
/** optional callback, could send error to sentry or other */
callback) {
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        if (error) {
            enqueueSnackbar(message, {
                variant: "error",
            });
            callback && callback(error);
        }
    }, [error, enqueueSnackbar, message, callback]);
}
