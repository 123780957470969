import React, { FC } from "react"
import { Box, Fab, makeStyles, Theme, Typography } from "@material-ui/core"
import { FilterList as FilterListIcon } from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { Filter } from "@citydna/common"
import { useConfig } from "@citydna/experience"
import { actionCreators, useApp } from "../common/AppState"

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    boxShadow: theme.shadows[7],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "56px",
    height: "56px",
  },
  label: {
    position: "absolute",
    left: 0,
    bottom: -theme.spacing(3),
    width: "100%",
    textAlign: "center",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[100]
        : theme.palette.common.black,
  },
}))
/**
 * Button that renders the filterButton or layersButton depending on which
 * experience is active.
 * citydna = LayersButton and action
 * aboriginal-melbourne = filtersButton and action
 */
export const FilterButton: FC = () => {
  const config = useConfig()
  const [, dispatch] = useApp()
  const classes = useStyles()
  const { t } = useTranslation<string>()

  const handleClick = () => {
    if (config.filterComponent === "Layers") {
      dispatch(actionCreators.updateState({ layersOpen: true }))
    } else if (config.filterComponent === "Filters") {
      dispatch(actionCreators.updateState({ filterOpen: true }))
    }
  }

  return (
    <>
      <Box position="relative">
        <Fab
          className={classes.fab}
          title={t(
            config.id === "citydna"
              ? "controls.layers.controlButtonTitle"
              : "controls.themesFilter.controlButtonTitle"
          )}
          onClick={handleClick}
        >
          {config.filterComponent === "Layers" ? (
            <Filter width="2em" height="2em" />
          ) : (
            <FilterListIcon />
          )}
        </Fab>
        <Typography variant="caption" className={classes.label}>
          {t(
            config.id === "citydna"
              ? "controls.layers.controlButtonLabel"
              : "controls.themesFilter.controlButtonLabel"
          )}
        </Typography>
      </Box>
    </>
  )
}
