import { FC, useEffect, useRef, useReducer, useCallback } from "react"
import { Source, Layer } from "react-map-gl"
import { useSearchLayer } from "../search/SearchLayerProvider"
import { useFitViewportToFeature } from "@citydna/maps"

type BlipState = {
  initialOpacity: number
  opacity: number
  framesPerSecond: number
  blipCount: number
  display: boolean
}
/**
 * Layer that renders and animates when a property has been searched for
 * in the search bar
 */
export const SearchLayer: FC = () => {
  const [parentFeatureGeojson, setParentFeatureGeojson] = useSearchLayer()
  const fitViewportToFeature = useFitViewportToFeature()

  const requestRef = useRef<number>(0)

  /** set up the state for the mapbox layer's animation */
  const [state, dispatch] = useReducer(
    (prev: BlipState, action: Partial<BlipState>) => ({
      ...prev,
      ...action,
    }),
    {
      initialOpacity: 0,
      opacity: 1,
      framesPerSecond: 12,
      blipCount: 0,
      display: false,
    }
  )

  /** set the viewport to the incoming base property feature */
  useEffect(() => {
    if (parentFeatureGeojson) {
      fitViewportToFeature(parentFeatureGeojson, { padding: 50 })
      dispatch({ display: true })
    }
  }, [fitViewportToFeature, parentFeatureGeojson])

  /** animate function to alter the opacity of the line and give it a pulse animation */
  const animateBoundary = useCallback(() => {
    requestRef.current = requestAnimationFrame(animateBoundary)
    const { initialOpacity, opacity, framesPerSecond, blipCount } = state
    if (blipCount >= 4) {
      dispatch({ display: false })
    }
    const newOpacity = opacity + 0.2 / framesPerSecond
    if (newOpacity >= 1) {
      dispatch({ opacity: initialOpacity, blipCount: blipCount + 1 })
    } else {
      dispatch({ opacity: newOpacity })
    }
  }, [state])

  /** use the effect to call the animation */
  useEffect(() => {
    if (parentFeatureGeojson && state.display) {
      requestRef.current = requestAnimationFrame(animateBoundary)
      return () => {
        requestRef.current && cancelAnimationFrame(requestRef.current)
      }
    }
  }, [parentFeatureGeojson, animateBoundary, state.display])

  /** if display has been triggered off then set the feature to empty and reset the animation state */
  useEffect(() => {
    if (!state.display) {
      setParentFeatureGeojson(undefined)
      dispatch({ display: false, blipCount: 0, opacity: state.initialOpacity })
    }
  }, [setParentFeatureGeojson, state.display, state.initialOpacity])

  if (!parentFeatureGeojson || !state.display) {
    return null
  }

  return (
    <Source id="search-result" type="geojson" data={parentFeatureGeojson}>
      <Layer
        id="search-result-layer"
        type="line"
        paint={{
          "line-color": "#e50e56",
          "line-width": 4,
          "line-opacity": state.opacity,
        }}
      />
    </Source>
  )
}
