export { default as MUNICIPAL_BOUNDARY } from "./municipal-boundary.json";
export const MELBOURNE_LATITUDE = -37.8136;
export const MELBOURNE_LONGITUDE = 144.9631;
export const MELBOURNE_SOUTH_LATITUDE = -37.835689673086065;
export const MELBOURNE_SOUTH_LONGITUDE = 144.96202731658764;
export const MELBOURNE_VIEWPORT = {
    latitude: MELBOURNE_LATITUDE,
    longitude: MELBOURNE_LONGITUDE,
    zoom: 14,
};
export const MELBOURNE_BOUNDS = [
    [144.9, -37.85],
    [144.99, -37.776], // Northeast coordinates
];
export const DEFAULT_MAP_ID = "wrapped-map-id";
export const LAYER_PREFIX = "dna-";
