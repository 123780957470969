/** Weirdly, properties deemed null are coming in as a string "null" */
const sanitiseProperties = (properties) => Object.entries(properties).reduce((a, [key, value]) => ({
    ...a,
    [key]: value === "null" || value === "" ? null : value,
}), {});
/** Generates a TourCardPayload from data retrieved from the API */
export const generateCardFromFeature = (
/** The function used to access the feature layer properties */
accessor, 
/** The card ID to identify it in the stack */
id, 
/** The point and polygon graphics */
{ point, polygons, lnglat }, 
/** The metadata ref field to identify the feature in AGOL */
metadataRefField) => {
    const sanitisedProperties = sanitiseProperties(point.attributes);
    return {
        id,
        point,
        polygons,
        lnglat,
        ...accessor(sanitisedProperties, metadataRefField),
    };
};
