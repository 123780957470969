/** @module @citydna/common/Button */
import React from "react";
import { makeStyles, Box, Zoom } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { default as MuiButton, } from "@material-ui/core/Button";
import { Check as CheckIcon } from "@material-ui/icons";
const useStyles = makeStyles(() => ({
    indicator: ({ size }) => ({
        position: "absolute",
        left: "50%",
        marginLeft: size === "small" ? -8 : -12,
        marginTop: size === "small" ? -8 : -14,
        color: "#fff",
    }),
    done: ({ size }) => ({
        position: "absolute",
        left: "50%",
        marginLeft: size === "small" ? -12 : -12,
        marginTop: size === "small" ? -2 : -2,
        fontSize: size === "small" ? 24 : 32,
        color: "#fff",
    }),
}));
/**
 * A Button that extends [`@material-ui/core/Button`](https://material-ui.com/api/button/), adding loading and done states.
 */
export const Button = ({ loading = false, done = false, size = "large", children, ...props }) => {
    const styles = useStyles({ size });
    return (React.createElement(MuiButton, { disabled: props.disabled || loading || done, ...props },
        children,
        React.createElement(Zoom, { in: loading },
            React.createElement(Box, { component: "span" },
                React.createElement(CircularProgress, { className: styles.indicator, size: size === "small" ? 16 : 24, "data-testid": "button-loading" }))),
        React.createElement(Zoom, { in: done },
            React.createElement(CheckIcon, { className: styles.done, "data-testid": "button-done" }))));
};
