import { useState } from "react"
import { useTimer } from "use-timer"
import { useCurrentRoom } from "@citydna/platform"
import { TourCardPayload } from "@citydna/experience"

export function useAvatarDialog() {
  const [cardState, setCardState] = useState({
    hasCardQueued: false,
    isCurrentStop: false,
  })
  const { useEvent, me } = useCurrentRoom()
  const { start, status, time } = useTimer({
    timerType: "DECREMENTAL",
    endTime: 0,
    initialTime: 20,
  })

  useEvent<TourCardPayload[]>("ALL_CARDS", (payload) => {
    const hasCardQueued = payload.some((card) => card?.owner?.id === me?.id)
    const isCurrentStop = payload[0]?.owner?.id === me?.id
    if (isCurrentStop && status !== "RUNNING") {
      start()
    }
    setCardState({
      hasCardQueued,
      isCurrentStop,
    })
  })

  return {
    ...cardState,
    currentStopTimeLeft: time,
  }
}
