import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { useInView } from "react-intersection-observer"
import { useImage } from "@citydna/experience"
import { useSpring, a } from "@react-spring/web"
import { generateServerlessImageUrl } from "../common/utils"
import { ThemeIcon } from "./ThemeIcon"
import { useConfig } from "@citydna/experience"
import { GalleryTileClickProps } from "./Gallery"

export const TILE_WIDTH = 96
export const TILE_HEIGHT = 96

const useStyles = makeStyles<
  Theme,
  {
    backgroundImage?: string
    inView: boolean
  }
>((theme) => ({
  tileWrapper: {
    position: "relative",
    width: TILE_WIDTH,
    height: TILE_HEIGHT,
  },
  tile: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "10px",
    border:
      theme.palette.type === "dark"
        ? `1px solid ${theme.palette.grey[400]}`
        : `1px solid ${theme.palette.grey[200]}`,
    background: theme.palette.grey[400],
    fontSize: 12,
    transition: theme.transitions.create(["transform", "opacity"]),
    transform: ({ inView }) => `scale(${inView ? 1 : 0.7})`,
    opacity: ({ inView }) => (inView ? 1 : 0),
    willChange: "transform, opacity",
    position: "relative",
  },
  tileBox: {
    fill: theme.palette.grey[400],
  },
  tileImage: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    zIndex: 1,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: ({ backgroundImage }) => `url(${backgroundImage})`,
    borderRadius: theme.shape.borderRadius,
    opacity: ({ backgroundImage }) => (backgroundImage ? 1 : 0),
    transition: theme.transitions.create("opacity"),
    willChange: "opacity",
  },
  tileContents: {
    position: "relative",
    zIndex: 2,
    userSelect: "none",
  },
  title: {
    userSelect: "none",
  },
  iconContainer: {
    position: "absolute",
    top: "5px",
    left: "5px",
  },
}))

type GalleryTileProps = {
  onClick: (props: GalleryTileClickProps) => void
  OBJECTID?: number
  id?: number
  title?: string
  theme?: string
  image: string | { thumbnail: string; large: string }
}

/**
 * Pass in either fields to support AM and Melbourne Historical
 */
export const GalleryTile = React.memo(
  ({ onClick, OBJECTID, id, title, theme, image }: GalleryTileProps) => {
    /** Track whether the tile is in view */
    const [ref, inView] = useInView()

    /** If thumbnail is in image object we can assume its coming from strapi, otherwise generate and fetch from s3 */
    const backgroundImage = useImage(
      typeof image !== "string"
        ? image.thumbnail
        : generateServerlessImageUrl(image, {
            resize: {
              width: 420,
              height: 420,
              fit: "inside",
            },
          }),
      !inView
    )

    const styles = useSpring({
      opacity: inView ? 1 : 0,
      scale: inView ? 1 : 0.7,
    })

    const classes = useStyles({
      backgroundImage,
      inView,
    })

    const config = useConfig()

    return (
      <div className={classes.tileWrapper} ref={ref}>
        <>
          {inView && (
            <div className={classes.iconContainer}>
              <ThemeIcon theme={theme || ""} appId={config?.id} />
            </div>
          )}

          {inView && (
            <a.div
              style={styles}
              className={classes.tile}
              data-thumbnail={backgroundImage}
              onClick={(e) =>
                onClick({
                  featureObjectId: OBJECTID,
                  thumbnail: backgroundImage,
                  id,
                  xy: {
                    x: e.pageX,
                    y: e.pageY,
                  },
                })
              }
            >
              <div
                className={classes.tileImage}
                data-thumbnail={backgroundImage}
              ></div>
              {!backgroundImage && (
                <div className={classes.tileContents}>{title}</div>
              )}
            </a.div>
          )}
        </>
      </div>
    )
  }
)
