import React, { FC, memo, useMemo } from "react"
import { useConfig } from "@citydna/experience"
import { Gallery } from "../gallery/Gallery"
import { Map } from "../map/Map"
import { Loading } from "./Loading"
import { useData } from "./DataProvider"

export const FeatureExplorer: FC = memo(() => {
  const config = useConfig()

  const Component = useMemo(() => {
    switch (config?.galleryComponent) {
      case "Gallery":
        return Gallery
      case "Map":
        return Map
      default:
        return null
    }
  }, [config])

  const data = useData()

  return Component ? <Component {...data} /> : <Loading />
})

export default FeatureExplorer
