import usePromise from "react-use-promise";
/**
 * Use this hook to fetch a config.json file from a s3 bucket.
 * @param url string that needs to contain '{{experience}}' example: 'https://citydna-configs-bucket.s3-ap-southeast-2.amazonaws.com/{{experience}}/config.json'
 * @param experience string to replace {{experience}} in url
 * @returns config
 */
export const useRemoteConfig = ({ bucketName, experience, environment, }) => {
    const config = usePromise(() => {
        return fetch(`https://${bucketName}.s3-ap-southeast-2.amazonaws.com/${environment}/${experience}/config.json`).then((res) => res.json());
    }, []);
    return config;
};
