import React, { useRef } from "react";
import { Box, IconButton, InputBase, makeStyles, } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Search as SearchIcon } from "@material-ui/icons";
/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        backgroundColor: ({ variant }) => {
            /** Sets a white background as this element is usually floating over a map */
            if (variant === "outlined") {
                return theme.palette.type === "dark"
                    ? theme.palette.grey[500]
                    : theme.palette.common.white;
            }
            /** if variant is not outlined, (i.e. contained), set a background colour. */
            return theme.palette.type === "dark"
                ? theme.palette.grey[500]
                : theme.palette.grey[200];
        },
        border: ({ variant }) => variant === "outlined"
            ? `1px solid ${theme.palette.type === "dark"
                ? theme.palette.grey[400]
                : theme.palette.grey[200]}`
            : "none",
        borderRadius: theme.shape.borderRadius,
        "& .MuiSvgIcon-root": {
            width: 24,
            height: 24,
            fill: theme.palette.type === "dark"
                ? theme.palette.grey[100]
                : theme.palette.grey[800],
        },
    },
    input: {
        flexGrow: 1,
        maxWidth: "100%",
        paddingTop: 13,
        paddingBottom: 11,
        color: theme.palette.type === "dark"
            ? theme.palette.grey[100]
            : theme.palette.grey[800],
        [theme.breakpoints.only("xs")]: {
            fontSize: 16,
        },
        "& .MuiInputBase-inputTypeSearch": {
            appearance: "none",
        },
        marginLeft: theme.spacing(1),
        /** Remove default x provided by webkit when input type="search" */
        "& .MuiInputBase-inputTypeSearch::-webkit-search-cancel-button": {
            WebkitAppearance: "none",
        },
    },
}));
export const SearchBar = ({ children, onChange, onClear, onSearch, onFocus, onBlur, forceShowClear, placeholder, value, variant = "outlined", searchIconPosition = "right", ...props }) => {
    const inputRef = useRef(null);
    /** Focus the field when the clear button is clicked */
    const handleClearClick = () => {
        onClear && onClear(inputRef.current);
    };
    /** Focus the field when the search button is clicked */
    const handleSearchClick = () => {
        /** If the onSearch prop is  */
        if (onSearch) {
            onSearch();
            return;
        }
        inputRef.current.focus();
    };
    /** Get generated classes for styling */
    const classes = useStyles({ variant });
    return (React.createElement(Box, { ...props, className: `${classes.root} ${props.className || ""}` },
        searchIconPosition === "left" ? (React.createElement(IconButton, { onClick: value || forceShowClear ? handleClearClick : handleSearchClick }, value || forceShowClear ? React.createElement(CloseIcon, null) : React.createElement(SearchIcon, null))) : null,
        children,
        React.createElement(InputBase, { type: "search", value: value, onChange: onChange, className: classes.input, placeholder: placeholder, inputRef: inputRef, onFocus: onFocus, onBlur: onBlur }),
        searchIconPosition === "right" ? (React.createElement(IconButton, { onClick: value || forceShowClear ? handleClearClick : handleSearchClick }, value || forceShowClear ? React.createElement(CloseIcon, null) : React.createElement(SearchIcon, null))) : null));
};
