import React, { FC, memo } from "react"
import { makeStyles, Theme, Typography } from "@material-ui/core"
import { TickIcon } from "@citydna/common"
import GreenlineIcon from "./assets//datasetIcons/Icon_Greenline.png"
import MetroTunnelRouteIcon from "./assets/datasetIcons/Icon_MetroTunnelRoute.png"
import MetroTunnelStationsIcon from "./assets/datasetIcons/Icon_MetroTunnelStations.png"
import PedestrianSensorsIcon from "./assets/datasetIcons/Icon_PedestrianSensors.png"
import PTVBusRoutesIcon from "./assets/datasetIcons/Icon_PTVBusRoutes.png"
import PTVTrainRouteIcon from "./assets/datasetIcons/Icon_PTVTrainRoutes.png"
import PTVTramRouteIcon from "./assets/datasetIcons/Icon_PTVTramRoutes.png"
import HistoricRouteOfYarra from "./assets/datasetIcons/Icon_HistoricRouteOfRiver.png"
import { ProjectionProgressBar } from "../../common/ProgressBar/ProjectionProgressBar"
import { useApp } from "../../common/AppState"
import { Dataset, ProjectionIconKeys } from "@citydna/experience"
import { Skeleton } from "@material-ui/lab"

const useStyles = makeStyles<
  Theme,
  { isActiveProjection: boolean; isLoading: boolean }
>((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    flexDirection: "column",
    justifyContent: "center",
  },
  top: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  icon: {
    width: "38px",
    height: "38px",
    position: "relative",
    opacity: ({ isActiveProjection }) => (isActiveProjection ? 0.7 : 1),
  },
  tickIcon: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  right: {
    marginLeft: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: ({ isLoading }) => (isLoading ? "300px" : "auto"),
  },
  footer: {
    bottom: "0px",
    width: "100%",
  },
}))

export const ProjectionDataset: FC<{
  dataset?: Dataset
  categoryId?: string
  isLoading: boolean
}> = memo(({ dataset, categoryId, isLoading }) => {
  const [{ activeProjection }] = useApp()
  const isActiveProjection =
    activeProjection.id === dataset?.id &&
    categoryId === activeProjection.categoryId
  const classes = useStyles({ isActiveProjection, isLoading })

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <div className={classes.icon}>
          {isLoading ? (
            <Skeleton variant="circle" height={38} width={38} />
          ) : (
            <img
              src={icons[dataset?.title as ProjectionIconKeys]}
              alt={dataset?.title}
            />
          )}
          {isActiveProjection && !isLoading && (
            <div className={classes.tickIcon}>
              <TickIcon />
            </div>
          )}
        </div>
        <div className={classes.right}>
          <Typography variant="h4">
            {isLoading ? <Skeleton /> : dataset?.title}
          </Typography>
          <Typography variant="body2">
            {isLoading ? <Skeleton /> : dataset?.subtitle}
          </Typography>
        </div>
      </div>

      <div className={classes.footer}>
        {!isLoading &&
          isActiveProjection &&
          activeProjection.timeLeft &&
          activeProjection.timeLeft > 0 && (
            <ProjectionProgressBar timeLeft={activeProjection.timeLeft} />
          )}
      </div>
    </div>
  )
})

const icons: Record<ProjectionIconKeys, string> = {
  Greenline: GreenlineIcon,
  "Metro Tunnel Route": MetroTunnelRouteIcon,
  "Metro Tunnel Stations": MetroTunnelStationsIcon,
  "PTV Train Routes": PTVTrainRouteIcon,
  "PTV Tram Routes": PTVTramRouteIcon,
  "Bus Routes": PTVBusRoutesIcon,
  "Night Bus Routes": PTVBusRoutesIcon,
  "Pedestrian Sensors": PedestrianSensorsIcon,
  "Original course of the Yarra River (Birrarung)": HistoricRouteOfYarra,
}
