import React, { useRef } from "react";
import { ButtonBase, Checkbox, makeStyles, lighten, Typography, useControlled, } from "@material-ui/core";
/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-end",
        width: theme.spacing(13),
        height: theme.spacing(12.5),
        border: "3px solid",
        borderColor: ({ checked }) => {
            if (theme.palette.type === "dark") {
                return checked ? "transparent" : theme.palette.grey[200];
            }
            else {
                return checked ? "transparent" : theme.palette.grey[400];
            }
        },
        backgroundColor: ({ checked, color }) => {
            if (theme.palette.type === "dark") {
                return !checked ? "transparent" : theme.palette.grey[500];
            }
            else {
                const bgcolor = theme.palette[color]?.main || theme.palette.grey[500];
                return !checked ? "transparent" : lighten(bgcolor, 0.7);
            }
        },
        transition: theme.transitions.create([
            "border",
            "border-color",
            "background-color",
        ]),
        borderRadius: 4,
        cursor: "pointer",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(2),
    },
    icon: {
        color: ({ checked, color }) => {
            if (theme.palette.type === "dark") {
                const iconColor = theme.palette[color]?.main || theme.palette.grey[200];
                return checked ? iconColor : theme.palette.grey[200];
            }
            else {
                const iconColor = theme.palette[color]?.main || theme.palette.grey[400];
                return checked ? iconColor : theme.palette.grey[400];
            }
        },
    },
    control: {
        pointerEvents: "none",
        position: "absolute",
        top: 0,
        right: 0,
        color: () => {
            if (theme.palette.type === "dark") {
                return theme.palette.grey[200];
            }
            else {
                return "inherit";
            }
        },
    },
    label: {
        fontSize: 12,
        textAlign: "left",
        textTransform: "none",
        color: ({ checked, color }) => {
            if (theme.palette.type === "dark") {
                const labelColor = theme.palette[color]?.main || theme.palette.grey[200];
                return checked ? labelColor : theme.palette.grey[200];
            }
            else {
                const checkedColor = theme.palette[color]?.main || theme.palette.grey[500];
                return checked ? checkedColor : "inherit";
            }
        },
    },
}));
export const SelectionIcon = ({ CheckboxProps = {}, checked: checkedProp, className, color = "default", component: Component = Checkbox, icon: Icon, label, name, onChange, RadioProps = {}, value, ...props }) => {
    /** Store refs to elements */
    const buttonRef = useRef();
    const inputRef = useRef();
    /** Track internal state when uncontrolled */
    const [checked, setCheckedIfUncontrolled] = useControlled({
        controlled: checkedProp,
        default: Boolean(false),
        name: "SelectionIcon",
        state: "checked",
    });
    /** Handle change event */
    const handleChange = event => {
        setCheckedIfUncontrolled(event.target.checked);
        if (onChange) {
            onChange(event);
        }
    };
    /** Delegate clicks on the input to the input */
    const delegateClick = () => {
        const input = inputRef.current.querySelector("input");
        input.click();
    };
    /** Get scoped CSS classes */
    const classes = useStyles({
        checked: checkedProp !== undefined ? checkedProp : checked,
        color,
    });
    return (React.createElement(ButtonBase, { className: `${classes.root} ${className}`, ref: buttonRef, onClick: delegateClick, component: "label", htmlFor: name, ...props },
        React.createElement(Icon, { className: classes.icon }),
        React.createElement(Typography, { variant: "body2", className: classes.label }, label),
        React.createElement(Component, { className: classes.control, disableRipple: true, checked: checkedProp !== undefined ? checkedProp : checked, color: color, name: name, ref: inputRef, onChange: handleChange, value: value, ...CheckboxProps, ...RadioProps })));
};
