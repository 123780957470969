import { base } from "./base";
export const dark = {
    themeName: "CityDNA Dark Mode",
    palette: {
        type: "dark",
        background: {
            default: base.palette.grey[800],
            paper: base.palette.grey[800],
        },
        primary: {
            main: base.palette.base.magenta,
        },
        secondary: {
            main: base.palette.base.blueMid,
        },
        success: {
            main: base.palette.base.green,
        },
    },
    typography: {
        body1: { color: base.palette.grey[100] },
        body2: { color: base.palette.grey[100] },
        subtitle1: { color: base.palette.grey[400] },
    },
    overrides: {
        MuiAppBar: {
            colorDefault: {
                backgroundColor: base.palette.grey[600],
            },
            root: {
                "&.MuiPaper-elevation0": {
                    borderBottomColor: base.palette.grey[400],
                },
            },
        },
        MuiBottomNavigation: {
            root: {
                borderTopColor: base.palette.grey[400],
            },
        },
        MuiBottomNavigationAction: {
            root: {
                "&:not(.Mui-selected) .MuiSvgIcon-root": {
                    fill: base.palette.grey[100],
                },
            },
            label: {
                "&:not(.Mui-selected)": {
                    color: base.palette.grey[100],
                },
            },
        },
        MuiButton: {
            root: {
                color: base.palette.grey[100],
            },
            contained: {
                backgroundColor: base.palette.grey[500],
                "&:hover": {
                    backgroundColor: base.palette.grey[600],
                },
            },
            containedPrimary: {
                color: base.palette.grey[800],
                "&:hover": {
                    color: base.palette.common.white,
                },
            },
            containedSecondary: {
                color: base.palette.grey[800],
                "&:hover": {
                    color: base.palette.common.white,
                },
            },
            outlined: {
                "&:hover": {
                    borderColor: base.palette.grey[300],
                },
            },
        },
        MuiCard: {
            root: {
                borderColor: base.palette.grey[500],
            },
        },
        MuiCardHeader: {
            subheader: {
                color: base.palette.grey[300],
            },
        },
        MuiChip: {
            root: {
                backgroundColor: base.palette.grey[500],
                borderColor: base.palette.grey[400],
                "& .MuiAvatar-colorDefault": {
                    color: base.palette.grey[50],
                    backgroundColor: base.palette.grey[400],
                },
                "&:focus": {
                    backgroundColor: base.palette.grey[200],
                    borderColor: "transparent",
                },
            },
            icon: {
                fill: base.palette.grey[500],
            },
            label: {
                color: base.palette.grey[50],
            },
            clickable: {
                "&:hover": {
                    backgroundColor: base.palette.grey[400],
                    // borderColor: "transparent",
                },
            },
            deleteIcon: {
                fill: base.palette.grey[300],
            },
            colorPrimary: {
                borderColor: "transparent",
                "& .MuiSvgIcon-root": {
                    fill: base.palette.grey[700],
                },
                "& .MuiChip-label": {
                    color: base.palette.grey[700],
                },
                "& .MuiAvatar-root": {
                    backgroundColor: "rgba(255,255,255,0.3)",
                    color: base.palette.grey[700],
                },
            },
            colorSecondary: {
                borderColor: "transparent",
                "& .MuiSvgIcon-root": {
                    fill: base.palette.grey[700],
                },
                "& .MuiChip-label": {
                    color: base.palette.grey[700],
                },
                "& .MuiAvatar-root": {
                    backgroundColor: "rgba(255,255,255,0.3)",
                    color: base.palette.grey[700],
                },
            },
        },
        MuiDialog: {
            paper: {
                backgroundColor: base.palette.grey[700],
            },
            paperFullScreen: {
                backgroundColor: base.palette.grey[900],
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: base.palette.grey[700],
            },
        },
        MuiFab: {
            root: {
                backgroundColor: base.palette.grey[600],
                borderColor: base.palette.grey[400],
                "&:hover": {
                    backgroundColor: base.palette.grey[700],
                },
            },
        },
        MuiFilledInput: {
            root: {
                backgroundColor: base.palette.grey[500],
                "&&&&:hover": {
                    backgroundColor: base.palette.grey[400],
                },
                "&$focused": {
                    backgroundColor: base.palette.grey[400],
                },
                "& :-webkit-autofill": {
                    "-webkit-text-fill-color": base.palette.grey[200],
                },
            },
            input: {
                color: base.palette.grey[200],
            },
        },
        MuiRadio: {
            root: { color: base.palette.common.white },
            colorSecondary: {
                "&$checked": {
                    color: base.palette.common.white,
                },
            },
        },
        MuiCheckbox: {
            colorSecondary: {
                color: base.palette.grey[100]
            }
        },
        MuiIconButton: {
            root: {
                color: "rgba(255,255,255,0.54)",
            },
        },
        MuiInput: {
            input: {
                color: base.palette.grey[200],
            },
        },
        MuiInputLabel: {
            shrink: {
                color: `${base.palette.grey[200]} !important`,
            },
        },
        MuiListItemIcon: {
            root: {
                fill: base.palette.grey[100],
            },
        },
        MuiPaper: {
            root: {
                color: base.palette.grey[100],
                borderColor: base.palette.grey[400],
            },
        },
        MuiSnackbarContent: {
            root: {
                backgroundColor: base.palette.grey[600],
                color: base.palette.grey[50],
            },
        },
        // @ts-ignore
        MuiSkeleton: {
            root: {
                backgroundColor: base.palette.grey[500],
            },
        },
        MuiTypography: {
            root: {
                color: base.palette.grey[100],
            },
        },
    },
};
