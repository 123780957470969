/** Portal ID */
export const PORTAL_OUTLET_ID = "dna-portal-outlet"

export const PROJECTION_DEFAULT_TIMER = 20

export const S3_CONFIG_BUCKET_URL =
  "https://citydna-configs-bucket.s3-ap-southeast-2.amazonaws.com"

export const WEAVE_URL =
  "https://weave-proxy-api-v2.au-s1.cloudhub.io/weave/v1/data/execute/ids/__dd_property_prefparent_v1"

export const BUILDING_BASEPROP_URL =
  "https://services1.arcgis.com/KGdHCCUjGBpOPPac/arcgis/rest/services/propertybase_and_propertybuilding/FeatureServer/0"

export const ENVIRONMENT = ["local", "staging"].includes(
  process.env.REACT_APP_RUNNING_ENVIRONMENT || ""
)
  ? "nonprod"
  : "prod"
