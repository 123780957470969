import React, { useCallback } from "react"
import { Box, Fab, makeStyles, Theme, Typography } from "@material-ui/core"
import { Projection } from "@citydna/common"
import { actionCreators, useApp } from "../common/AppState"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    boxShadow: theme.shadows[7],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "56px",
    height: "56px"
  },
  label: {
    position: "absolute",
    left: 0,
    bottom: -theme.spacing(3),
    width: "100%",
    textAlign: "center",
    fontSize: 12,
  },
}))

export const ProjectionButton: React.FC = () => {
  const [{ projectionOpen }, dispatch] = useApp()
  const { t } = useTranslation<string>()

  const handleClick = useCallback(() => {
    dispatch(
      actionCreators.updateState({
        projectionOpen: !projectionOpen,
        projectionOpenFromDefaultButton: !projectionOpen,
      })
    )
  }, [dispatch, projectionOpen])

  const classes = useStyles()
  return (
    <Box position="relative">
      <Fab
        className={classes.fab}
        title={t("controls.projection.controlButtonTitle")}
        onClick={handleClick}
      >
        <Projection width="2em" height="2em" />
      </Fab>
      <Typography variant="caption" className={classes.label}>
        {t("controls.projection.controlButtonLabel")}
      </Typography>
    </Box>
  )
}
