import React, { useCallback, useEffect, useState } from "react"
import { Typography, Box, makeStyles } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import {
  PickContentFlat,
  ContentQueuedFlat,
  OnScreenFlat,
} from "@citydna/common"
import { Dialog } from "../Dialog"
import { actionCreators, useApp } from "../AppState"
import { useAvatarDialog } from "./useAvatarDialog"
import { ProjectionProgressBar } from "../ProgressBar/ProjectionProgressBar"

const useStyles = makeStyles((theme) => ({
  lottieContainer: {
    height: "126px",
    width: "218px",
    "& svg": {
      width: "100%",
      height: "100%",
    },
    "& path": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "& polygon": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "& rect": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
  },
  progressBar: {
    height: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}))

export const AvatarDialog: React.FC = () => {
  const {
    hasCardQueued,
    isCurrentStop,
    currentStopTimeLeft,
  } = useAvatarDialog()
  const [lottieIndex, setLottieIndex] = useState<number>(0)
  const [{ avatarDialogOpen }, dispatch] = useApp()
  const classes = useStyles()

  useEffect(() => {
    if (hasCardQueued && !isCurrentStop) {
      setTimeout(() => {
        setLottieIndex(1)
      }, 100)
    } else if (isCurrentStop) {
      setTimeout(() => {
        setLottieIndex(2)
      }, 100)
    } else {
      setTimeout(() => {
        setLottieIndex(0)
      }, 100)
    }
  }, [hasCardQueued, isCurrentStop])

  const handleDialogClose = useCallback(() => {
    dispatch(actionCreators.updateState({ avatarDialogOpen: false }))
  }, [dispatch])

  const { t } = useTranslation<string>()

  return (
    <Dialog
      dialogCloseCallback={handleDialogClose}
      isDialogOpen={avatarDialogOpen}
      lottie={
        <Box className={classes.lottieContainer}>
          {lottieIndex === 0 && <PickContentFlat />}
          {lottieIndex === 1 && <ContentQueuedFlat />}
          {lottieIndex === 2 && <OnScreenFlat />}
        </Box>
      }
      body={
        <>
          {!(isCurrentStop || hasCardQueued) && (
            <Typography variant="body2" align="center">
              {t("dialogs.avatar.noContent")}
            </Typography>
          )}
          {!isCurrentStop && hasCardQueued && (
            <Typography variant="body2" align="center">
              {t("dialogs.avatar.contentQueued")}
            </Typography>
          )}
          {isCurrentStop && (
            <Typography variant="body2" align="center">
              {t("dialogs.avatar.contentDisplayed")}
            </Typography>
          )}
        </>
      }
      footer={
        <>
          {!!(
            isCurrentStop &&
            currentStopTimeLeft &&
            currentStopTimeLeft > 0
          ) && (
            <Box className={classes.progressBar}>
              Moving on in {currentStopTimeLeft} seconds
              <ProjectionProgressBar timeLeft={currentStopTimeLeft} />
            </Box>
          )}
        </>
      }
    />
  )
}
