import { base } from "./base";
export const light = {
    themeName: "CityDNA Light Mode",
    palette: {
        type: "light",
        background: {
            default: base.palette.common.white,
            paper: base.palette.common.white,
        },
        primary: { main: base.palette.pop.magenta },
        secondary: { main: base.palette.pop.blueMid },
        error: { main: base.palette.pop.orange },
        success: { main: base.palette.pop.green },
        text: {
            primary: base.palette.grey[700],
            secondary: base.palette.grey[500],
        },
    },
    typography: {
        h1: { color: base.palette.grey[800] },
        h2: { color: base.palette.grey[800] },
        h3: { color: base.palette.grey[800] },
        // subtitle1: { color: base.palette.grey[400] },
        body1: { color: base.palette.grey[400] },
        body2: { color: base.palette.grey[400] },
    },
    overrides: {
        MuiAppBar: {
            colorDefault: {
                backgroundColor: base.palette.grey[50],
            },
            root: {
                "&.MuiPaper-elevation0": {
                    borderBottomColor: base.palette.grey[200],
                },
            },
        },
        MuiAvatar: {},
        MuiBottomNavigation: {
            root: {
                borderTopColor: base.palette.grey[200],
            },
        },
        MuiButton: {
            contained: {
                backgroundColor: base.palette.grey[400],
                "&:hover": {
                    backgroundColor: base.palette.grey[500],
                },
            },
        },
        MuiCard: {
            root: {
                borderColor: base.palette.grey[200],
            },
        },
        MuiCardHeader: {
            subheader: {
                color: base.palette.grey[400],
            },
        },
        MuiChip: {
            root: {
                backgroundColor: base.palette.common.white,
                borderColor: base.palette.grey[200],
                "& .MuiAvatar-colorDefault": {
                    color: base.palette.grey[500],
                    backgroundColor: base.palette.grey[200],
                },
                "&:focus": {
                    backgroundColor: base.palette.grey[200],
                    borderColor: "transparent",
                },
            },
            icon: {
                fill: base.palette.grey[800],
            },
            label: {
                color: base.palette.grey[800],
            },
            clickable: {
                "&:hover": {
                    backgroundColor: base.palette.grey[200],
                    // borderColor: "transparent",
                },
            },
            colorPrimary: {
                borderColor: "transparent",
                "& .MuiSvgIcon-root": {
                    fill: base.palette.common.white,
                },
                "& .MuiChip-label": {
                    color: base.palette.common.white,
                },
                "& .MuiAvatar-root": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                    color: base.palette.common.white,
                },
            },
            colorSecondary: {
                borderColor: "transparent",
                "& .MuiSvgIcon-root": {
                    fill: base.palette.common.white,
                },
                "& .MuiChip-label": {
                    color: base.palette.common.white,
                },
                "& .MuiAvatar-root": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                    color: base.palette.common.white,
                },
            },
        },
        MuiCheckbox: {
            colorSecondary: {
                color: base.palette.grey[600]
            }
        },
        MuiDialog: {
            paper: {
                backgroundColor: base.palette.common.white,
            },
            paperFullScreen: {
            // backgroundColor: base.palette.grey[900]
            },
        },
        MuiFab: {
            root: {
                color: base.palette.grey[700],
                backgroundColor: base.palette.common.white,
                borderColor: base.palette.grey[200],
                "&:hover": {
                    backgroundColor: base.palette.grey[100],
                },
            },
        },
        MuiPaper: {
            root: {
                color: base.palette.grey[700],
                borderColor: base.palette.grey[200],
            },
        },
        MuiSnackbarContent: {
            root: {
                borderColor: base.palette.grey[900],
            },
        },
        // @ts-ignore
        MuiSkeleton: {
            root: {
                backgroundColor: base.palette.grey[200],
            },
        },
    },
};
