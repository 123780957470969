import React, { useCallback, useRef, useState, useEffect } from "react";
import { useRoom } from "../hooks/useRoom";
import { __RoomContext } from "./context";
import { LOCAL_CHANNEL_NAME } from "../constants";
/**
 * For use in public places, such as the public app. Keeps a record of how often
 * a user emits events.
 */
export const PublicRoomProvider = ({ name = LOCAL_CHANNEL_NAME, emitLimit, emitLimitResetTime, userAdmittedCallback, children, }) => {
    const intervalRef = useRef();
    const [eventCount, setEventCount] = useState(0);
    // describes if the user is in the waiting room or not
    const [isUserAdmitted, setIsUserAdmitted] = useState(false);
    const currentRoom = useRoom(name);
    currentRoom.useEvent("citydna:invitation-to-enter", (payload) => {
        if (payload.member.id === currentRoom.myID) {
            setIsUserAdmitted(true);
            userAdmittedCallback("yes");
        }
    });
    currentRoom.useEvent("citydna:denied-entry", (payload) => {
        if (payload.member.id === currentRoom.myID) {
            userAdmittedCallback("no");
        }
    });
    const emit = useCallback((eventName, data) => {
        if (!isUserAdmitted) {
            return false;
        }
        setEventCount(eventCount + 1);
        if (eventCount >= emitLimit) {
            return false;
        }
        currentRoom.emit(eventName, data);
        return true;
    }, [currentRoom, eventCount, emitLimit, isUserAdmitted]);
    useEffect(() => {
        intervalRef.current = setInterval(() => {
            setEventCount(0);
        }, emitLimitResetTime);
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [emitLimitResetTime]);
    return (React.createElement(__RoomContext.Provider, { value: { ...currentRoom, emit } }, children));
};
