import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Tile } from "..";
export const VerticalTileGrid = ({ title, items, loading = false, onClick, tileOrnament = null, }) => {
    return (React.createElement(React.Fragment, null,
        title && (React.createElement(Typography, { variant: "body1", gutterBottom: true }, title)),
        loading && (React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, md: 6 },
                React.createElement(Skeleton, { variant: "rect", width: "100%", height: 292, style: { borderRadius: 10 }, title: "loading..." })),
            React.createElement(Grid, { item: true, md: 6 },
                React.createElement(Skeleton, { variant: "rect", width: "100%", height: 292, style: { borderRadius: 10 }, title: "loading..." })))),
        !loading &&
            (items && items.length ? (React.createElement(Grid, { container: true, spacing: 2 }, items.map((item) => {
                const { title, coverImageUrl } = item;
                return (React.createElement(Grid, { key: item.id, item: true, md: 6 },
                    React.createElement(Tile, { size: "large", title: title, coverImageUrl: coverImageUrl, onClick: onClick ? () => onClick(item) : undefined, width: "100%" }, typeof tileOrnament === "function"
                        ? tileOrnament(item)
                        : tileOrnament)));
            }))) : (!loading && React.createElement(Typography, { variant: "subtitle1" }, "No results")))));
};
