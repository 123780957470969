import React from "react";
import { Box } from "@material-ui/core";
/**
 * A skrim covers an image with a linear gradient, allowing text to be visible over it.
 * Extends [`@material-ui/core/Box`](https://material-ui.com/components/box/) so
 * you can apply positioning information
 *
 * @example
 * <Box angle={90} width="100%" height="100%">Children</Box>
 */
export const Skrim = ({ angle = 0, skrimColor = "dark", ...rest }) => {
    const background = skrimColor === "dark"
        ? `linear-gradient(${angle}deg, rgba(8,8,9,1) 0%, rgba(8,8,9,0) 100%)`
        : `linear-gradient(${angle}deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)`;
    return React.createElement(Box, { position: "absolute", style: { background }, zIndex: 9, ...rest });
};
