import React, { FC, useState } from "react"
import {
  Backdrop,
  Fade,
  IconButton,
  Typography,
  makeStyles,
  Box,
} from "@material-ui/core"
import { ArrowForward } from "@material-ui/icons"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  backdropOverride: () => ({
    zIndex: 99999,
    backgroundColor: theme.palette.grey[700],
  }),
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: "300px",
    margin: theme.spacing(1),
  },
  paragraph: {
    whiteSpace: "pre-line",
    textAlign: "center",
    marginBottom: theme.spacing(6),
  },
}))

export const AcknowledgmentToCountry: FC = () => {
  const [isOpen, setIsOpen] = useState(true)
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <Backdrop
      open={isOpen}
      className={classes.backdropOverride}
      transitionDuration={{ enter: 0, exit: 500 }}
    >
      <Fade in>
        <Box className={classes.contentContainer}>
          <Typography className={classes.paragraph} variant="h6" component="p">
            {t("onboarding.acknowledgmentOfCountry")}
          </Typography>
          <IconButton onClick={() => setIsOpen(false)}>
            <ArrowForward />
          </IconButton>
        </Box>
      </Fade>
    </Backdrop>
  )
}
