import React from "react"
import { Box } from "@material-ui/core"
import ScrollLock from "react-scrolllock"
import { AppHeader } from "@citydna/common"
import { useApp } from "./AppState"
import { FilterDrawer } from "../controls/FilterDrawer"
import { ThemesList } from "../controls/ThemesList"
import { LayersList } from "../controls/LayersList"
import ReferencesDrawer from "../controls/References/ReferencesDrawer"
import { ProjectionDrawer } from "../controls/Projection/ProjectionDrawer"

export const Layout: React.FC = ({ children }) => {
  const [{ filterOpen, layersOpen, referencesOpen, projectionOpen }] = useApp()

  return (
    <ScrollLock>
      <Box display="flex" flexDirection="column" height={1}>
        <AppHeader zIndex={1000} />
        <Box flexGrow={1} display="flex" position="relative">
          {children}
          {filterOpen && (
            <FilterDrawer
              drawerHeight={500}
              shutHeight={-150}
              ListComponent={ThemesList}
            />
          )}
          {layersOpen && (
            <FilterDrawer
              drawerHeight={420}
              shutHeight={-150}
              ListComponent={LayersList}
            />
          )}
          {projectionOpen && <ProjectionDrawer />}
          {referencesOpen && <ReferencesDrawer />}
        </Box>
      </Box>
    </ScrollLock>
  )
}
