import { MORE_TIME_GRANTED } from "./../common/eventNames";
import { REQUEST_ADD_STOP, CURRENT_TOUR_STOP, REQUEST_MOVE_ON, REQUEST_MORE_TIME, SHIFT_CARD, } from "../common/eventNames";
import { generateCardFromFeature } from "../common/generateCardFromFeature";
import { useConfig } from "../ConfigProvider";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRandomFeature } from "./useRandomFeature";
import { useLoadFullCardFromAgol, } from "./useLoadFullCardFromAgol";
import { v4 as uuid } from "uuid";
import { useCurrentRoom } from "@citydna/platform";
import { useTimer } from "use-timer";
import { useFeaturePropertiesAccessor } from "../FeaturePropertiesAccessorProvider";
const getMinCard = (card) => ({
    ...card,
    childMetadata: undefined,
    metadata: undefined,
    polygons: undefined,
    point: {
        ...card?.point,
        attributes: {
            ...card?.point?.attributes,
            dna_children_properties: undefined,
        },
    },
});
/** A default spatial reference for ESRI */
const DEFAULT_SPATIAL_REFERENCE = { wkid: 4326 };
/** A default stop interval before shifting cards */
const DEFAULT_STOP_INTERVAL = 25000;
/** a default event for onEnter and onLeave */
const DEFAULT_ENTER_LEAVE = () => Promise.resolve(undefined);
/**
 * This monstrous hook handles all the logic for;
 *  - tracking active cards
 *  - shifting them when their time is up
 *  - randomly selecting a new card if desired
 *  - adding cards requested over websockets
 *  - restarting when more time is requested
 * @param useCardStackArgs options to control the stack
 */
export const useCardStack = ({ autoplay = true, loadFullCardArgs, onEnter = DEFAULT_ENTER_LEAVE, onLeave = DEFAULT_ENTER_LEAVE, includeRandomCards = false, randomBoundToHoddle = false, randomThreshold = undefined, spatialReference = DEFAULT_SPATIAL_REFERENCE, stopInterval = DEFAULT_STOP_INTERVAL, skipAgolCall = false, }) => {
    /** Store cards in state */
    const [cards, setCards] = useState([]);
    const accessor = useFeaturePropertiesAccessor();
    /** Callback to a card off the top of the stack */
    const shiftCard = useCallback((id) => {
        /** Let the room know we're shifting */
        // const topCards = id ? cards.find(c => c.id === id) : [getMinCard(cards[0]), getMinCard(cards[1])]
        emit(SHIFT_CARD, cards.map((card) => getMinCard(card)));
        /** Shift the card from the internal stack */
        if (typeof id === "string") {
            // if an id is specified, remove that specific card
            setCards((cards) => cards.filter((c) => c.id !== id));
        }
        else {
            // otherwise just shift the first card
            setCards((cards) => {
                const next = [...cards];
                next.shift();
                return next;
            });
        }
    }, [cards]);
    /** Grab the app's config */
    const config = useConfig();
    /** Grab the platform SDK */
    const { useEvent, emit, myID } = useCurrentRoom();
    /** Grab the random feature generator */
    const randomFeature = useRandomFeature({
        loadFullCardArgs,
        spatialReference,
        boundToHoddle: randomBoundToHoddle,
        includeRandomCards,
    });
    /** When there are less than n cards, grab a random one */
    const loadingRandomRef = useRef(false);
    useEffect(() => {
        if (includeRandomCards &&
            config &&
            !loadingRandomRef.current &&
            cards.length < randomThreshold &&
            randomFeature?.ready && randomFeature?.getRandomFeature) {
            loadingRandomRef.current = true;
            randomFeature.getRandomFeature()
                .then((feature) => {
                const newCard = generateCardFromFeature(accessor, uuid(), feature);
                const newCardExistsInQueuedCards = cards.some((card) => {
                    return card.id === newCard.id;
                });
                if (!newCardExistsInQueuedCards) {
                    const { id, title } = newCard;
                    const refId = feature.point.attributes[config?.metadataRefField];
                    emit(REQUEST_ADD_STOP, {
                        id,
                        refId,
                        title,
                        [config?.metadataRefField]: refId,
                        owner: { id: myID },
                    });
                    setCards((cards) => [...cards, newCard]);
                }
                loadingRandomRef.current = false;
            })
                .catch((err) => {
                console.log("err loading random", err);
            });
        }
    }, [
        accessor,
        cards,
        config,
        emit,
        randomFeature,
        includeRandomCards,
        myID,
        randomThreshold,
    ]);
    /** Store an active flag to prevent anything happening while a card is mid-sequence */
    const activeRef = useRef(false);
    /** Use a timer to keep track of how long a card has been active for */
    const { start, reset, time } = useTimer({
        initialTime: stopInterval / 1000,
        timerType: "DECREMENTAL",
    });
    /** When the stack changes, run a card's sequence (go to, rotate, shift) */
    useEffect(() => {
        emit("ALL_CARDS", cards.map((card) => ({
            ...getMinCard(card),
            expiresInSeconds: stopInterval !== undefined ? stopInterval / 1000 : undefined,
        })));
        const topCard = cards[0];
        // check we're not already running another card and have a point to display
        if (!activeRef.current && cards.length) {
            activeRef.current = true;
            onEnter(topCard).then(() => {
                activeRef.current = false;
                if (autoplay) {
                    start();
                }
                emit(CURRENT_TOUR_STOP, {
                    id: topCard.id,
                    expiresInSeconds: stopInterval / 1000,
                });
            });
        }
    }, [autoplay, cards, emit, onEnter, start, stopInterval]);
    /** Listen to the timer and do things when it's finished */
    const shiftingRef = useRef(false); // used to prevent multiple shifts at once
    useEffect(() => {
        if (time === 0 && shiftingRef.current === false) {
            shiftingRef.current = true;
            // run any onLeave promises
            onLeave(cards).then((id = undefined) => {
                // (potentially passing an id to shiftCard)
                shiftCard(id);
                // reset the timer ready for the next card
                reset();
                // flick the flag back to not shifting
                shiftingRef.current = false;
            });
        }
    }, [cards, onLeave, reset, shiftCard, time]);
    /**
     *
     * EVENT LISTENERS
     * Respond to events over websocket to interactive the cards.
     *
     */
    /** Generate a loadFullCard callback */
    const loadFullCard = useLoadFullCardFromAgol(config, spatialReference, skipAgolCall);
    /** Listening for new cards requested and load them into the stack */
    useEvent(REQUEST_ADD_STOP, (shallowCard) => {
        // return early if the component using this hook queued this card
        if (shallowCard.owner?.id === myID)
            return;
        // immediately add the card
        setCards((cards) => [...cards, shallowCard]);
        // if card does not rely on AGOL all information needed is already on the 'shallow' card
        if (skipAgolCall) {
            return;
        }
        // asynchronously load the rest of the card
        loadFullCard(loadFullCardArgs(shallowCard))
            .then((result) => {
            if (shallowCard) {
                // generate a TourCardPayload compliant card from the result
                const fullCard = generateCardFromFeature(accessor, shallowCard.id, result);
                // set the card again.
                setCards((cards) => {
                    // find the index of the card in case others have been added in the meantime
                    const index = cards.findIndex((c) => c.id === shallowCard.id);
                    // generate a new array and add the full card to it
                    const next = [...cards];
                    // merge the shallow and full card at the index
                    next[index] = {
                        ...shallowCard,
                        ...fullCard,
                    };
                    // return the new array
                    return next;
                });
            }
        })
            .catch((err) => console.log("error loading full card", err));
    });
    /** When the owner requests more time, reset and restart the timer */
    useEvent(REQUEST_MORE_TIME, ({ id, owner }) => {
        const top = cards[0];
        if (owner?.id === top?.owner?.id && top?.id === id) {
            /** Restart the timer */
            reset();
            start();
            /** Let the room know more time was granted */
            emit(MORE_TIME_GRANTED, top);
        }
    });
    /** Request moving on from a stop, shift the card immediately. */
    useEvent(REQUEST_MOVE_ON, ({ id, owner }) => {
        const top = cards[0];
        if (owner?.id === top?.owner?.id && top?.id === id) {
            reset();
            shiftCard(id);
        }
    });
    return {
        cards,
        timeLeft: time,
        setCards,
        shiftCard,
        reset,
    };
};
