import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { useAppEnvironment } from "@citydna/platform";
import { useConfig } from "../ConfigProvider";
import { TownHallMapQRCode as QRCode } from "./TownHallMapQRCode";
const useStyles = makeStyles(() => ({
    qrContainer: ({ windowPadding }) => ({
        position: "absolute",
        bottom: windowPadding,
        right: windowPadding,
        zIndex: 10,
    }),
}));
/**
 * Sets up the window for a card stack, and animates the QR container.
 *
 * notes: use regular css to animate QR container here as react-spring was causing frame rate issues for the card stack
 */
export const TownHallMapLayout = ({ children, windowPadding }) => {
    const { global: { publicAppUrl }, } = useAppEnvironment();
    const config = useConfig();
    const classes = useStyles({ windowPadding });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { height: "100%", display: "flex", alignItems: "flex-end", bottom: windowPadding, left: windowPadding, position: "absolute", zIndex: 10 }, children),
        React.createElement(Box, { className: classes.qrContainer },
            React.createElement(QRCode, { publicAppUrl: publicAppUrl || "", experience: config?.id }))));
};
