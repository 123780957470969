import React, { FC } from "react"
import { useApp, actionCreators } from "./AppState"
import { Box, Chip, Avatar, makeStyles, Theme } from "@material-ui/core"
import { getDataset } from "../controls/Projection/ProjectionDrawer"
import { useProjectionCategories } from "../common/useProjectionCategories"

const useStyles = makeStyles<Theme>((theme) => ({
  chip: {
    backgroundColor: theme.palette.grey[800],
    height: 40,
    borderRadius: "4px",
    "& .MuiChip-label": {
      fontSize: "10px",
    },
  },
}))

export const ActiveProjectionChip: FC = () => {
  const [categoriesData] = useProjectionCategories()
  const [
    {
      activeProjection: { id, categoryId },
    },
    dispatch,
  ] = useApp()

  const dataset = id && categoryId && getDataset(categoriesData, categoryId, id)

  const classes = useStyles()

  const handleClick = () => {
    dispatch(actionCreators.updateState({ projectionOpen: true }))
  }

  return dataset ? (
    <Box
      position="absolute"
      width="100%"
      top={100}
      display="flex"
      justifyContent="center"
      zIndex={10}
    >
      <Chip
        label={`${dataset.title} currently projected`}
        size="medium"
        clickable
        onClick={handleClick}
        className={classes.chip}
        avatar={
          <Avatar
            alt={`${dataset.title} avatar`}
            src={dataset.detailImageUrl}
          />
        }
      />
    </Box>
  ) : null
}
