import { useQuery } from "react-query"
import { Categories } from "@citydna/experience"
import { ENVIRONMENT, S3_CONFIG_BUCKET_URL } from "./constants"
import { getProjectionCategories } from "./api/queries"

/**
 * Hook that fetches the projection categories json file from AWS.
 */
export const useProjectionCategories = () => {
  const { data, refetch, isError, isLoading } = useQuery("categories", () =>
    getProjectionCategories({
      bucketUrl: S3_CONFIG_BUCKET_URL,
      environment: ENVIRONMENT,
    })
  )
  return [data as Categories, isError, refetch, isLoading] as const
}
