import React from "react";
import { MenuItem, Select as MuiSelect, makeStyles, FormControl, InputLabel, } from "@material-ui/core";
const SMALL = "small";
const LARGE = "large";
const useStyles = makeStyles(() => ({
    // @ts-ignore
    control: ({ size, label }) => ({
        minWidth: size === SMALL ? 180 : 240,
        marginTop: label ? 24 : 0,
    }),
    // @ts-ignore
    label: ({ size }) => ({
        fontSize: size === SMALL ? "18px" : "auto",
        zIndex: 0,
        transform: size === SMALL
            ? "translate(16px, 36px) scale(1)"
            : "translate(16px, 44px) scale(1)",
        top: size === SMALL ? -16 : -18,
        "&.MuiInputLabel-shrink": {
            transform: size === SMALL
                ? "translate(0px, 0px) scale(0.8)"
                : "translate(0px, 0px) scale(0.5)",
        },
    }),
    //@ts-ignore
    select: ({ size }) => ({
        padding: size === SMALL ? 0 : "auto",
        fontSize: size === SMALL ? 18 : "auto",
        "& .MuiSelect-filled.MuiSelect-filled": {
            padding: size === SMALL ? "18px 32px 18px 14px" : "auto",
            fontSize: size === SMALL ? 18 : "auto",
        },
    }),
}));
export const Select = ({ items = [], size = LARGE, label, ...props }) => {
    const classes = useStyles({ size, label });
    return (React.createElement(FormControl, { className: classes.control },
        label && React.createElement(InputLabel, { className: classes.label }, label),
        React.createElement(MuiSelect, { variant: "filled", disableUnderline: true, ...props, className: classes.select }, items.map(({ label, value }) => (React.createElement(MenuItem, { value: value, key: value }, label))))));
};
