import React, { FC } from "react"
import { Box, Fab, makeStyles, Theme, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { actionCreators, useApp } from "../../common/AppState"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    boxShadow: theme.shadows[7],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "56px",
    height: "56px"
  },
  label: {
    position: "absolute",
    left: 0,
    bottom: -theme.spacing(3),
    width: "100%",
    textAlign: "center",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[100]
        : theme.palette.common.black,
  },
}))

export const ReferencesButton: FC = () => {
  const [, dispatch] = useApp()
  const handleClick = () => {
    dispatch(actionCreators.updateState({ referencesOpen: true }))
  }

  const classes = useStyles()

  const { t } = useTranslation<string>()

  return (
    <Box position="relative">
      <Fab
        className={classes.fab}
        title={t("controls.references.controlButtonTitle")}
        onClick={handleClick}
      >
        <FormatListBulletedIcon />
      </Fab>
      <Typography variant="caption" className={classes.label}>
        {t("controls.references.controlButtonLabel")}
      </Typography>
    </Box>
  )
}
