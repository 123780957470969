import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { viewState, globalBoundsState, zoomState, bearingState, deckGlLayerState, } from "./atoms";
import { adjustedViewState } from "./selectors";
import { LAYER_PREFIX } from "../constants";
import { useCallback } from "react";
export const useGetViewState = () => {
    return useRecoilValue(viewState);
};
export const useSetViewState = () => {
    return useSetRecoilState(viewState);
};
export const useViewState = () => {
    return useRecoilState(viewState);
};
export const useGetGlobalBoundsState = () => {
    return useRecoilValue(globalBoundsState);
};
export const useSetGlobalBoundsState = () => {
    return useSetRecoilState(globalBoundsState);
};
export const useGlobalBoundsState = () => {
    return useRecoilState(globalBoundsState);
};
export const useGetZoomState = () => {
    return useRecoilValue(zoomState);
};
export const useSetZoomState = () => {
    return useSetRecoilState(zoomState);
};
export const useZoomState = () => {
    return useRecoilState(zoomState);
};
export const useGetBearingState = () => {
    return useRecoilValue(bearingState);
};
export const useSetBearingState = () => {
    return useSetRecoilState(bearingState);
};
export const useBearingState = () => {
    return useRecoilState(bearingState);
};
export const useGetAdjustedView = () => {
    const adjustedView = useRecoilValue(adjustedViewState);
    return adjustedView;
};
export const useGetDeckGlLayerState = () => {
    return useRecoilValue(deckGlLayerState);
};
const deckGlStateHelper = (layers) => {
    return layers.map((layer) => {
        if (!layer.id.includes(LAYER_PREFIX)) {
            layer.id = LAYER_PREFIX + layer.id;
        }
        return layer;
    });
};
export const useSetDeckGlLayerState = () => {
    const setDeckGlState = useSetRecoilState(deckGlLayerState);
    const setter = useCallback((layers) => {
        if (typeof layers === "function") {
            setDeckGlState((currentLayers) => {
                const newLayers = layers(currentLayers);
                return deckGlStateHelper(newLayers);
            });
            return;
        }
        setDeckGlState(deckGlStateHelper(layers));
    }, [setDeckGlState]);
    return setter;
};
export const useDeckGlLayerState = () => {
    const value = useGetDeckGlLayerState();
    const setter = useSetDeckGlLayerState();
    return [value, setter];
};
