import { useMap as useReactGlMap } from "react-map-gl";
import { DEFAULT_MAP_ID } from "../constants";
/**
 * Returns the underlying Mapbox reference, use this to access imperative methods on the map.
 *
 * react-map-gl docs on [`useMap`](https://visgl.github.io/react-map-gl/docs/api-reference/use-map)
 */
export const useMap = () => {
    return useReactGlMap()[DEFAULT_MAP_ID];
};
