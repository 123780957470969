import React from "react";
import { Switch, useTheme, withStyles } from "@material-ui/core";
import { Brightness2Rounded as Brightness2RoundedIcon } from "@material-ui/icons";
import { Brightness5Rounded as Brightness5RoundedIcon } from "@material-ui/icons";
import { useSetTheme } from "./Theme";
// const useStyles = makeStyles((theme: Theme) => ({}))
export const ToggleTheme = () => {
    const theme = useTheme();
    const setTheme = useSetTheme();
    const handleChange = () => setTheme(theme => (theme === "dark" ? "light" : "dark"));
    return (React.createElement(IOSSwitch, { checked: theme.palette.type === "dark", onChange: handleChange }));
};
const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 52,
        height: 26,
        padding: 0,
        margin: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        "& .MuiSvgIcon-root": {
            fill: theme.palette.pop.yellow,
            width: 24,
            height: 24,
        },
    },
    switchBase: {
        padding: "1px !important",
        "&$checked": {
            transform: "translateX(26px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: theme.palette.grey[600],
                border: `1px solid ${theme.palette.grey[400]}`,
                opacity: 1,
                // border: "none",
            },
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[600]}`,
        backgroundColor: theme.palette.grey[400],
        opacity: 1,
    },
    checked: {},
    focusVisible: {},
    // @ts-ignore
}))(({ classes, ...props }) => {
    return (React.createElement(Switch, { title: "toggle theme", focusVisibleClassName: classes.focusVisible, disableRipple: true, checkedIcon: React.createElement(Brightness2RoundedIcon, null), icon: React.createElement(Brightness5RoundedIcon, null), classes: {
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
        }, ...props }));
});
