import { a, config, useSpring } from "react-spring"
import React, { useCallback, useEffect } from "react"
import { useDrag } from "react-use-gesture"
import { Box, useTheme, Typography, Modal } from "@material-ui/core"
import { useApp, actionCreators } from "../../common/AppState"
import { useTranslation } from "react-i18next"
import useMeasure from "react-use-measure"
import { PORTAL_OUTLET_ID } from "../../common/constants"
import { ReferencesContent } from "./ReferencesContent"

const AnimatedBox = a(Box)

type filterDrawerProps = {
  drawerHeight?: number
  extendThreshold?: number
  bottomBuffer?: number
  shutHeight?: number
}

export const ReferencesDrawer: React.FC<filterDrawerProps> = ({
  extendThreshold = 50,
  drawerHeight = 500,
  shutHeight = -150,
}) => {
  const theme = useTheme()
  const [, dispatch] = useApp()
  const [{ y }, api] = useSpring(() => ({ y: 0, scale: 1 }))
  const [measureRef, { height }] = useMeasure()

  const open = useCallback(() => {
    api.start({ y: -drawerHeight, immediate: false, config: config.gentle })
  }, [api, drawerHeight])

  const close = useCallback(() => {
    api.start({ y: 0, immediate: false, config: config.gentle })
    setTimeout(() => {
      dispatch(actionCreators.updateState({ referencesOpen: false }))
    }, 100)
  }, [api, dispatch])

  const bind = useDrag(
    ({ last, movement: [, my], tap }) => {
      // return on tap
      if (tap) return

      // only let the user drag so high before cancelling
      if (my <= -drawerHeight - extendThreshold) {
        open()
        return
      }
      // if the user drags the drawer down far enough
      if (my >= shutHeight) {
        close()
        return
      }
      // if not closed far enough - then open - else close
      if (last) {
        if (my < -300) {
          open()
        } else {
          close()
        }
      } else {
        // all other conditions - move the drawer
        api.start({ y: my, scale: 1, immediate: false, config: config.stiff })
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      axis: "y",
    }
  )

  /** open up the drawer on mount each time */
  useEffect(() => {
    open()
  }, [open])

  const { t } = useTranslation<string>()

  const handleClickInner = (event: React.UIEvent | UIEvent): void => {
    event.stopPropagation()
  }

  return (
    <Modal
      container={document.getElementById(PORTAL_OUTLET_ID)}
      open={true}
      onBackdropClick={close}
    >
      <AnimatedBox
        style={{ y, outline: "none" }}
        {...bind()}
        position="absolute"
        bottom={-drawerHeight - extendThreshold}
        left={0}
        right={0}
        bgcolor={theme.palette.grey[700]}
        borderTop={1}
        borderColor={theme.palette.grey[400]}
        zIndex={11}
        height={drawerHeight}
      >
        <Box
          // @ts-ignore
          ref={measureRef}
        >
          <DragBar />
          <Box p={2} pb={0} pt={0}>
            <Typography variant="h2">
              {t("controls.references.sheetTitle")}
            </Typography>
          </Box>
        </Box>
        <ReferencesContent
          contentHeight={drawerHeight - height - extendThreshold}
          onClick={handleClickInner}
          onTouchStart={handleClickInner}
          onTouchMove={handleClickInner}
          onTouchEnd={handleClickInner}
          onTouchCancel={handleClickInner}
          onPointerDown={handleClickInner}
          onPointerMove={handleClickInner}
          onPointerUp={handleClickInner}
          onPointerCancel={handleClickInner}
        />
      </AnimatedBox>
    </Modal>
  )
}

const DragBar = () => (
  <Box position="relative" p={1} display="flex" justifyContent="center">
    <Box width={29} height={3} bgcolor="#5D616C" borderRadius="20%" />
  </Box>
)

export default ReferencesDrawer
