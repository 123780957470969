import { fontFamily } from "./base";
export const touchSizing = {
    typography: {
        htmlFontSize: 18,
        h1: { fontFamily: fontFamily, fontSize: 64, fontWeight: 900 },
        h2: { fontFamily: fontFamily, fontSize: 48, fontWeight: 900 },
        h3: { fontFamily: fontFamily, fontSize: 36, fontWeight: 900 },
        h4: { fontFamily: fontFamily, fontSize: 34, fontWeight: 500 },
        h5: { fontFamily: fontFamily, fontSize: 24, fontWeight: 500 },
        h6: { fontFamily: fontFamily, fontSize: 20, fontWeight: 500 },
        subtitle1: { fontSize: 34 },
        body1: { fontSize: 18 },
        body2: { fontSize: 18 },
    },
    shape: {
        borderRadius: 10,
    },
    overrides: {
        MuiTextField: {
            root: {
                marginTop: 24,
            },
        },
        MuiInput: {
            input: {
                fontSize: 36,
            },
        },
        MuiFilledInput: {
            root: {
                borderRadius: 10,
                fontSize: 36,
                fontWeight: "normal",
                "& :-webkit-autofill": {
                    backgroundColor: "transparent",
                    transition: "background-color 5000s ease-in-out 0s",
                },
            },
            input: {
                fontSize: 36,
                padding: "24px 32px",
            },
        },
        MuiInputLabel: {
            root: {
                fontWeight: 100,
                fontSize: 36,
            },
            filled: {
                transform: "translate(32px, 28px) scale(1)",
                "&$shrink": {
                    transform: "translate(0px, -24px) scale(0.5)",
                },
            },
            shrink: {
                transform: "translate(0px, 0px) scale(0.5)",
            },
        },
        MuiButton: {
            root: {
                fontSize: 18,
                padding: "8px 24px",
            },
            text: {
                fontSize: 18,
                padding: "8px 24px",
            },
            textSizeLarge: {
                fontSize: 18,
                padding: "8px 24px",
            },
            label: {
                position: "relative",
                top: 1,
            },
        },
        MuiSnackbarContent: {
            message: {
                fontSize: 18,
                fontWeight: 900,
            },
        },
        MuiDialogContent: {
            root: {
                backgroundColor: "transparent",
            },
        },
    },
};
