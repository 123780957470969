import React, { FC, useCallback } from "react"
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Box,
  Checkbox,
  Typography,
} from "@material-ui/core"
import { HelpOutlineRounded as HelpOutlineRoundedIcon } from "@material-ui/icons"
import { ThemeIconMap } from "@citydna/experience"
import { useThemes } from "../common/ThemesProvider"
import { FilterListProps } from "./FilterDrawer"

const useStyles = makeStyles<Theme>((theme) => ({
  avatar: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.common.white
        : "transparent",
  },
  icon: {
    width: "34px",
    height: "34px",
  },
  listItemText: {
    paddingRight: theme.spacing(1),
    "& .MuiListItemText-secondary": {
      color:
        theme.palette.type === "dark" ? theme.palette.grey[200] : "inherit",
    },
  },
}))

/**
 * Reads from the themes object and renders list components that the user
 * can toggle on and off to render the GalleryTiles on and off.
 */
export const ThemesList: FC<FilterListProps> = ({
  contentHeight,
  ...props
}) => {
  const classes = useStyles()
  const { themes, activeThemes, setActiveThemes } = useThemes()

  const handleToggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (activeThemes) {
        const updatedActiveThemes = activeThemes.map((theme) => {
          if (theme.id === e.target.id) {
            return {
              ...theme,
              disabled: !e.target.checked,
            }
          }
          return theme
        })
        setActiveThemes(updatedActiveThemes)
      }
    },
    [activeThemes, setActiveThemes]
  )

  if (!activeThemes) {
    return <Typography>Loading ...</Typography>
  }

  return (
    <List
      style={{
        height: contentHeight,
        overflow: "auto",
      }}
      {...props}
    >
      {themes.map((theme) => {
        const Icon = ThemeIconMap[theme.id] || HelpOutlineRoundedIcon
        return (
          <Box key={theme.id}>
            <ListItem divider>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <Icon
                    className={classes.icon}
                    title={`icon representing ${theme.title}`}
                  />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id={`switch-${theme.title}-label`}
                primary={theme.title}
                secondary={theme?.summary || ""}
                className={classes.listItemText}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  size="small"
                  name={theme.title}
                  id={theme.id || theme.title}
                  checked={
                    !activeThemes?.find(
                      (activeTheme) => activeTheme.id === theme.id
                    )?.disabled
                  }
                  onChange={handleToggle}
                  inputProps={{
                    "aria-labelledby": `switch-${theme.title}-label`,
                  }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        )
      })}
    </List>
  )
}
