import React, { FC } from "react"
import { Modal, Box, makeStyles, Theme, IconButton } from "@material-ui/core"
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons"
import Zoomable from "react-instagram-zoom";
import { useImage } from "@citydna/experience"

type ExpandImageViewerProps = {
  open: boolean
  handleClose: () => void
  imageUrl: string | undefined
  imageTag: string | undefined
}

type StyleProps = {
  height: number
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
  expandImageContainer: {
    position: "absolute",
    width: "100%",
    top: ({ height }) => height / 2 - 350 / 2,
  },
  expandImage: {
    height: "350px",
    width: "100%",
    objectFit: 'contain'
  },
  expandImageClose: {
    position: "absolute",
    right: "21px",
    top: "119px",
    color: "white",
  },
}))

export const ExpandImageViewer: FC<ExpandImageViewerProps> = ({
  open,
  handleClose,
  imageUrl,
  imageTag,
}) => {
  const classes = useStyles({ height: window.innerHeight })

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { background: "rgba(0, 0, 0, 0.8)" } }}
    >
      <Box style={{outline: "none"}}>
        <IconButton className={classes.expandImageClose} onClick={handleClose}>
          <CloseRoundedIcon fontSize="small" />
        </IconButton>
        <Box className={classes.expandImageContainer}>
            <Zoomable>
              <img
                className={classes.expandImage}
                src={useImage(imageUrl as string)}
                alt={imageTag}
              />
            </Zoomable>
        </Box>
      </Box>
    </Modal>
  )
}
