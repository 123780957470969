import React, { useEffect, useState } from "react";
import { Paper, Typography, Box, ButtonBase, useTheme, makeStyles, } from "@material-ui/core";
import { Skrim } from "..";
export const TILE_SMALL_HEIGHT = 156;
export const TILE_LARGE_HEIGHT = 292;
const useStyles = makeStyles((theme) => ({
    paper: ({ size, width, active, coverImageUrl }) => ({
        width,
        height: size === "small" ? TILE_SMALL_HEIGHT : TILE_LARGE_HEIGHT,
        transition: theme.transitions.create(["background-color"], {
            duration: theme.transitions.duration.short,
        }),
        textAlign: "left",
        background: theme.palette.background.paper,
        backgroundSize: "cover",
        position: "relative",
        color: theme.palette.type === "light" && !coverImageUrl
            ? theme.palette.text.primary
            : theme.palette.common.white,
        boxShadow: active ? "none" : theme.shadows[3],
    }),
    active: ({ active }) => ({
        width: "calc(100% + 8px)",
        height: "calc(100% + 8px)",
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.short,
        }),
        opacity: active ? 1 : 0,
        zIndex: 1,
        boxShadow: !active ? "none" : theme.shadows[3],
    }),
    coverImage: ({ coverImageUrl, imageLoaded }) => ({
        background: `url(${coverImageUrl && coverImageUrl})`,
        borderRadius: theme.shape.borderRadius,
        backgroundSize: "cover",
        transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.short,
        }),
        opacity: imageLoaded ? 1 : 0,
        position: "absolute",
        width: "100%",
        height: "100%",
    }),
}));
/**
 * A card or tile with additional props for handy use-cases.
 * Extends [`@material-ui/core/Box`](https://material-ui.com/components/box/).
 */
export const Tile = ({ size = "small", title, active, onClick, coverImageUrl, width, children, ...rest }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    useEffect(() => {
        if (coverImageUrl) {
            const img = new Image();
            img.onload = () => setImageLoaded(true);
            img.src = coverImageUrl;
        }
    }, [coverImageUrl]);
    const classes = useStyles({
        size,
        active,
        coverImageUrl,
        imageLoaded,
        width,
    });
    const theme = useTheme();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { position: "relative", style: { cursor: "pointer" }, onClick: onClick, width: width, ...rest },
            React.createElement(Box, { className: classes.active, bgcolor: "primary.main", position: "absolute", borderRadius: 12, left: -4, top: -4, zIndex: -1, "data-testid": active ? "tile-active" : "tile-inactive", title: title + " is selected " }),
            React.createElement(ButtonBase, { style: { width, zIndex: 2, position: "relative" }, component: "div" },
                React.createElement(Paper, { className: classes.paper },
                    coverImageUrl && (React.createElement(React.Fragment, null,
                        React.createElement(Skrim, { width: "100%", height: "100%", borderRadius: theme.shape.borderRadius }),
                        React.createElement(Box, { className: classes.coverImage, style: { backgroundSize: "cover" }, title: `Tile image` }))),
                    React.createElement(Box, { p: 2, display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-end", height: 1, position: "relative", zIndex: 9, whiteSpace: "normal" },
                        title && (React.createElement(Typography, { variant: "h5", component: "h3" }, title)),
                        children))))));
};
