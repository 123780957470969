import { useEffect, useRef, useState } from "react";
/**
 * @param timeoutInMs the number of milliseconds to timeout in
 */
export const useIsIdle = (timeoutInMs) => {
    const [idle, setIdle] = useState(false);
    const timeoutRef = useRef();
    /** effect that sets the idle state to true based on incoming timeout */
    useEffect(() => {
        // always set idle to false which will be overwritten by the timeout function eventually
        setIdle(false);
        // if a timeout exists already, cancel it
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        // set idle to true in n seconds
        timeoutRef.current = window.setTimeout(() => setIdle(true), timeoutInMs || 25000);
    }, [timeoutInMs]);
    return idle;
};
