import { useCallback } from "react";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { viewState, globalBoundsState } from "../state";
export const useSetViewportInBounds = () => {
    const setViewport = useSetRecoilState(viewState);
    const globalBounds = useRecoilValue(globalBoundsState);
    /**
     * This function allows you to change the viewport but always remain in specific bounds.
     *
     * @param viewportOrViewportUpdater the viewport that you would like to update the map to or an updater function.
     * @param localBounds optionally pass in specific bounds, otherwise it'll use globalBounds.
     */
    const setViewportInBounds = useCallback((viewportOrViewportUpdater, localBounds) => {
        setViewport((previousViewport) => {
            /**
             * Can pass either a function or an object. You'd
             * pass a function to get access to the previous
             * viewState.
             */
            const nextViewport = typeof viewportOrViewportUpdater === "function"
                ? viewportOrViewportUpdater(previousViewport)
                : viewportOrViewportUpdater;
            /** Which bounds are we using this call? */
            const bounds = localBounds || globalBounds;
            /** Return early if no bounds have been set */
            if (!bounds)
                return nextViewport;
            /** Get the variables we need for calculation */
            let { latitude, longitude, ...rest } = nextViewport;
            const [[minLongitude, minLatitude], [maxLongitude, maxLatitude]] = bounds;
            /** Clamp latitude */
            if (latitude < minLatitude) {
                latitude = minLatitude;
            }
            else if (latitude > maxLatitude) {
                latitude = maxLatitude;
            }
            /** Clamp longitude */
            if (longitude < minLongitude) {
                longitude = minLongitude;
            }
            else if (longitude > maxLongitude) {
                longitude = maxLongitude;
            }
            /** Return clamped viewport */
            return {
                ...previousViewport,
                ...rest,
                latitude,
                longitude,
            };
        });
    }, [globalBounds, setViewport]);
    return setViewportInBounds;
};
