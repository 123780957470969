import { useContext } from "react";
import { AppEnvironmentContext } from "../AppEnvironmentContext";
/**
 * App environment config, this will be set in the host app.
 *
 * Use this to get values in apps instead of relying on process.env
 */
export function useAppEnvironment() {
    const appEnvironmentContext = useContext(AppEnvironmentContext);
    if (!appEnvironmentContext) {
        throw new Error("Error: context not set");
    }
    return appEnvironmentContext;
}
