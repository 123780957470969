import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
/**
 *  Easily create nice consistent page titles without worrying about which variants, i.e. h2, h3, to use.
 * Extends [`@material-ui/core/Box`](https://material-ui.com/components/box/) for control over margins, etc.
 */
export const Subtitle = ({ primary, secondary, loading = false, ...rest }) => {
    return (React.createElement(Box, { ...rest },
        React.createElement(Typography, { variant: "h2" }, loading ? (React.createElement(Skeleton, { variant: "text", width: "100%", height: 36, title: "loading..." })) : (primary)),
        React.createElement(Typography, { variant: "h4" }, loading ? (React.createElement(Skeleton, { variant: "text", width: "69%", height: 24, title: "loading..." })) : (secondary))));
};
export default Subtitle;
