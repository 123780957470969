import React, { createContext, useContext, useRef } from "react"
import fscreen from "fscreen"
import { Box } from "@material-ui/core"

/** Type return values */
type FullscreenContextValue = {
  enter: () => void
  exit: () => void
}

/** Generate the context */
const FullscreenContext = createContext<FullscreenContextValue | undefined>(
  undefined
)

/** Provider to wrap your app with */
export const FullscreenProvider: React.FC = ({ children }) => {
  /** Store ref to dom node */
  const ref = useRef<HTMLDivElement>()

  /** Open full screen */
  const enter = () => {
    if (ref.current?.requestFullscreen) {
      fscreen.requestFullscreen(ref.current)
    }
  }

  /** Open full screen */
  const exit = () => {
    if (ref.current?.requestFullscreen) {
      fscreen.exitFullscreen()
    }
  }

  return (
    <FullscreenContext.Provider value={{ enter, exit }}>
      <Box
        flexGrow={1}
        // @ts-ignore
        ref={ref}
      >
        {children}
      </Box>
    </FullscreenContext.Provider>
  )
}

/** Hook to consume the context */
export const useFullscreen = () => {
  const context = useContext(FullscreenContext)
  if (!context) throw Error("Not inside <FullscreenProvider />")
  return context
}
