import React, { forwardRef } from "react";
import { Fab, makeStyles } from "@material-ui/core";
import { AccessibilityNewRounded as AccessibilityNewRoundedIcon } from "@material-ui/icons";
import { useCurrentRoom } from "@citydna/platform";
import { colors } from "@citydna/common";
import { AvatarIconMap } from "../common/AvatarIconMap";
/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme) => ({
    fab: {
        backgroundColor: ({ color }) => color || theme.palette.grey[400],
        border: `1px solid ${theme.palette.grey[50]}`,
    },
    buttonIcon: {
        width: "100%",
        height: "100%",
        "& path": {
            fill: ({ isLightBackground }) => isLightBackground
                ? theme.palette.common.black
                : theme.palette.common.white,
        },
    },
}));
export const Avatar = forwardRef(({ className, icon, color, ...props }, forwardedRef) => {
    const { me } = useCurrentRoom();
    const Icon = icon
        ? AvatarIconMap[icon]
        : me && AvatarIconMap[me?.info.icon];
    const classes = useStyles({
        color: color || me?.info.color,
        isLightBackground: Object.values(colors.light).includes(color || me?.info.color),
    });
    return (React.createElement(Fab, { className: `${classes.fab} ${className}`, ...props, ref: forwardedRef }, Icon ? (React.createElement(Icon, { className: classes.buttonIcon, title: icon || me?.info.icon })) : (React.createElement(AccessibilityNewRoundedIcon, null))));
});
