import { useState, useEffect, useRef } from "react";
import { blurHashToURL } from "./blurhashToUrl";
const s3BucketPrefix = 'https://featureeditor56e96836589c4e1ca49e3a21f83a15f5121955-dev.s3-ap-southeast-2.amazonaws.com/public/';
function fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
}
export const useImage = (image, defer = false) => {
    const [imageUrl, set] = useState();
    const prevImage = useRef();
    useEffect(() => {
        /** Return early if there's no image to get, deferral is set or the previously loaded image was this one */
        if (defer || prevImage.current === image)
            return;
        /** Load the image programatically, then set it. */
        new Promise((resolve, reject) => {
            if (!image) {
                resolve(undefined);
            }
            else if (image.includes("iiif")) {
                /** Handle IIIF images */
                fetch(image)
                    .then(res => res.json())
                    .then(({ thumbnail }) => resolve(thumbnail));
            }
            else if (image.includes("s3:")) {
                /** Handle */
                const [, s3Key] = image.split("s3:");
                resolve(s3BucketPrefix + fixedEncodeURIComponent(s3Key));
            }
            else if (image.includes("http")) {
                resolve(image);
            }
            else if (image.includes("bh/")) {
                const [, hash, width, height] = image.split("/");
                resolve(blurHashToURL(hash, width, height));
            }
            else {
                reject("Something wrong with the image string");
            }
        })
            .then(imageSrc => {
            if (imageSrc) {
                const imageEl = new Image();
                imageEl.onload = () => set(imageSrc);
                imageEl.src = imageSrc;
            }
        })
            .finally(() => (prevImage.current = image));
    }, [image, defer]);
    if (!image) {
        return null;
    }
    else {
        return imageUrl;
    }
};
