import React, { Dispatch, useReducer, useContext } from "react"
import { AppState, Action } from "./types"
import { reducer } from "./reducer"

const initialState = {
  avatarDialogOpen: false,
  waitingRoomDialogOpen: false,
  onboardingOpen: false,
  layersOpen: false,
  filterOpen: false,
  referencesOpen: false,
  projectionOpen: false,
  projectionOpenFromDefaultButton: false,
  projectionLoading: false,
  activeProjection: {
    timeLeft: undefined,
    categoryId: undefined,
    id: undefined,
  },
  isUserAdmitted: undefined,
}

export const AppContext = React.createContext<
  [AppState, Dispatch<Action>] | undefined
>(undefined)

/**
 * Keeps the state of the entire app
 */
export const AppProvider: React.FC<Partial<AppState>> = (props) => {
  const [state, dispatch] = useReducer(reducer, { ...initialState, ...props })

  return <AppContext.Provider value={[state, dispatch]} {...props} />
}

export const useApp = () => {
  const context = useContext(AppContext)
  if (!context) throw Error("Not inside <AppProvider />")
  return context
}
