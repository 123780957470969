import {
  makeStyles,
  Theme,
} from "@material-ui/core"
import React, { FC } from "react"

interface ReferencesContentProps {
  contentHeight: number
  onClick: (event: React.UIEvent | UIEvent) => void
  onTouchStart: (event: React.UIEvent | UIEvent) => void
  onTouchMove: (event: React.UIEvent | UIEvent) => void
  onTouchEnd: (event: React.UIEvent | UIEvent) => void
  onTouchCancel: (event: React.UIEvent | UIEvent) => void
  onPointerDown: (event: React.UIEvent | UIEvent) => void
  onPointerMove: (event: React.UIEvent | UIEvent) => void
  onPointerUp: (event: React.UIEvent | UIEvent) => void
  onPointerCancel: (event: React.UIEvent | UIEvent) => void
}

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    fontSize: "10px",
    padding: "16px",
    overflow: "auto",
    wordBreak: "break-word",
  },
}))

export const ReferencesContent: FC<ReferencesContentProps> = ({
  contentHeight,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div
      className={classes.container}
      style={{ height: contentHeight }}
      {...props}
    >
      Queens Wharf – The Gold Rush Broome, R. (2005) Aboriginal Victorians: A
      History since 1800, Allen & Unwin, Crows Nest, NSW
      <br />
      <br />
      Bunurong Land Council Aboriginal Corporation (2018) ‘Hoddle Grid Heritage
      Review: Aboriginal Place Research Report 5 Enterprize Park – Narm-Jaap –
      Queen’s Wharf’ Attached as Appendix 6 to Context (2018) Hoddle Grid
      Heritage Review Volume 3: Aboriginal Heritage Final Report March 2019,
      prepared for City of Melbourne
      <br />
      <br />
      Scar: A Stolen Vision Bunurong Land Council Aboriginal Corporation (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 5
      Enterprize Park – Narm-Jaap – Queen’s Wharf’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Enterprize Park Bunurong Land Council Aboriginal Corporation (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 5
      Enterprize Park – Narm-Jaap – Queen’s Wharf’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      John Fawkner’s Residence 424 Flinders Street, Melbourne Bunurong Land
      Council Aboriginal Corporation (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report 9 ‘John Fawkner’s Resident’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Former German Consulate 419 Collins Street, Melbourne Boon Wurrung
      Foundation (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research
      Report 7 ‘Former German Consulate’ Attached as Appendix 6 to Context
      (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage Final
      Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      News Editor (2012) ‘A Koori protest against the Nazis’ Blog State Library
      Victoria Such Was Life December 6, 2012 Available at:
      https://blogs.slv.vic.gov.au/such-was-life/a-koori-protest-against-the-nazis/
      (Accessed 25/02/21).
      <br />
      <br />
      The Falls Bunurong Land Council Aboriginal Corporation (2018) ‘Hoddle Grid
      Heritage Review: Aboriginal Place Research Report 5 ‘Enterprize Park –
      Narm-Japp – Queen’s Wharf’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      The Falls – Queens Bridge Bunurong Land Council Aboriginal Corporation
      (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 5
      ‘Enterprize Park – Narm-Japp – Queen’s Wharf’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Narm-Jaap (Queen's Wharf) Bunurong Land Council Aboriginal Corporation
      (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 5
      Enterprize Park – Narm-Jaap – Queen’s Wharf’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Ceremony Place 'on low ground between what are now Swanston and Elizabeth
      streets' Boon Wurrung Foundation (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report 3 ‘Princes Bridge Cultural Landscape’
      Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage Review
      Volume 3: Aboriginal Heritage Final Report March 2019, prepared for City
      of Melbourne
      <br />
      <br />
      ‘Lettsom Raid’ - Collins Street (approximate site) Fels, M. H. (2011) ‘‘I
      succeeded once’: the Aboriginal Protectorate on the Mornington Peninsula,
      1839-1840’. Aboriginal history monograph; 22. Australian National
      University Press, pg.114 Wurundjeri Woiwurrung Cultural Heritage
      Aboriginal Council (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place
      Research Report 6 ‘Old Melbourne Gaol’ Attached as Appendix 6 to Context
      (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage Final
      Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Melbourne Town Hall XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal
      Place Research Report X ‘Research report name’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne Meeting of
      Derrimut and Magistrate William Hull -– opposite the Bank of Victoria at
      24-28 Swanston Street. Bunurong Land Council Aboriginal Corporation (2019)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 10 Meeting
      Place: Derrimut and Magistrate William Hull’ Attached as Appendix 6 to
      Context (2019) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      St Paul's Cathedral Boon Wurrung Foundation (2018) ‘Hoddle Grid Heritage
      Review: Aboriginal Place Research Report 12 ‘St Paul’s Cathedral’ Attached
      as Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Wandin, J. 2000 in Victoria (2000) Parliamentary Debates, Legislative
      Assembly, 31 May 2000: 1993-94 (James Wandin, J)(Austl.) Available at:
      https://www.parliament.vic.gov.au/hansard/daily-hansard (Accessed DATE)
      <br />
      <br />
      Richards, C. (n.d.) Jim ‘Juby’ Wandin: Legend of the Healesville Football
      Club Available at: http://www.coranderrk.com/jim-juby-wandin-2/ (Accessed
      DATE)
      <br />
      <br />
      Federation Square - NAIDOC March Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne, pg. 73
      <br />
      <br />
      National NAIDOC Committee (published by the Commonwealth of Australia)?
      (n.d.) NAIDOC Week Available at:
      https://www.naidoc.org.au/about/naidoc-week (Accessed DATE)
      <br />
      <br />
      VIC NAIDOC (2021) Events Available via: https://vicnaidoc.com.au/events
      (Accessed DATE)
      <br />
      <br />
      Tanderrum at Federation Square Attwood, B. (with Doyle, H.) (2009)
      Possession: Batman's Treaty and the Matter of History The Miegunyah Press,
      Melbourne. Barwick, D. (1984) ‘Mapping the Past: an Atlas of Victorian
      Clans 1835-1904’ in I. McBryde, Ed. Aboriginal History (8)2: 100-131.
      Boucher, L. and Russell, L. 2015 ‘Introduction: Colonial history,
      postcolonial theory and the ‘Aboriginal problem’ in colonial Victoria’ in
      Settler Colonial Governance in Nineteenth-Century Victoria L. Boucher and
      L. Russell, Eds. ANU Press and Aboriginal History Inc., Canberra. Bunurong
      Land Council Aboriginal Corporation, Wurundjeri Woi-wurrung Cultural
      Heritage Aboriginal Corporation, Boon Wurrung Foundation (2018) ‘Hoddle
      Grid Heritage Review: Aboriginal Place Research Report 1 Parliament House
      and Hill’ Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne
      <br />
      <br />
      Eidelson, M. (2014). Yalukit Willam: The River People of Port Phillip City
      of Port Phillip, Victoria. Newton, J. (2017). ‘Two Victorian corroborees:
      Meaning making in response to European intrusion’ Aboriginal History (41)
      121-149. Retrieved July 21, 2020, from www.jstor.org/stable/90018870.
      Russell, L. (2015) ‘’Tickpen’, ‘Boro Boro’: Aboriginal economic
      engagements in early Melbourne’ in Settler Colonial Governance in
      Nineteenth-Century Victoria L. Boucher and L. Russell, Eds. ANU Press and
      Aboriginal History Inc., Canberra.
      <br />
      <br />
      Koorie Heritage Trust Koorie Heritage Trust (n.d.) About Koorie Heritage
      Trust Available at: About Us | Koorie Heritage Trust (Accessed DATE)
      <br />
      <br />
      Meeting Place - Princes Bridge Cultural Landscape Boon Wurrung Foundation
      (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 3
      ‘Princes Bridge Cultural Landscape’ Attached as Appendix 6 to Context
      (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage Final
      Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Princes Bridge and the Arts Centre 1940s and 1950s Boon Wurrung Foundation
      (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 3
      ‘Princes Bridge Cultural Landscape’ Attached as Appendix 6 to Context
      (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage Final
      Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      City of Melbourne (2021) Making a Show of It Available at:
      https://www.melbourne.vic.gov.au/arts-and-culture/city-gallery/exhibition-archive/Pages/Making-a-Show-of-It.aspx
      (Accessed 01/02/2021)
      <br />
      <br />
      State Government of Victoria (2019) William ‘Bill’ Onus: A leader and
      pioneering entrepreneur Available at:
      https://www.aboriginalvictoria.vic.gov.au/william-bill-onus (Accessed
      01/02/2021)
      <br />
      <br />
      ‘Yarra Bankers’ - Near Alexandra Gardens (formerly Yarra Bank Park) Boon
      Wurrung Foundation (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place
      Research Report 3 ‘Princes Bridge Cultural Landscape’ Attached as Appendix
      6 to Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal
      Heritage Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      May, A. (n.d.) Yarra Bank and Yarra Bankers Available at:
      https://www.emelbourne.net.au/biogs/EM01653b.htm (Accessed 01/02/2021)
      <br />
      <br />
      Speaker’s Corner - Birrarung Marr (formerly Flinders Park) Boon Wurrung
      Foundation (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research
      Report 3 ‘Princes Bridge Cultural Landscape’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Broome, R. (n.d.) Aboriginal Melbourne Available at:
      https://www.emelbourne.net.au/biogs/EM00029b.htm 01/02/2021 Accessed on
      01/02/2021 (Accessed DATE)
      <br />
      <br />
      City of Melbourne (2017) City Collection: Speakers Corner 1999 Available
      at: http://citycollection.melbourne.vic.gov.au/speakers-corner/ (Accessed
      01/02/2021)
      <br />
      <br />
      May, A. (n.d.) Yarra Bank and Yarra Bankers Available at:
      https://www.emelbourne.net.au/biogs/EM01653b.htm Accessed on 01/02/2021
      (Accessed DATE)
      <br />
      <br />
      Eel Trap City of Melbourne (2017) City Collection: Eel Trap 2003 Available
      at: http://citycollection.melbourne.vic.gov.au/eel-trap/ (Accessed DATE)
      <br />
      <br />
      Wurundjeri Woi Wurrung Cultural Heritage Aboriginal Corporation (2020)
      (pers com)
      <br />
      <br />
      Birrarung Wilam Art Installation City of Melbourne (2017) City Collection:
      Birrarung Wilam (Common Ground) 2006 Available at:
      http://citycollection.melbourne.vic.gov.au/birrarung-wilam-common-ground/
      (Accessed DATE)
      <br />
      <br />
      Fairfax Media Australia (2004) ‘Urban Design Award for Birrarung Marr’ The
      Age, [online] Available at:
      https://www.theage.com.au/national/urban-design-award-for-birrarung-marr-20041029-gdyw09.html
      Accessed 01/02/2021 (Accessed DATE)
      <br />
      <br />
      Kings Domain Resting Place Bunurong Land Council Aboriginal Corporation
      (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 13
      ‘State Library of Victoria’ Attached as Appendix 6 to Context (2018)
      Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage Final Report
      March 2019, prepared for City of Melbourne Bunurong Boon Wurrung Gathering
      Place (Government House and the Royal Botanic Gardens) Context and On
      Country Heritage & Consulting (2019) Hoddle Grid Heritage Review Volume 4:
      Aboriginal History Final Report March 2019, prepared for City of Melbourne
      pp. 5-6
      <br />
      <br />
      William Kyle (1906), "Reminiscences of Aboriginal Life in Victoria and New
      South Wales", The Geelong Naturalist, No. 31, Second Series, Vol. II, No.
      3 (March 1906), pp. 159-172, 159-60.
      <br />
      <br />
      Eastern Kulin Gathering Places, South Bank of the Yarra River Boon Wurrung
      Foundation (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research
      Report X ‘Princes Bridge Cultural Landscape Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Hunting - Tromgin Billabong (now site of ornamental lake) - Royal Botanic
      Gardens Clarke, I. D. & Kostanski, L. M. (2006) An Indigenous History of
      Stonnington: A Report to the City of Stonnington, prepared for the City of
      Stonnington p. 21
      <br />
      <br />
      Museums Victoria (2021) Eastern Kulin Seasonal Calendar Available at:
      https://museumsvictoria.com.au/bunjilaka/about-us/eastern-kulin-seasonal-calendar/
      (Accessed 05/04/2021)
      <br />
      <br />
      Langhorne’s Mission School XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Removing bark to make canoes - former Richmond and Friendly Societies
      reserve William Kyle, "Reminiscences of Aboriginal Life in Victoria and
      New South Wales", The Geelong Naturalist, No. 31, Second Series, Vol. II,
      No. 3 (March 1906), pp. 159-172.
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Wurundjeri Woi Wurrung Gathering Place (MCG and Yarra Park) Context and On
      Country Heritage & Consulting (2019) Hoddle Grid Heritage Review Volume 4:
      Aboriginal History Final Report March 2019, prepared for City of Melbourne
      pp. 5-6
      <br />
      <br />
      William Kyle (1906) “Reminiscences of Aboriginal Life in Victoria and New
      South Wales” The Geelong Naturalist, No. 31, Second Series, Vol. II, No. 3
      (March 1906), pp. 159-172, 159, 162.
      <br />
      <br />
      Deadly Story First game of Aussie Rules Football played, inspired by
      Marngrook Available at:
      https://www.deadlystory.com/page/culture/history/First_game_of_Australian_Rules_Football_played_in_Melbourne_based_on_Koorie_game_Marngrook
      (Accessed 01/11/2020)
      <br />
      <br />
      Aboriginal Scarred or culturally modified tree 'Yarra Park 2' Aboriginal
      Victoria Fact sheet: Aboriginal Scar Trees Available at:
      https://www.aboriginalvictoria.vic.gov.au/fact-sheet-aboriginal-scar-trees
      (Accessed 30/10/2020)
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Aboriginal Scarred or culturally modified tree 'Yarra Park 3' Aboriginal
      Victoria Fact sheet: Aboriginal Scar Trees Available at:
      https://www.aboriginalvictoria.vic.gov.au/fact-sheet-aboriginal-scar-trees
      (Accessed 30/10/2020)
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Aboriginal Scarred or culturally modified tree 'Yarra Park 1' Aboriginal
      Victoria Fact sheet: Aboriginal Scar Trees Available at:
      https://www.aboriginalvictoria.vic.gov.au/fact-sheet-aboriginal-scar-trees
      (Accessed 30/10/2020)
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Former Barracks of the Native Police XXXX (2018) ‘Hoddle Grid Heritage
      Review: Aboriginal Place Research Report X ‘Research report name’ Attached
      as Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Derrimut's Grave XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal
      Place Research Report X ‘Research report name’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne Koob-borr (Koala)
      and Birrarung (Yarra River) Smyth, R. B. (1878). The Aborigines of
      Victoria: with notes relating to the habits of the natives of other parts
      of Australia and Tasmania (Vol. I). Melbourne: John Ferres, Government
      Printer. pp. 447-449 Eastern Kulin Meetings - where the Dja Dja Wurrung
      stayed when visiting (Royal Park and Merri Creek) Context and On Country
      Heritage & Consulting (2019) Hoddle Grid Heritage Review Volume 4:
      Aboriginal History Final Report March 2019, prepared for City of Melbourne
      pp. 5-6
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne.
      <br />
      <br />
      Eastern Kulin Meetings – a place to stay when visiting (Western End of
      Lonsdale Street) Context and On Country Heritage & Consulting (2019)
      Hoddle Grid Heritage Review Volume 4: Aboriginal History Final Report
      March 2019, prepared for City of Melbourne pp. 5-6
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Eastern Kulin Meetings - where the Taungurung stayed when visiting
      (Clifton Hill) Context and On Country Heritage & Consulting (2019) Hoddle
      Grid Heritage Review Volume 4: Aboriginal History Final Report March 2019,
      prepared for City of Melbourne pp. 5-6
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Eastern Kulin Gathering place - Royal Park and Princes Park Context and On
      Country Heritage & Consulting (2019) Hoddle Grid Heritage Review Volume 4:
      Aboriginal History Final Report March 2019, prepared for City of Melbourne
      pp. 5-6
      <br />
      <br />
      Westgarth, W. (1888) Personal recollections of early Melbourne and
      Victoria (George Robertson & Co: Melbourne, 1888), pp. 14-15.
      <br />
      <br />
      East Kulin Gathering Place - Fawkner Park XXXX (2018) ‘Hoddle Grid
      Heritage Review: Aboriginal Place Research Report X ‘Research report name’
      Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage Review
      Volume 3: Aboriginal Heritage Final Report March 2019, prepared for City
      of Melbourne
      <br />
      <br />
      Filling of the Bay Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne p. 2
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Briggs, C. 2000 in Victoria (2000) Parliamentary Debates, Legislative
      Assembly, 31 May 2000: 1993-94 (James Wandin, J) (Austl.) Available at:
      https://www.parliament.vic.gov.au/hansard/daily-hansard
      <br />
      <br />
      Eastern Kulin Ceremony Place – South Melbourne Town Hall XXXX (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report X ‘Research
      report name’ Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne
      <br />
      <br />
      Argus, Wednesday 26 July 1854, page 5 Ceremonial Ground - Parliament Hill
      (approximate location) Bunurong Land Council Aboriginal Corporation,
      Wurundjeri Woi-wurrung Cultural Heritage Aboriginal Corporation, Boon
      Wurrung Foundation (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place
      Research Report 1 Parliament House and Hill’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne Victorian
      Parliament - Political Action Bunurong Land Council Aboriginal
      Corporation, Wurundjeri Woi-wurrung Cultural Heritage Aboriginal
      Corporation, Boon Wurrung Foundation (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report 1 Parliament House and Hill’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      First Peoples’ Assembly of Victoria Available at:
      https://www.firstpeoplesvic.org/about/ (Accessed 20/10/2020).
      <br />
      <br />
      Wahlquist, C. (2019) We are Turning this room Black – Inaugural First
      Peoples Assembly of Victoria Path to Treaty The Guardian Tue 10 Dec 2019
      Available at:
      https://www.theguardian.com/australia-news/2019/dec/10/we-are-turning-this-room-black-inaugural-first-peoples-assembly-of-victoria-start-path-to-treaty
      (Accessed 20/20/2020) Parliament House & Hill - Colonial Impact Bunurong
      Land Council Aboriginal Corporation, Wurundjeri Woi-wurrung Cultural
      Heritage Aboriginal Corporation, Boon Wurrung Foundation (2018) ‘Hoddle
      Grid Heritage Review: Aboriginal Place Research Report 1 Parliament House
      and Hill’ Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne
      <br />
      <br />
      Pastor Sir Doug and Lady Gladys Nicholls Statue – Parliament Gardens
      Bunurong Land Council Aboriginal Corporation, Wurundjeri Woi-wurrung
      Cultural Heritage Aboriginal Corporation, Boon Wurrung Foundation (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report 1
      Parliament House and Hill’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Parliament House mosaic (Another View Walking Trail, Trails of Feeling)
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      State Library of Victoria XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      John Batman Memorial, Queen Victoria Markets (Part of Another View Walking
      Trail) XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research
      Report X ‘Research report name’ Attached as Appendix 6 to Context (2018)
      Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage Final Report
      March 2019, prepared for City of Melbourne
      <br />
      <br />
      1000 Warriors March Spring Street XXXX (2018) ‘Hoddle Grid Heritage
      Review: Aboriginal Place Research Report X ‘Research report name’ Attached
      as Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Aborigines Advancement League, first office: 46 Russell St, Melbourne (Now
      the Hyatt Hotel) XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal
      Place Research Report X ‘Research report name’ Attached as Appendix 6 to
      Context (2018) Hoddle Grid Heritage Review Volume 3: Aboriginal Heritage
      Final Report March 2019, prepared for City of Melbourne
      <br />
      <br />
      Old Melbourne Gaol (1845 - 1921) 377 Russell Street, Melbourne XXXX (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report X ‘Research
      report name’ Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne
      <br />
      <br />
      Standing by Tunnerminnerwait and Maulboyheenner (Gallows Hill) XXXX (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report X ‘Research
      report name’ Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne
      <br />
      <br />
      Tullamareena’s Escape - Melbourne's first thatched roofed jail Lonsdale,
      W. to Col. sec., 26 April 1838, in Michael Cannon, editor-in-chief, (with
      Pauline Jones, ed.) (1981) Historical records of Victoria: Volume One,
      Beginnings of Permanent Government, compiled by the Public Records Office.
      Victorian Government Printing Office, Melbourne, pp. 512-513.
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne Former Melbourne Hospital (later
      Queen Victoria Hospital). XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      The Protectorate - Old Treasury Building
      https://guides.slv.vic.gov.au/law/acts
      <br />
      <br />
      https://guides.slv.vic.gov.au/Victoriasearlyhistory/aboriginalaustralians
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      The Coranderrk Inquiry - Old Treasury Building XXXX (2018) ‘Hoddle Grid
      Heritage Review: Aboriginal Place Research Report X ‘Research report name’
      Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage Review
      Volume 3: Aboriginal Heritage Final Report March 2019, prepared for City
      of Melbourne
      <br />
      <br />
      Ann Bon’s Residence Windsor Hotel XXXX (2018) ‘Hoddle Grid Heritage
      Review: Aboriginal Place Research Report X ‘Research report name’ Attached
      as Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      The Moreton Bay Fig Tree Gathering Place XXXX (2018) ‘Hoddle Grid Heritage
      Review: Aboriginal Place Research Report X ‘Research report name’ Attached
      as Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      The Blue Salt Water Lagoon and Marsh from Batman’s Hill Agriculture
      Victoria (2020) accessed 27 August 2020
      http://vro.agriculture.vic.gov.au/dpi/vro/vrosite.nsf/pages/sip_salt_native_yam
      Andrew C. Ward and Associates with Milner, P., Vines, G., and Greenaway,
      R. (1991) Docklands Heritage Study: Commissioned by the Historic Buildings
      Council and the Docklands Task Force Australian National Herbarium (2019)
      accessed 27 August 2020
      https://www.anbg.gov.au/gnp/interns-2005/carpobrotus-glaucescens.html
      Biosis (2017) Southbank and Fisherman’s Bend Heritage Review, prepared for
      City of Melbourne Context (2011) Thematic History – A History of the City
      of Melbourne’s Urban Environment Planning for the Future Growth Ecolink
      accessed 27 August 2020
      https://grasslands.ecolinc.vic.edu.au/fieldguide/flora/twining-fringe-lily#details
      Greening Australia Fact Sheet Microseris Lanceolata accessed 27 August
      2020
      https://www.greeningaustralia.org.au/wp-content/uploads/2017/11/FACT-SHEET_Microseris_lanceolata.pdf
      McCrae, G. G. (1912) ‘Some Recollections of Melbourne in the ‘Forties’ ‘in
      The Victorian Historical Magazine, Vol. 2, No. 3 (November 1912) pp:
      114-136, 117-118, 123. XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Gathering yams near the Blue Lagoon Agriculture Victoria 2020 accessed 27
      August 2020
      http://vro.agriculture.vic.gov.au/dpi/vro/vrosite.nsf/pages/sip_salt_native_yam
      Andrew C. Ward and Associates with Milner, P., Vines, G., and Greenaway,
      R. (1991) Docklands Heritage Study: Commissioned by the Historic Buildings
      Council and the Docklands Task Force Australian National Herbarium 2019
      accessed 27 August 2020
      https://www.anbg.gov.au/gnp/interns-2005/carpobrotus-glaucescens.html
      Biosis (2017) Southbank and Fisherman’s Bend Heritage Review: Prepared for
      City of Melbourne Context (2011) Thematic History – A History of the City
      of Melbourne’s Urban Environment Planning for the Future Growth Ecolink
      accessed 27 August 2020
      https://grasslands.ecolinc.vic.edu.au/fieldguide/flora/twining-fringe-lily#details
      Greening Australia Fact Sheet Microseris Lanceolata accessed 27 August
      2020
      https://www.greeningaustralia.org.au/wp-content/uploads/2017/11/FACT-SHEET_Microseris_lanceolata.pdf
      McCrae, G. G. (1912) ‘Some Recollections of Melbourne in the ‘Forties’ ‘in
      The Victorian Historical Magazine, Vol. 2, No. 3 (November 1912) pp:
      114-136, 117-118, 123. XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Travelling up the Birrarung - South Bank and Fisherman’s Bend XXXX (2018)
      ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report X ‘Research
      report name’ Attached as Appendix 6 to Context (2018) Hoddle Grid Heritage
      Review Volume 3: Aboriginal Heritage Final Report March 2019, prepared for
      City of Melbourne
      <br />
      <br />
      G. F. B., 'Memories of Old Melbourne', Geelong Advertiser, Thursday 6
      March 1902, page 2).
      <br />
      <br />
      Aboriginal Scarred or culturally modified tree (Princes Park) Aboriginal
      Victoria Fact sheet: Aboriginal Scar Trees
      https://www.aboriginalvictoria.vic.gov.au/fact-sheet-aboriginal-scar-trees
      Accessed 30 October 2020 XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Aboriginal Scarred or culturally modified tree Fitzroy Gardens Aboriginal
      Victoria Fact sheet: Aboriginal Scar Trees
      https://www.aboriginalvictoria.vic.gov.au/fact-sheet-aboriginal-scar-trees
      Accessed 30 October 2020 Aboriginal Scarred or culturally modified tree
      Melbourne Zoo Aboriginal Victoria Fact sheet: Aboriginal Scar Trees
      https://www.aboriginalvictoria.vic.gov.au/fact-sheet-aboriginal-scar-trees
      Accessed 30 October 2020
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Bunjilaka (Melbourne Museum) XXXX (2018) ‘Hoddle Grid Heritage Review:
      Aboriginal Place Research Report X ‘Research report name’ Attached as
      Appendix 6 to Context (2018) Hoddle Grid Heritage Review Volume 3:
      Aboriginal Heritage Final Report March 2019, prepared for City of
      Melbourne
      <br />
      <br />
      Koorie Heritage Trust (n.d.) About Koorie Heritage Trust Available at:
      About Us | Koorie Heritage Trust Accessed 02/01/2021
      <br />
      <br />
      XXXX (2018) ‘Hoddle Grid Heritage Review: Aboriginal Place Research Report
      X ‘Research report name’ Attached as Appendix 6 to Context (2018) Hoddle
      Grid Heritage Review Volume 3: Aboriginal Heritage Final Report March
      2019, prepared for City of Melbourne
      <br />
      <br />
      Museums Victoria, 2021 About Us: Wominjeka. Welcome. Available at:
      https://museumsvictoria.com.au/bunjilaka/about-us/ Accessed 02/01/2021
    </div>
  )
}
