import { useEffect, useRef } from "react"

/**
 * This keeps a previous value of state. This is useful if you
 * have state that can can have more than 2 values (i.e. non-binary). You
 * can compare the previous state, which this hooks returns, to the new state.
 */
export function usePreviousValue<T = unknown>(state: T) {
  const ref = useRef<T | undefined>()

  useEffect(() => {
    ref.current = state
  }, [state])

  return ref.current
}
