import { useEffect } from "react";
import { WebMercatorViewport } from "@math.gl/web-mercator";
import { useGetViewState, useMap } from "@citydna/maps";
import { useActiveFeature } from "./ActiveFeatureContext";
export const ActiveFeature = ({ activeCard, activeCardWidth, windowPadding, zoomFactor, }) => {
    const { setActiveRefId } = useActiveFeature();
    const viewport = useGetViewState();
    const map = useMap();
    useEffect(() => {
        if (!activeCard || !activeCard?.lnglat || !map)
            return;
        /**
         * Set the active ref ID so the basemap updates
         */
        setActiveRefId(activeCard.itemId);
        const [lng, lat] = activeCard.lnglat;
        /** calculate the midpoint between active card and right side of browser viewport */
        const offset = window.innerWidth / 2 + (activeCardWidth + 2 * windowPadding) / 2;
        /** get the new lat lng based on offset desired position in pixels */
        const [longitude, latitude] = new WebMercatorViewport({
            width: window.innerWidth,
            height: window.innerHeight,
            latitude: lat,
            longitude: lng,
            zoom: zoomFactor || viewport.zoom,
            pitch: viewport.pitch,
        }).getMapCenterByLngLatPosition({
            lngLat: activeCard.lnglat,
            pos: [offset, window.innerHeight / 2],
        });
        // stop any current map animations
        map.stop();
        map.flyTo({
            center: [longitude, latitude],
            zoom: zoomFactor || viewport.zoom,
            pitch: viewport.pitch,
            duration: 1500,
            bearing: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setActiveRefId, activeCard]);
    /** remove the active icon from the map when cards are empty */
    useEffect(() => {
        if (!activeCard) {
            setActiveRefId(undefined);
        }
    }, [activeCard, setActiveRefId]);
    return null;
};
