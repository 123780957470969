import React, { FC } from "react"
import { Box, Fab } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { MapView2D, MapView3D } from "@citydna/common"

type Toggle3DProps = {
  is3D: boolean
  toggle: () => void
}

export const Toggle3D: FC<Toggle3DProps> = ({ is3D, toggle }) => {
  const { t } = useTranslation()

  return (
    <Box
      position="absolute"
      right={0}
      top={46}
      mt={10}
      mr={2}
      zIndex={8}
      whiteSpace="nowrap"
    >
      <Fab
        size="small"
        onClick={toggle}
        title={t("map.controls.dimensionToggle.title")}
      >
        {!is3D || is3D === undefined ? (
          <MapView3D width="2em" height="2em" />
        ) : (
          <MapView2D width="2em" height="2em" />
        )}
      </Fab>
    </Box>
  )
}
