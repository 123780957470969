import { Config, Resource } from "@citydna/experience"
import { createAgolUrl, weaveAuthorization } from "../api/utils"
import qs from "qs"
import { WEAVE_URL, BUILDING_BASEPROP_URL } from "../constants"

export const getProjectionCategories = async ({
  bucketUrl,
  environment,
}: {
  bucketUrl: string
  environment: string
}) => {
  const response = await fetch(
    `${bucketUrl}/${environment}/citydna/categories.json`
  )
  if (!response.ok) {
    throw new Error("Projection categories request failed")
  }
  return response.json()
}

export const getFeatureCollectionFromConfig = async ({
  source,
  type,
}: Resource) => {
  const response = await fetch(
    `${source}${type === "agol" ? createAgolUrl({ f: "geojson" }) : ""}`
  )
  if (!response.ok) {
    throw new Error("Feature collection request failed")
  }
  return response.json()
}

export const getResultsFromStrapi = async ({
  source,
  apiKey,
  sortFields,
  filters,
}: Resource) => {
  const query = qs.stringify({
    ...(filters && { filters }),
    ...(sortFields && { sort: sortFields }),
    populate: "*",
    pagination: {
      pageSize: 9999,
    },
  })
  const response = await fetch(`${source}?${query}`, {
    headers: { Authorization: `Bearer ${apiKey}` },
  })
  if (!response.ok) {
    throw new Error("Feature collection request failed")
  }
  return response.json()
}

export const getLayerStylesConfig = async ({
  bucketUrl,
  environment,
  configId,
}: {
  bucketUrl: string
  environment: string
  configId: string
}) => {
  const response = await fetch(
    `${bucketUrl}/${environment}/${configId}/mapboxStyles.json`
  )
  if (!response.ok) {
    throw new Error("Mapbox layer styles request failed")
  }
  return response.json()
}

/**
 * Returns a list of themes, if the source is Strapi use the theme URL to get information
 */
export const getThemesData = async (config: Config) => {
  if (config.filterComponent !== "Filters") {
    return 
  }
  let url = ""
  const resource = config.resources[0]
  let response
  if (resource.type === "strapi") {
    const query = qs.stringify({
      fields: resource.themeFields || ["*"],
    })
    url = `${resource.themeSource}?${query}`
    response = await fetch(url, {
      headers: { Authorization: `Bearer ${resource.apiKey}` },
    })
  }
  if (resource.type === "agol") {
    url = `${resource.source}${createAgolUrl({
      f: "json",
      outFields:
        config.id === "state-library-images"
          ? "dna_artwork_type"
          : "dna_category",
      returnDistinctValues: true,
      returnGeometry: false,
    })}`
    response = await fetch(url)
  }
  if (!response) {
    throw new Error("Themes data request failed")
  }
  return response.json()
}

export const getCoMPropertyFromWeaveApi = async (query: string) => {
  if (!query) {
    return
  }
  const response = await fetch(
    "https://weave-proxy-api-v2.au-s1.cloudhub.io/weave/v1/index/search" +
      "?" +
      qs.stringify({
        query,
        start: 0,
        limit: 10,
        indexes: "lyr_property",
        type: "WILDCARD",
        crs: "EPSG:4326",
      }),
    {
      headers: weaveAuthorization,
    }
  )
  if (!response.ok) {
    throw new Error("CoM property request failed")
  }
  return response.json()
}

export const getParentPropertyFeature = async (
  propertyId: number | undefined
) => {
  if (!propertyId) {
    return undefined
  }
  const propertyResponse = await fetch(
    WEAVE_URL +
      "?" +
      qs.stringify({
        ids: propertyId,
        metadata: false,
        inlineAttributes: false,
      }),
    {
      headers: weaveAuthorization,
    }
  )
  if (!propertyResponse.ok) {
    throw new Error("CoM property request failed - 1/2")
  }
  const propertyResponseResult = await propertyResponse.json()
  const parentPropId = propertyResponseResult.results[0].slice(-2)[0]
  const parentPropFeatureResponse = await fetch(
    `${BUILDING_BASEPROP_URL}/query?where=mccid_int=${parentPropId}&f=geojson`
  )
  if (!parentPropFeatureResponse.ok) {
    throw new Error("CoM parent property request failed - 2/2 ")
  }
  return parentPropFeatureResponse.json()
}
