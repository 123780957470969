import React, { forwardRef, useState } from "react";
import { Marker } from "react-map-gl";
import useOutsideClickRef from "@rooks/use-outside-click-ref";
import { Box } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { MarkerDot } from "./MarkerDot";
import { MarkerIcon } from "./MarkerIcon";
import { MarkerDialog } from "./MarkerDialog";
export const UncontrolledMarkerPopup = forwardRef(({ color, children, title, icon: Icon, ...props }, ref) => {
    const [open, setOpen] = useState(false);
    const [outsideClickRef] = useOutsideClickRef(() => {
        setOpen(false);
    });
    console.log("open", open);
    return (React.createElement("div", { ref: ref },
        React.createElement(Marker, { ...props },
            React.createElement(Box, { position: "relative", zIndex: open ? 99 : 1, "data-testid": "UnontrolledMarkerPopup" },
                React.createElement("div", null,
                    React.createElement(MarkerDot
                    // @ts-ignore
                    , { 
                        // @ts-ignore
                        boxShadow: open ? 0 : 4, color: !open && color, onClick: () => {
                            console.log("clicked");
                            setOpen((o) => !o);
                        }, "aria-haspopup": !!children, title: title, "aria-label": title },
                        React.createElement(MarkerIcon, { visible: open },
                            React.createElement(CloseIcon, { color: "action" })),
                        Icon && (React.createElement(MarkerIcon, { visible: !open },
                            React.createElement(Icon, { color: "action" })))),
                    children && open && "fuck you",
                    children && open && (React.createElement(MarkerDialog, { visible: true }, children)))))));
});
