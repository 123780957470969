import React from "react"
import {
  makeStyles,
  Theme,
  Dialog as MuiDialogue,
  IconButton,
  Box,
} from "@material-ui/core"
import { PORTAL_OUTLET_ID } from "../common/constants"
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons"
import { Avatar } from "@citydna/experience"
import { ConnectionDot } from "./ConnectionDot"
import { useApp } from "./AppState"

const useStyles = makeStyles<Theme>((theme) => ({
  dialog: {
    "& .MuiPaper-root": {
      backgroundColor:
        theme.palette.type === "dark"
          ? theme.palette.grey[600]
          : theme.palette.grey[100],
      padding: theme.spacing(2),
      border: "none",
      borderRadius: theme.spacing(1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    alignItems: "center",
    borderBottom: "1px solid #3C404B",
    paddingBottom: "12px",
  },
  closeButton: {
    position: "absolute",
    top: "0",
    right: "12px",
  },
  avatarStyles: {
    width: 38,
    height: 38,
    marginRight: theme.spacing(1),
  },
  footer: {
    width: "100%",
    paddingTop: theme.spacing(2),
    // 4px is the height of the blue timer
    paddingBottom: "4px",
  },
  bodyContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #3C404B",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  connectionDot: {
    position: "absolute",
    /** move the dot up by half the icons width, and right by 28. 28 just felt right */
    transform: "translate(28px, -14px)",
    zIndex: 9,
  },
}))

type DialogProps = {
  dialogCloseCallback: () => void
  enableBackdropClick?: boolean
  isDialogOpen: boolean
  body: string | JSX.Element
  lottie: JSX.Element
  footer?: JSX.Element
}

/**
 * Simple dialogue which has a header, body that supports lottie and text, and a footer
 *
 * Use this to keep consistent styling for dialogs
 */
export const Dialog: React.FC<DialogProps> = ({
  dialogCloseCallback,
  enableBackdropClick = true,
  isDialogOpen,
  lottie,
  body,
  footer,
}) => {
  const classes = useStyles()
  const [{ isUserAdmitted }] = useApp()

  // @ts-ignore
  const onClose = (event, reason: string) => {
    if (reason === "backdropClick" && enableBackdropClick) {
      dialogCloseCallback()
    }
  }

  return (
    <MuiDialogue
      container={document.getElementById(PORTAL_OUTLET_ID)}
      open={isDialogOpen}
      onClose={onClose}
      className={classes.dialog}
    >
      <div className={classes.iconContainer}>
        <ConnectionDot
          connected={isUserAdmitted === "yes"}
          className={classes.connectionDot}
        />
        <Avatar className={classes.avatarStyles} />
        <span>Your profile</span>
        <IconButton
          size="small"
          edge="end"
          onClick={dialogCloseCallback}
          className={classes.closeButton}
        >
          <CloseRoundedIcon style={{ width: 18 }} />
        </IconButton>
      </div>
      <Box className={classes.bodyContainer}>
        {lottie}
        <div>{body}</div>
      </Box>
      <div className={classes.footer}>{footer}</div>
    </MuiDialogue>
  )
}
