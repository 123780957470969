import { useCallback, useEffect } from "react";
import { MELBOURNE_VIEWPORT } from "../constants";
import { useMap } from "./useMap";
/**
 * Return a setter method that will set the viewport to Melbourne
 */
export function useGoToMelbourne(runOnMount = false) {
    const map = useMap();
    const go = useCallback(() => {
        map.flyTo({
            center: [MELBOURNE_VIEWPORT.longitude, MELBOURNE_VIEWPORT.latitude],
            zoom: MELBOURNE_VIEWPORT.zoom,
            pitch: 35,
            duration: 1500,
            bearing: 0,
        });
    }, [map]);
    useEffect(() => {
        if (runOnMount && map) {
            go();
        }
    }, [go, map, runOnMount]);
    return go;
}
