import React from "react";
import { a, useTransition } from "@react-spring/web";
import { Box, makeStyles } from "@material-ui/core";
const useClasses = makeStyles(() => ({
    cardStackContainer: {
        height: "100%",
        width: "max-content",
        position: "relative",
    },
}));
/**
 * Handles displaying cards in the card stack, it can animate
 * changing heights of the card from queued to active state.
 *
 * Visible controls how much of a queued card is seen.
 */
export const CardStackManager = ({ cards, visible, timeLeft, activeCard = {
    width: 400,
    height: 600,
}, queuedCard = {
    width: 400,
    height: 200,
}, activeCardComponent: Card, queuedCardHeightReduction = 50, }) => {
    const classes = useClasses();
    /**
     * Calculate the x position of each card
     */
    const calculateOffset = (index) => {
        if (index === 0) {
            return 0;
        }
        return activeCard.width - (queuedCard.width - visible * index);
    };
    const transitions = useTransition(cards, {
        key: (child) => child.id,
        from: (child) => ({
            x: calculateOffset(child.index),
            y: 1000,
            width: child.index === 0 ? `${activeCard?.width}px` : `${queuedCard?.width}px`,
            height: child.index === 0
                ? `${activeCard?.height}px`
                : `${queuedCard?.height - (child.index - 1) * queuedCardHeightReduction}px`,
        }),
        enter: {
            y: 0,
        },
        update: (child) => {
            return [
                {
                    x: calculateOffset(child.index),
                },
                {
                    width: child.index === 0
                        ? `${activeCard?.width}px`
                        : `${queuedCard.width}px`,
                    height: child.index === 0
                        ? `${activeCard?.height}px`
                        : `${queuedCard?.height -
                            (child.index - 1) * queuedCardHeightReduction}px`,
                },
            ];
        },
        leave: {
            x: -(activeCard.width + 100),
        },
    });
    return (React.createElement(Box, { className: classes.cardStackContainer }, transitions((style, card, _t, index) => (React.createElement(a.div, { style: {
            ...style,
            bottom: 0,
            position: "absolute",
            zIndex: -index,
        } },
        React.createElement(Card, { active: index === 0, card: card, timeLeft: index === 0 ? timeLeft : 0 }))))));
};
