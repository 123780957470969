import React from "react"
import { makeStyles, Theme, Typography, Fab, Box } from "@material-ui/core"
import {
  AddRounded as AddRoundedIcon,
  RemoveRounded as RemoveRoundedIcon,
} from "@material-ui/icons"
import { useTranslation } from "react-i18next"
import { useSpring, animated } from "react-spring"
import { useDrag } from "react-use-gesture"
import { Avatar, Avatars, TourCard, TourCardProps } from "@citydna/experience"
import { useApp } from "../AppState"
import { Fullscreen as FullScreenIcon } from "@material-ui/icons"
import { useConfig } from "@citydna/experience"

export interface TourCardWrapperProps extends TourCardProps {
  /** Props for the avatar as it's outside the provider */
  avatar?: Record<string, string>
  /** Callback fired when the add button is clicked */
  onAdd?: () => void
  handleSetExpandImage?: () => void
  expandImage?: boolean
}

type StyleProps = { height: number }

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  addFab: {
    marginLeft: 0,
    position: "absolute",
    // @ts-ignore
    WebkitBackfaceVisibility: "hidden",
    // @ts-ignore
    backfaceVisibility: "hidden",
  },
  avatar: {
    marginLeft: 0,
    position: "absolute",
    "& svg": {
      width: 24,
      height: 24,
    },
  },
  iconOverride: {
    color: theme.palette.grey[400],
  },
}))

/** Generate react-spring animated versions of our components */
const AnimatedFab = animated(Fab)
const AnimatedAvatar = animated(Avatar)

/**
 * Clamps a number between a range
 * @param number the number to clamp
 * @param min The min possible clamp
 * @param max the max possible clamp
 */
function clamp(number: number, min: number, max: number) {
  if (min > max) throw new RangeError("`min` should be lower than `max`")
  if (number < min) return min
  if (number > max) return max
  return number
}

export const TourCardWrapper: React.FC<TourCardWrapperProps> = ({
  className,
  onAdd,
  avatar,
  onClose,
  handleSetExpandImage,
  ...props
}) => {
  const { t } = useTranslation<string>()
  const [{ isUserAdmitted }] = useApp()
  const classes = useStyles({ height: window.innerHeight })

  /** Flipping avatar animation */
  const [translationProps, set] = useSpring(() => ({
    rotation: 0,
  }))

  const bind = useDrag(({ last, movement: [, yMovement] }) => {
    if (yMovement < 0) {
      set({
        rotation: clamp((Math.abs(yMovement) / 60) * -180, -180, 0),
        immediate: true,
      })
    }

    if (last) {
      set({
        rotation: 0,
        immediate: false,
      })
    }
  })

  /** When the add button is clicked, we flip the avatar and call the onAdd */
  const handleAdd = () => {
    if (isUserAdmitted === "yes") {
      set({ rotation: 180, immediate: false })
      setTimeout(() => onAdd && onAdd(), 250)
    }
  }

  return (
    <TourCard
      isPublicApp
      onClose={onClose}
      {...props}
      summary={props.summary}
      {...bind()}
      className={className}
      expandBackgroundImageActions={
        <Fab size="small" onClick={handleSetExpandImage}>
          <FullScreenIcon />
        </Fab>
      }
      avatarKey={props.avatarKey}
      actions={
        <>
          <Typography variant="body2" component="label">
            {isUserAdmitted === "yes"
              ? t("map.tourCard.addButtonLabel")
              : t("map.tourCard.deniedAddButtonLabel")}
          </Typography>
          <Box position="relative" width={40} height={40}>
            {avatar && isUserAdmitted === "yes" && (
              <AnimatedAvatar
                size="small"
                className={classes.avatar}
                color={avatar?.color}
                icon={avatar?.icon as Avatars}
                style={{
                  transform: translationProps.rotation.interpolate(
                    (r: any) => `perspective(600px) rotateY(${180 - r}deg)`
                  ),
                }}
              />
            )}
            <AnimatedFab
              size="small"
              onClick={handleAdd}
              title={
                isUserAdmitted === "yes"
                  ? t("map.tourCard.addButtonTitle", { title: "title" })
                  : t("map.tourCard.deniedAddButtonLabel")
              }
              style={{
                transform: translationProps.rotation.interpolate(
                  (r) => `perspective(600px) rotateY(${r}deg)`
                ),
              }}
              disabled={isUserAdmitted !== "yes"}
              className={classes.addFab}
            >
              {isUserAdmitted !== "yes" ? (
                <RemoveRoundedIcon className={classes.iconOverride} />
              ) : (
                <AddRoundedIcon />
              )}
            </AnimatedFab>
          </Box>
        </>
      }
    />
  )
}
