import { useConfig } from "../ConfigProvider";
import { useLoadFullCardFromAgol, } from "./useLoadFullCardFromAgol";
import { useFeatureLayers } from "./useFeatureLayers";
import usePromise from "react-use-promise";
import { useMemo, useState } from "react";
import { polygon } from "@turf/helpers";
import buffer from "@turf/buffer";
/** The projection calibration bounding box */
const PROJECTION_BOUNDING_BOX = [
    [
        [144.9311951089, -37.8313714531],
        [144.9892537824, -37.8313714531],
        [144.9892537824, -37.8001150568],
        [144.9311951089, -37.8001150568],
        [144.9311951089, -37.8313714531],
    ],
];
/** Let's give it a negative buffer so we don't get properties on the edge */
const HODDLE_BBOX_NEGATIVE_BUFFER = buffer(polygon(PROJECTION_BOUNDING_BOX), -0.5);
/** Returns a function that returns a random property */
export const useRandomFeature = ({ loadFullCardArgs, spatialReference, includeRandomCards, boundToHoddle, }) => {
    if (!includeRandomCards) {
        return;
    }
    const config = useConfig();
    const { metadataLayer } = useFeatureLayers(config);
    /** set up counter to declare the string for the query so that it always puts a poi after a development */
    const [count, setCount] = useState(0);
    const dna_type = useMemo(() => (count % 2 === 0 ? "development" : "poi"), [count]);
    const [featureIds] = usePromise(() => {
        const args = {
            where: `1=1 AND dna_type='${dna_type}'`,
        };
        if (boundToHoddle) {
            args.spatialRelationship = "contains";
            args.geometry = {
                // @ts-ignore
                type: "polygon",
                rings: HODDLE_BBOX_NEGATIVE_BUFFER.geometry.coordinates,
            };
        }
        return metadataLayer
            ? metadataLayer.queryObjectIds(args)
            : Promise.resolve(undefined);
    }, [metadataLayer, boundToHoddle, dna_type]);
    /** Grab the function that loads a full card */
    const loadFullCard = useLoadFullCardFromAgol(config, spatialReference);
    return useMemo(() => ({
        getRandomFeature: () => {
            if (featureIds) {
                setCount(count + 1);
                // generate a random index to pull up next (pray to god it's totally random)
                const randomIndex = getRandomInt(0, featureIds.length - 1);
                // first load the random point
                return loadFullCard({
                    where: `OBJECTID=${featureIds[randomIndex]}`,
                    polygons: false,
                }).then(({ point }) => 
                // then load the associated point with polygons. shame we have to do 2 calls.
                loadFullCard({
                    where: `${config?.metadataRefField}='${point.attributes[config?.metadataRefField]}'`,
                    polygons: loadFullCardArgs({ id: "1", refId: 1 }).polygons,
                }));
            }
            else {
                return Promise.reject("FeatureIds haven't loaded yet.");
            }
        },
        ready: Boolean(featureIds),
    }), [featureIds, loadFullCard, loadFullCardArgs, count, setCount]);
};
/**
 * Returns a random integer between min and max, inclusive
 * @param min number
 * @param max number
 */
function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
