import React from "react";
import { MapProvider as ReactMapGlProvider } from "react-map-gl";
import { RecoilRoot } from "recoil";
/**
 * Wrap each map in your application with this Provider. It allows manipulation of the viewport using hooks.
 *
 * Use recoil to manage component state.
 */
export const MapProvider = ({ children }) => {
    return (React.createElement(RecoilRoot, { override: false },
        React.createElement(ReactMapGlProvider, null, children)));
};
