import React, { createContext, useContext, useState } from "react"

interface PropertyFilterContextValue {
  activePropertyFilters: ActivePropertyFilters
  setActivePropertyFilters: React.Dispatch<React.SetStateAction<ActivePropertyFilters>>
}

const PropertyFilterContext = createContext<PropertyFilterContextValue | undefined>(undefined)

export type ActivePropertyFilters = {
  applied: boolean
  approved: boolean
  under_construction: boolean
  poi: boolean
  default: boolean
}

export const PropertyFilterProvider: React.FC = ({ children }) => {

  const [activePropertyFilters, setActivePropertyFilters] = useState<
  ActivePropertyFilters
  >({
    applied: false,
    approved: false,
    under_construction: false,
    poi: false,
    default: false,
  })

  return (
    <PropertyFilterContext.Provider value={{ activePropertyFilters, setActivePropertyFilters }}>
      {children}
    </PropertyFilterContext.Provider>
  )
}

export default PropertyFilterProvider

export const usePropertyFilter = () => {
  const context = useContext(PropertyFilterContext)
  if (!context) throw Error("Not inside <PropertyFilterProvider />")
  return context
}
