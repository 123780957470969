import { Dingo, Emu, Fish, Goanna, Kangaroo, Koala, Possum, Swan, Wombat, } from "@citydna/common";
/** Map of all icons possible for a users avatar */
export const AvatarIconMap = {
    dingo: Dingo,
    emu: Emu,
    fish: Fish,
    goanna: Goanna,
    kangaroo: Kangaroo,
    koala: Koala,
    possum: Possum,
    swan: Swan,
    wombat: Wombat,
};
