import React, { FC, useMemo } from "react"
import {
  Box,
  Button,
  Divider,
  Fab,
  makeStyles,
  Typography,
} from "@material-ui/core"
import {
  AddRounded as AddRoundedIcon,
  RemoveRounded as RemoveRoundedIcon,
} from "@material-ui/icons"
import {
  ChevronLeftIcon,
  TickIcon,
  Legend,
  Point,
  Line,
  Polygon,
  DynamicPoint,
  PolygonPolyline,
  shapeTypes,
  bodyTypes,
  ProjectionLegendItem,
} from "@citydna/common"
import { useBuildProjectionLegend, Dataset } from "@citydna/experience"
import { TouchScrollable } from "react-scrolllock"
import { ProjectionProgressBar } from "../../common/ProgressBar/ProjectionProgressBar"
import { useApp, actionCreators } from "../../common/AppState"

const bodyLookup = {
  DynamicPoint: DynamicPoint,
  PolygonPolyline: PolygonPolyline,
}

const shapeLookup = {
  point: Point,
  line: Line,
  polygon: Polygon,
}

const useStyles = makeStyles((theme) => ({
  projectionDetailContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
  },
  topContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  body: {
    marginTop: theme.spacing(1),
    // adjust the height to fit inside the container, need to subtract the padding from the container and the top
    height: `calc(100% - ${theme.spacing(7)}px)`,
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    width: "12px",
    height: "20px",
    marginRight: theme.spacing(1.5),
  },
  category: {
    fontSize: "14px",
    lineHeight: "21.33px",
  },
  subcategory: {
    textTransform: "none",
    fontSize: "20px",
    marginBottom: theme.spacing(1),
    lineHeight: "30.38px",
  },
  legendTitle: {
    fontSize: "12px",
  },
  legendDivider: {
    background: theme.palette.grey[500],
  },
  imageContainer: {
    marginBottom: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: "194px",
  },
  description: {
    overflow: "auto",
    flexGrow: 1,
    paddingRight: theme.spacing(2)
  },
  toggleContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTop: "1px solid #3C404B",
    fontSize: "14px",
    width: "100%",
    padding: "8px 0",
  },
  footer: {
    width: "100%",
    borderTop: "1px solid #3C404B",
    position: "absolute",
    bottom: 0,
  },
  iconOverride: {
    color: theme.palette.grey[400],
  },
  toggleIconContainer: ({
    isActiveProjection,
  }: {
    isActiveProjection: boolean
  }) => ({
    backgroundColor: isActiveProjection
      ? theme.palette.grey[400]
      : theme.palette.grey[700],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    border: "1px solid #5D616C",
    marginLeft: "14px",
    width: "40px",
    height: "40px",
  }),
}))

interface ProjectionDetailProps {
  dataset: Dataset | undefined
  categoryTitle: string
  categoryId: string
  setDatasetIndex: React.Dispatch<React.SetStateAction<string | undefined>>
  handleToggle: (id: string) => void
}

const getText = (
  activeProjectionId: string | undefined,
  datasetId: string | undefined,
  isUserAdmitted: boolean
) => {
  if (!isUserAdmitted) {
    return "Cannot add to queue"
  }
  if (activeProjectionId === undefined) {
    return "Display on city model"
  }
  if (activeProjectionId === datasetId) {
    return "Currently displayed"
  }
  return "Projection in use"
}

export const ProjectionDetail: FC<ProjectionDetailProps> = ({
  dataset,
  categoryTitle,
  categoryId,
  setDatasetIndex,
  handleToggle,
}) => {
  const [
    { isUserAdmitted, activeProjection, projectionLoading },
    dispatch,
  ] = useApp()
  const isActiveProjection =
    activeProjection.id === dataset?.id &&
    activeProjection.categoryId === categoryId
  const classes = useStyles({ isActiveProjection })
  const { active, body, items, shape } = useBuildProjectionLegend({
    query: dataset?.projectionPayload.query,
    visualisation: dataset?.projectionPayload.visualisation,
    visible: isActiveProjection,
  })

  const toggleText = getText(
    activeProjection.id,
    dataset?.id,
    isUserAdmitted === "yes"
  )

  const Icon = useMemo(() => {
    if (
      isUserAdmitted !== "yes" ||
      (activeProjection.id && !isActiveProjection)
    ) {
      return <RemoveRoundedIcon className={classes.iconOverride} />
    }
    if (isActiveProjection) {
      return <TickIcon />
    }
    return <AddRoundedIcon />
  }, [
    activeProjection.id,
    classes.iconOverride,
    isActiveProjection,
    isUserAdmitted,
  ])

  if (!dataset) {
    return <div>Unable to obtain information.</div>
  }

  return (
    <Box className={classes.projectionDetailContainer}>
      <Box className={classes.topContainer}>
        <Button
          onClick={() => setDatasetIndex(undefined)}
          startIcon={<ChevronLeftIcon className={classes.icon} />}
          style={{ fontWeight: "normal" }}
        >
          Back to selection
        </Button>
      </Box>
      <Box className={classes.body}>
        <Typography variant="h4" className={classes.category}>
          {categoryTitle}
        </Typography>
        <Typography variant="h5" className={classes.subcategory}>
          {dataset.title}
        </Typography>
        <Box className={classes.imageContainer}>
          <img
            className={classes.image}
            src={dataset.detailImageUrl}
            alt={dataset.altText}
          />
        </Box>
        <TouchScrollable>
          <Box className={classes.description}>
            {active && (
              <Box mb={1}>
                <Typography variant="h6" className={classes.legendTitle}>
                  Currently projected
                </Typography>
                <Box pb={1} pt={1}>
                  <Legend
                    body={bodyLookup[body as bodyTypes]}
                    items={items as ProjectionLegendItem[]}
                    shape={shapeLookup[shape as shapeTypes]}
                  />
                </Box>
                <Divider classes={{ root: classes.legendDivider }} />
              </Box>
            )}
            <Typography>{dataset.description}</Typography>
          </Box>
        </TouchScrollable>
        <Box className={classes.toggleContainer}>
          <Typography>{toggleText}</Typography>
          <Fab
            onClick={() => handleToggle(dataset.id)}
            className={classes.toggleIconContainer}
            disabled={
              isUserAdmitted !== "yes" ||
              projectionLoading ||
              !!activeProjection.id
            }
          >
            {Icon}
          </Fab>
        </Box>
        <Box className={classes.footer}>
          {isActiveProjection &&
            activeProjection.timeLeft &&
            activeProjection.timeLeft > 0 && (
              <ProjectionProgressBar timeLeft={activeProjection.timeLeft} />
            )}
        </Box>
      </Box>
    </Box>
  )
}
