import React from "react";
import { SnackbarProvider as NotistackSnackbarProvider, } from "notistack";
import { makeStyles } from "@material-ui/core";
import { CheckRounded as SuccessIcon } from "@material-ui/icons";
import { ErrorRounded as ErrorIcon } from "@material-ui/icons";
import { WarningRounded as WarningIcon } from "@material-ui/icons";
import { InfoRounded as InfoIcon } from "@material-ui/icons";
const useSnackbarClasses = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    variantSuccess: {
        borderColor: "transparent",
        color: `${theme.palette.type === "dark"
            ? theme.palette.grey[600]
            : theme.palette.grey[50]}!important`,
        backgroundColor: `${theme.palette.type === "dark"
            ? theme.palette.base.green
            : theme.palette.pop.green} !important`,
    },
    variantError: {
        borderColor: "transparent",
        color: theme.palette.grey[600] + "!important",
        backgroundColor: `${theme.palette.type === "dark"
            ? theme.palette.base.orange
            : theme.palette.pop.orange} !important`,
    },
    variantDefault: {
        backgroundColor: `${theme.palette.type === "dark"
            ? theme.palette.grey[400]
            : theme.palette.grey[900]} !important`,
    },
    variantInfo: {
        borderColor: "transparent",
        backgroundColor: `${theme.palette.type === "dark"
            ? theme.palette.grey[400]
            : theme.palette.grey[900]} !important`,
    },
    variantWarning: {
        borderColor: "transparent",
        color: theme.palette.grey[600] + "!important",
        backgroundColor: `${theme.palette.type === "dark"
            ? theme.palette.base.yellow
            : theme.palette.pop.yellow} !important`,
    },
}));
/**
 * CityDNA themed snackbars. Wraps [notistack](https://github.com/iamhosseindhv/notistack)'s provider and re-exports `useSnackbar` - check for enqueuing API.
 * Depends on `notistack`, `@material-ui/core` and `@material-ui/icons`
 *
 * @example
 * ```typescript
 * // Wrap app in <SnackbarProvider /> then use the useSnackbar() hook.
 * import { useSnackbar } from '@citydna/common';
 * const { enqueueSnackbar } = useSnackbar();
 * ```
 */
export const SnackbarProvider = ({ children, ...props }) => {
    const classes = useSnackbarClasses(undefined);
    const snackbarClasses = {
        variantSuccess: classes.variantSuccess,
        variantError: classes.variantError,
        variantInfo: classes.variantInfo,
        variantWarning: classes.variantWarning,
    };
    const iconVariant = {
        success: React.createElement(SuccessIcon, { className: classes.icon, fontSize: "small" }),
        error: React.createElement(ErrorIcon, { className: classes.icon, fontSize: "small" }),
        info: React.createElement(InfoIcon, { className: classes.icon, fontSize: "small" }),
        warning: React.createElement(WarningIcon, { className: classes.icon, fontSize: "small" }),
    };
    return (React.createElement(NotistackSnackbarProvider, { autoHideDuration: 1500, classes: snackbarClasses, iconVariant: iconVariant, ...props }, children));
};
export * from "./useSnackbarError";
