/**
 *
 * @param config
 * @param properties
 * Replaces ternary logic in assigning the avatarKey as
 * Melbourne History Map now uses a different prop
 */
const avatarAccessor = (config, properties) => {
    if (config.id === "citydna") {
        if (properties.preTitle === "development") {
            return properties.preTitle;
        }
        else {
            return properties.tags[0];
        }
    }
    if (config.id === "state-library-images") {
        return properties.artworkType;
    }
    return properties.preTitle;
};
const DEFAULT_CARD_PROPS = {
    customLayer: undefined,
    address: undefined,
    tags: undefined,
    preTitle: undefined,
    title: undefined,
    dateYear: undefined,
    coverImage: undefined,
    coverImageCaption: undefined,
    backgroundImage: undefined,
    backgroundImageCaption: undefined,
    summary: undefined,
    refId: undefined,
    avatarKey: undefined,
    imageId: undefined,
    zoom: undefined,
    artworkType: undefined,
    artworkMedium: undefined,
};
const appTownHallExperienceAccessor = (properties, metadataRefField) => {
    const defaultProperties = {
        ...DEFAULT_CARD_PROPS,
        customLayer: properties.dna_custom_layer_id,
        address: properties.dna_property_address,
        tags: properties.dna_tags?.split(", "),
        preTitle: properties.dna_category,
        title: properties.dna_title,
        coverImage: properties.dna_image,
        description: properties.dna_description,
        refId: properties[metadataRefField],
        imageId: properties.dna_image_id,
        zoom: properties.dna_zoom,
    };
    /**
     * Try and parse the metadata
     * We use a try catch here because sometimes the metadata is malformed.
     */
    try {
        /** dont throw the error unless it's necessary */
        if (properties.dna_dynamic || properties.dna_children_properties) {
            const meta = JSON.parse(properties.dna_dynamic);
            const metadata = [...(meta ? meta : [])];
            /** Parse the child property metadata */
            const childMetadata = properties.dna_children_properties
                ? JSON.parse(properties.dna_children_properties)
                : undefined;
            return {
                ...defaultProperties,
                metadata,
                childMetadata,
            };
        }
        else {
            return {
                ...defaultProperties,
                metadata: undefined,
                childMetadata: undefined,
            };
        }
    }
    catch (error) {
        console.log(error, "\nmetadata", properties.dna_dynamic, "\nchildren", properties.dna_child_properties);
        return defaultProperties;
    }
};
const appMelbourneHistoricalAccessor = (artwork) => ({
    artistFirstName: artwork.artist_first_name,
    artistLastName: artwork.artist_last_name,
    artistOrganisationName: artwork.artist_organisation_name,
    title: artwork.title,
    dateYear: artwork.year,
    dateYearApproximated: artwork.year_approximated,
    artworkType: artwork.mhm_artwork_type.data.attributes.type,
    artworkMedium: artwork.artwork_medium,
    description: artwork.description,
    image: {
        thumbnail: artwork.image.data?.attributes.formats?.thumbnail?.url ||
            artwork.image.data?.attributes.url ||
            "",
        cover: artwork.image.data?.attributes.formats?.large?.url ||
            artwork.image.data?.attributes.url ||
            "",
        caption: artwork.image.data?.attributes.caption,
    },
    lnglat: [artwork.coordinates.lng, artwork.coordinates.lat],
});
const appAboriginalMelbourneAccessor = (story) => ({
    title: story.title,
    themeId: story.theme_id.data.attributes.theme_id,
    theme: story.theme_id.data.attributes.title,
    startYear: story.start_date,
    image: {
        thumbnail: story.image.data?.attributes.formats?.thumbnail?.url ||
            story.image.data?.attributes.url ||
            "",
        cover: story.image.data?.attributes.formats?.large?.url ||
            story.image.data?.attributes.url ||
            "",
        caption: story.image.data?.attributes.caption,
    },
    description: story.description_short,
    summary: story.summary,
    lnglat: [story.coordinates.lng, story.coordinates.lat],
    primaryArtworkDate: story.primary_artwork_date,
    primaryArtistAttribution: story.primary_artist_attribution,
    primaryArtworkSourceOrganisationName: story.primary_artwork_source_organisation_name,
    primaryArtworkSourceAcquisitionId: story.primary_artwork_source_acquisition_id,
    artworkTitle: story.artwork_title
});
export const appFeaturePropertiesAccessor = {
    avatarAccessor,
    appTownhallExperience: appTownHallExperienceAccessor,
    appMelbourneHistorical: appMelbourneHistoricalAccessor,
    appAboriginalMelbourne: appAboriginalMelbourneAccessor,
};
