import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useConfig } from "@citydna/experience"
import { Button, useSetTheme } from "@citydna/common"
import { actionCreators, UserAdmitted, useApp } from "../common/AppState"
import { Layout } from "../common/Layout"
import { PropertySearch } from "../search/PropertySearch"
import { WaitingRoomDialogue } from "../common/WaitingRoomDialogue"
import { Onboarding } from "../onboarding/Onboarding"
import { Controls } from "../controls/Controls"
import { TourCardPortal } from "../common/TourCard/TourCardPortal"
import { SearchLayerProvider } from "../search/SearchLayerProvider"
import { FeatureExplorer } from "../common/FeatureExplorer"
import { Loading } from "../common/Loading"
import { usePreviousValue } from "../common/usePreviousValue"
import { ProjectionContainer } from "../common/ProjectionContainer"
import { AvatarDialog } from "../common/AvatarDialog/AvatarDialog"
import { ActiveProjectionChip } from "../common/ActiveProjectionChip"
import { AcknowledgmentToCountry } from "../onboarding/AcknowledgmentOfCountry"

export function MapActivity() {
  const [timeoutFinished, setTimeoutFinished] = useState(false)
  const history = useHistory()
  const [{ isUserAdmitted, onboardingOpen }, dispatch] = useApp()
  const previousIsUserAdmitted = usePreviousValue<UserAdmitted>(isUserAdmitted)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const config = useConfig()
  const setTheme = useSetTheme()
  const { t } = useTranslation()

  useEffect(() => {
    if (config?.id === "state-library-images") {
      setTheme("light")
    } else {
      setTheme("dark")
    }
  }, [config, setTheme])

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutFinished(true)
    }, 7500)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    // after timeout and user still hasn't heard a response
    if (timeoutFinished && !isUserAdmitted) {
      enqueueSnackbar(t("common.notifications.errorConnectingToPlatform"), {
        variant: "error",
        autoHideDuration: 5000,
      })
      history.replace("/")
    }
  }, [enqueueSnackbar, history, isUserAdmitted, t, timeoutFinished])

  useEffect(() => {
    if (isUserAdmitted === "no" && !onboardingOpen) {
      dispatch(actionCreators.updateState({ waitingRoomDialogOpen: true }))
    }
    // If a user has been denied access, but later admitted send a notification.
    if (previousIsUserAdmitted === "no" && isUserAdmitted === "yes") {
      enqueueSnackbar(t("welcome.admittedNotification"), {
        variant: "success",
        persist: true,
        action: (key) => (
          <Button
            onClick={() => closeSnackbar(key)}
            size="small"
            variant="text"
            style={{
              color: "black",
            }}
          >
            Dismiss
          </Button>
        ),
      })
    }
  }, [
    isUserAdmitted,
    previousIsUserAdmitted,
    onboardingOpen,
    enqueueSnackbar,
    closeSnackbar,
    dispatch,
    t,
  ])

  if (isUserAdmitted === undefined) {
    return <Loading />
  }

  return (
    <Layout>
      {config?.id === "citydna" && <ProjectionContainer />}
      {config.id === "aboriginal-melbourne" && <AcknowledgmentToCountry />}
      <Onboarding />
      <WaitingRoomDialogue />
      <AvatarDialog />
      <Controls />
      <TourCardPortal />
      <ActiveProjectionChip />
      <SearchLayerProvider>
        <PropertySearch />
        <FeatureExplorer />
      </SearchLayerProvider>
    </Layout>
  )
}
