import React, { FC, useCallback } from "react"
import { Box, makeStyles, Typography } from "@material-ui/core"
import { Avatar } from "@citydna/experience"
import { ConnectionDot } from "../common/ConnectionDot"
import { useTranslation } from "react-i18next"
import { useApp, actionCreators } from "../common/AppState"

const useStyles = makeStyles((theme) => ({
  fab: {
    boxShadow: theme.shadows[6],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "56px",
    height: "56px"
  },
  label: {
    position: "absolute",
    left: 0,
    bottom: -theme.spacing(3),
    width: "100%",
    textAlign: "center",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[100]
        : theme.palette.common.black,
  },
  connectionDot: {
    position: "absolute",
    top: 0,
    right: 12,
    zIndex: 9,
  },
}))

type AvatarButtonProps = {
  /** whether user has been connected or admitted to platform */
  subscribed: boolean
  /** which avatar this user has been assigned */
  icon: string
}

export const AvatarButton: FC<AvatarButtonProps> = ({ subscribed, icon }) => {
  const [{ isUserAdmitted }, dispatch] = useApp()
  const classes = useStyles()
  const { t } = useTranslation<string>()

  const openDialog = useCallback(() => {
    if (isUserAdmitted === "yes") {
      dispatch(actionCreators.updateState({ avatarDialogOpen: true }))
    } else {
      dispatch(actionCreators.updateState({ waitingRoomDialogOpen: true }))
    }
  }, [dispatch, isUserAdmitted])

  return (
    <Box position="relative">
      <ConnectionDot
        connected={subscribed && isUserAdmitted === "yes"}
        className={classes.connectionDot}
      />
      <Avatar
        className={classes.fab}
        onClick={openDialog}
        title={t("controls.avatar.controlButtonTitle", {
          avatarIcon: icon || "",
        })}
      />
      <Typography variant="caption" className={classes.label}>
        {t("controls.avatar.controlButtonLabel")}
      </Typography>
    </Box>
  )
}
