import { makeStyles } from "@material-ui/core";
export const useSnackbarClasses = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(1),
        fontSize: 24,
    },
    variantSuccess: {
        backgroundColor: "#00ED8C !important",
    },
    variantError: {
        backgroundColor: `${theme.palette.error.main} !important`,
    },
}));
