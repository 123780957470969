import React from "react";
import { Box, useTheme } from "@material-ui/core";
/**
 * City of Melbourne's micro logo. Not CMCA Approved. Props spread onto an `svg` element.
 * Colour will invert based on which theme is active through the <Theme /> provider.
 */
export const MicroLogo = ({ variant, ...props }) => {
    const theme = useTheme();
    const color = theme.palette.type === "dark" || variant === "light"
        ? theme.palette.common.white
        : theme.palette.grey[800];
    return (React.createElement(Box, { component: "svg", width: 32, height: 32, 
        // @ts-ignore
        viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props },
        React.createElement("title", null, "City of Melbourne M Logo"),
        React.createElement("path", { d: "M15.9983 21.7494L10.6656 18.6254V24.8793L5.33262 28L-7.12e-07 24.8733V6.12673L5.32998 3L16.0015 9.24789L26.6674 3.0033L32 6.12116V24.8762L26.6674 28L21.3315 24.8733V18.6254L15.9987 21.7494H15.9983Z", fill: color })));
};
export default MicroLogo;
