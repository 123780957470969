import React, { useState, useContext, useMemo } from "react";
import { ThemeProvider, CssBaseline, makeStyles } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { base } from "./values/base";
import { touchSizing } from "./values/touchSizing";
import { dark } from "./values/dark";
import { light } from "./values/light";
const useStyles = makeStyles({
    global: {
        "@global": {
            body: {
                height: "100%",
            },
            html: {
                height: "100%",
            },
            "#root": {
                height: "100%",
            },
        },
    },
});
const ThemeVariantContext = React.createContext(undefined);
/**
 * A theme provider for CityDNA. Ensure you wrap your app or applet at the top level with this component.
 */
const RawTheme = ({ variant: defaultVariant = "light", usePreferredTheme = false, baseline = "global", touch = false, primaryColor, children, includeXxl = false, }) => {
    const [variant, setVariant] = useState(() => {
        if (!usePreferredTheme)
            return defaultVariant;
        /** finally check whether dark mode is requested by the user */
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        if (prefersDark)
            return "dark";
        /** otherwise just return default variant specified as a prop */
        return defaultVariant;
    });
    const theme = useMemo(() => {
        const lightColors = primaryColor
            ? {
                palette: {
                    primary: { main: base.palette.pop[primaryColor] || primaryColor },
                },
            }
            : {};
        const darkColors = primaryColor
            ? {
                palette: {
                    primary: { main: base.palette.base[primaryColor] || primaryColor },
                },
            }
            : {};
        const themeMap = {
            light: {
                default: !touch && createTheme(base, light, lightColors),
                touch: touch && createTheme(base, light, lightColors, touchSizing),
            },
            dark: {
                default: !touch && createTheme(base, dark, darkColors),
                touch: touch && createTheme(base, dark, darkColors, touchSizing),
            },
        };
        const theme = themeMap[variant][touch ? "touch" : "default"];
        // add scaling if theme provided supports 4k
        if ("xxl" in theme.breakpoints.values && includeXxl) {
            theme.typography.h3 = {
                ...theme.typography.h3,
                [theme.breakpoints.up("xxl")]: {
                    fontSize: 32,
                },
            };
            theme.typography.h6 = {
                ...theme.typography.h6,
                [theme.breakpoints.up("xxl")]: {
                    fontSize: 24,
                },
            };
            theme.typography.body2 = {
                ...theme.typography.body2,
                [theme.breakpoints.up("xxl")]: {
                    fontSize: 24,
                },
            };
            theme.typography.caption = {
                ...theme.typography.caption,
                [theme.breakpoints.up("xxl")]: {
                    fontSize: 20,
                },
            };
        }
        return theme;
    }, [primaryColor, touch, variant, includeXxl]);
    const classes = useStyles();
    return (React.createElement(ThemeProvider, { theme: theme },
        React.createElement("div", { className: classes.global }),
        React.createElement(ThemeVariantContext.Provider, { value: setVariant },
            React.createElement(React.Fragment, null,
                baseline === "global" && React.createElement(CssBaseline, null),
                baseline === "scoped" && React.createElement(ScopedCssBaseline, null),
                children))));
};
export const Theme = RawTheme;
export const useSetTheme = () => {
    return useContext(ThemeVariantContext);
};
