import React, { useEffect, useMemo } from "react";
import { useMediaQuery } from "@material-ui/core";
import { useGoToMelbourne, useMap } from "@citydna/maps";
import { useCardStack, useIsIdle } from "../hooks";
import { ActiveFeature } from "./ActiveFeature";
import { CardStackManager } from "./CardStackManager";
import { MapIdleScreen } from "../MapExperiences/MapIdleScreen";
import { TownHallMapLayout } from "./TownHallMapLayout";
const vhToPixel = (value) => (window.innerHeight * value) / 100;
export const CardStack = React.memo(({ config, idleTimeout }) => {
    const goToMelbourne = useGoToMelbourne();
    const map = useMap();
    const displaySize = useMediaQuery((theme) => theme.breakpoints.up("xxl"))
        ? "xxl"
        : "xl";
    /** Grab our stack of all cards */
    const { cards, timeLeft } = useCardStack({
        loadFullCardArgs: (card) => ({
            where: "",
            polygons: false,
        }),
        skipAgolCall: true,
    });
    const cardsInUse = useMemo(() => {
        return cards.slice(0, 4).map((card, index) => ({ ...card, index }));
    }, [cards]);
    const activeCard = cardsInUse && cardsInUse[0];
    const timeout = useMemo(() => {
        const timeLeftMs = timeLeft * 1000;
        return cards.length
            ? cards.length === 1
                ? timeLeftMs
                : timeLeftMs + cards.length * 25000
            : idleTimeout;
    }, [cards.length, idleTimeout, timeLeft]);
    const isIdle = useIsIdle(timeout);
    /* reset map to center */
    useEffect(() => {
        if (!activeCard && !isIdle && map) {
            goToMelbourne();
        }
    }, [activeCard, isIdle, goToMelbourne, map]);
    return (React.createElement(TownHallMapLayout, { windowPadding: config.windowPadding[displaySize] },
        React.createElement(CardStackManager, { timeLeft: timeLeft, visible: config.queuedCardVisibility[displaySize], cards: cardsInUse, activeCard: {
                // subtract top and bottom padding
                height: vhToPixel(config.activeCard[displaySize].height) -
                    config.windowPadding[displaySize] * 2,
                width: config.activeCard[displaySize].width,
            }, queuedCard: {
                width: config.queuedCard[displaySize].width,
                height: config.queuedCard[displaySize].height,
            }, activeCardComponent: config.activeCardComponent, queuedCardHeightReduction: displaySize === "xxl" ? 100 : 50 }),
        isIdle ? (React.createElement(MapIdleScreen, null)) : (React.createElement(ActiveFeature, { activeCard: activeCard, activeCardWidth: config.activeCard[displaySize].width, windowPadding: config.windowPadding[displaySize], zoomFactor: config.zoomFactor }))));
});
