import React, { useEffect, useState } from "react";
import QRCode from "qrcode.react";
import { API, graphqlOperation } from "aws-amplify";
import { Box, Link } from "@material-ui/core";
/**
 * IMPORTANT: the attribute(s) you want to return in the subscription
 * MUST MATCH what is specified in the mutation you're subscribing to
 */
const onDeleteToken = /* GraphQL */ `
  subscription OnDeleteToken {
    onDeleteToken {
      id
    }
  }
`;
const LOCAL_CHANNEL_NAME = "citydna-app-preview";
export const AuthQRCode = ({ publicAppUrl, experience, channel, loading, qrProps = {}, width, height, ...props }) => {
    const [token, setToken] = useState();
    useEffect(() => {
        if (!token) {
            API.get("api466165c9", "/generatetoken", {}).then((res) => setToken(res.data.createToken));
        }
    }, [token]);
    /** On token changes, subscribe to the graphql API for deleted tokens. */
    useEffect(() => {
        if (token) {
            const subscription = API.graphql(graphqlOperation(onDeleteToken)).subscribe({
                next: ({ value }) => {
                    /** If the token this QR code uses is deleted, request a new one by setting a new timestamp */
                    if (token.id === value.data.onDeleteToken.id) {
                        setToken(undefined);
                    }
                },
            });
            return () => {
                subscription.unsubscribe();
            };
        }
    }, [token]);
    const link = token &&
        `${publicAppUrl}/e?j=${token.token}&t=${token.id}&c=${channel || LOCAL_CHANNEL_NAME}&e=${experience || "citydna"}`;
    return link ? (React.createElement(Box, { component: Link, 
        // @ts-ignore
        href: link, target: "_blank", width: width, height: height, style: {
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }, ...props },
        React.createElement(QRCode, { renderAs: "svg", value: link, size: width - 8, ...qrProps }))) : (React.createElement(Box, { width: width, height: height }, loading));
};
