import React from "react";
import { Box, Typography, makeStyles, useTheme } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    gradient: ({ keysOrIntervals, inline }) => ({
        background: `linear-gradient(90deg, ${[
            `${keysOrIntervals[0].color.hex} 0%`,
            ...keysOrIntervals
                .slice(1, keysOrIntervals.length - 1)
                .map((item, index, array) => `${item.color.hex} ${Math.floor((100 / (array.length + 1)) * (index + 1))}%`),
            `${keysOrIntervals[keysOrIntervals.length - 1].color.hex} 100%`,
        ].join(",")})`,
        height: theme.spacing(2),
        width: inline ? theme.spacing(16) : "100%",
        borderRadius: theme.shape.borderRadius,
    }),
    root: ({ inline }) => ({
        flexWrap: inline ? "nowrap" : "wrap",
    }),
    firstLabel: ({ inline }) => ({
        order: inline ? 1 : 2,
        marginRight: inline ? theme.spacing(1) : 0,
    }),
    lastLabel: ({ inline }) => ({
        order: 3,
        marginLeft: inline ? theme.spacing(1) : "auto",
    }),
}));
/**
 * An scale map legend - creates a gradient between intervals.
 * Extends extends the [`@material-ui/core/Box` component](https://material-ui.com/components/box/).
 */
export const LegendScale = ({ keysOrIntervals, styles, inline, ...props }) => {
    const rootStyles = styles && styles.root ? styles.root : {};
    const colorStyles = styles && styles.color ? styles.color : {};
    const labelStyles = styles && styles.label ? styles.label : {};
    const classes = useStyles({ keysOrIntervals, inline });
    const firstLabel = keysOrIntervals[0].label;
    const lastLabel = keysOrIntervals[keysOrIntervals.length - 1].label;
    const theme = useTheme();
    return (React.createElement(Box, { ...props, display: "flex", flexWrap: "wrap", alignItems: "center", style: rootStyles },
        React.createElement(Typography, { variant: "button", style: labelStyles, className: classes.firstLabel }, firstLabel),
        React.createElement(Box, { className: classes.gradient, style: colorStyles, order: 1, boxShadow: [theme.shadows[2], theme.shadows[1]].join(",") }),
        React.createElement(Typography, { variant: "button", style: labelStyles, className: classes.lastLabel }, lastLabel)));
};
