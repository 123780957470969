import React from "react";
import { useTheme } from "@material-ui/core";
/**
 * City of Melbourne's official logo. Props spread onto an `svg` element.
 * Colour will invert based on which theme is active through the <Theme /> provider.
 */
export const Logo = props => {
    const theme = useTheme();
    const color = theme.palette.type === "dark"
        ? theme.palette.common.white
        : theme.palette.grey[800];
    return (React.createElement("svg", { version: "1.1", id: "Layer_1", x: "0px", y: "0px", viewBox: "0 0 444.6 126.5", ...props },
        React.createElement("title", null, "City of Melbourne Full Logo"),
        React.createElement("g", null,
            React.createElement("g", null,
                React.createElement("polygon", { fill: color, points: "81.4,93.6 107.6,78.4 107.6,108.7 133.8,123.9 160.1,108.7 160.1,17.8 133.8,2.7 81.3,32.9 \n\t\t\t28.9,2.7 2.6,17.8 2.6,108.7 28.9,123.9 55.1,108.7 55.1,78.4 81.4,93.6 \t\t" })),
            React.createElement("g", null,
                React.createElement("g", null,
                    React.createElement("path", { fill: color, d: "M215.5,68.1h-7.5c-0.3,0-0.7,0.2-0.7,0.6l-0.9,5.9l-4.8,13.3l-4.8-13.3l-0.9-5.9c0-0.3-0.3-0.6-0.7-0.6\n\t\t\t\th-7.5c-0.3,0-0.6,0.3-0.6,0.6v40.8c0,0.3,0.3,0.6,0.6,0.6h5.8c0.3,0,0.6-0.3,0.6-0.6V86.9c1.9,5.3,3.9,10.7,4.3,11.8\n\t\t\t\tc0.1,0.4,0.5,0.6,0.6,0.6h5.2c0.2,0,0.5-0.2,0.6-0.6c0.4-1.1,2.4-6.4,4.3-11.8v22.7c0,0.3,0.3,0.6,0.6,0.6h5.8\n\t\t\t\tc0.3,0,0.6-0.3,0.6-0.6V68.8C216.1,68.4,215.8,68.1,215.5,68.1z" }),
                    React.createElement("path", { fill: color, d: "M244.1,68.1h-19.9c-0.3,0-0.6,0.3-0.6,0.6v40.8c0,0.3,0.3,0.6,0.6,0.6h19.9c0.3,0,0.6-0.3,0.6-0.6v-5.8\n\t\t\t\tc0-0.3-0.3-0.6-0.6-0.6h-13.4v-12h10.2c0.3,0,0.6-0.3,0.6-0.6v-5.8c0-0.3-0.3-0.6-0.6-0.6h-10.2v-8.8h13.4c0.3,0,0.6-0.3,0.6-0.6\n\t\t\t\tv-5.8C244.7,68.4,244.4,68.1,244.1,68.1z" }),
                    React.createElement("path", { fill: color, d: "M269,103.1h-11.7V68.8c0-0.3-0.3-0.6-0.6-0.6h-5.8c-0.3,0-0.6,0.3-0.6,0.6v40.8c0,0.3,0.3,0.6,0.6,0.6\n\t\t\t\tH269c0.3,0,0.6-0.3,0.6-0.6v-5.8C269.6,103.4,269.3,103.1,269,103.1z" }),
                    React.createElement("path", { fill: color, d: "M353.1,68.1h-5.8c-0.3,0-0.6,0.3-0.6,0.6v32.5c0,1.4-1.2,1.9-2.5,1.9h-2c-1.3,0-2.5-0.5-2.5-1.9V68.8\n\t\t\t\tc0-0.3-0.3-0.6-0.6-0.6h-5.8c-0.3,0-0.6,0.3-0.6,0.6v34.4c0,3.9,3.1,7,7,7h7.1c3.9,0,7-3.1,7-7V68.8\n\t\t\t\tC353.8,68.4,353.4,68.1,353.1,68.1z" }),
                    React.createElement("path", { fill: color, d: "M412.2,68.1h-5.8c-0.1,0-0.3,0.1-0.5,0.3v0l0,0c0,0-0.1,0.1-0.1,0.2l0,0v16.2l-5.5-10.2l-0.9-5.9\n\t\t\t\tc0-0.3-0.3-0.6-0.6-0.6h-7.5c-0.3,0-0.6,0.3-0.6,0.6v40.8c0,0.3,0.3,0.6,0.6,0.6h5.8c0.3,0,0.6-0.3,0.6-0.6V83.4\n\t\t\t\tc2.9,6.3,7.6,13.7,8.2,15.2v11c0,0.3,0.3,0.6,0.6,0.6h5.8c0.3,0,0.6-0.3,0.6-0.6V68.8C412.8,68.4,412.5,68.1,412.2,68.1z" }),
                    React.createElement("path", { fill: color, d: "M440.7,75.2c0.3,0,0.6-0.3,0.6-0.6v-5.8c0-0.3-0.3-0.6-0.6-0.6h-19.9c-0.3,0-0.6,0.3-0.6,0.6v40.8\n\t\t\t\tc0,0.3,0.3,0.6,0.6,0.6h19.9c0.3,0,0.6-0.3,0.6-0.6v-5.8c0-0.3-0.3-0.6-0.6-0.6h-13.4v-12h10.2c0.3,0,0.6-0.3,0.6-0.6v-5.8\n\t\t\t\tc0-0.3-0.3-0.6-0.6-0.6h-10.2v-8.8H440.7z" }),
                    React.createElement("path", { fill: color, d: "M384.8,109.3l-5.6-13.8l0.5-0.4c2.1-1.4,3.3-4.3,3.3-7v-9.5c0-5.9-4.7-10.5-10.6-10.5h-10.7\n\t\t\t\tc-0.3,0-0.6,0.3-0.6,0.6v40.8c0,0.3,0.3,0.6,0.6,0.6h5.9c0.3,0,0.6-0.3,0.6-0.6V98.7h4.1l4.5,11.1c0.1,0.3,0.4,0.4,0.6,0.4h6.7\n\t\t\t\tC384.8,110.2,385,109.8,384.8,109.3z M375.9,88.1c0,1.9-1.5,3.6-3.4,3.6h-4.3V75.2h4.2c1.9,0,3.4,1.5,3.4,3.4V88.1z" }),
                    React.createElement("path", { fill: color, d: "M318.4,68.1h-7.1c-3.8,0-7,3.2-7,7v28.1c0,3.9,3.1,7,7,7h7.1c3.9,0,7-3.1,7-7V75.1\n\t\t\t\tC325.4,71.4,322.2,68.1,318.4,68.1z M318.3,101.2c0,1.4-1.2,1.9-2.5,1.9h-2c-1.3,0-2.5-0.5-2.5-1.9V77.1c0-1.5,0.5-1.9,2-1.9h3\n\t\t\t\tc1.5,0,2,0.4,2,1.9V101.2z" }),
                    React.createElement("path", { fill: color, d: "M296.9,80.2V78c0-5.9-4.7-9.9-10.6-9.9h-10.7c-0.3,0-0.7,0.3-0.7,0.6v40.8c0,0.3,0.3,0.6,0.7,0.6h10.7\n\t\t\t\tc5.9,0,10.6-4.6,10.6-10.5v-5.1c0-2.5-1-5.1-3.4-7C296.1,85.8,296.9,82.7,296.9,80.2z M289.6,99.7c0,1.9-1.5,3.4-3.4,3.4H282v-12\n\t\t\t\th4.3c1.8,0,3.4,1.7,3.4,3.6V99.7z M289.6,80.8c0,1.8-1.6,3.2-3.4,3.2H282v-8.8h4.2c1.9,0,3.4,1.7,3.4,3.6V80.8z" })),
                React.createElement("g", null,
                    React.createElement("path", { fill: color, d: "M207.5,19H194c-3.7,0-7,3.1-7,7V54c0,4.5,3.7,7,7,7h13.5c0.3,0,0.6-0.3,0.6-0.6v-5.8\n\t\t\t\tc0-0.3-0.3-0.6-0.6-0.6h-10.9c-1.5,0-2.5-0.6-2.5-1.9V27.9c0-1.4,0.4-1.9,2-1.9h11.4c0.3,0,0.6-0.3,0.6-0.6v-5.8\n\t\t\t\tC208.1,19.3,207.8,19,207.5,19z" }),
                    React.createElement("path", { fill: color, d: "M333.6,19h-19.8c-0.3,0-0.6,0.3-0.6,0.6v40.8c0,0.3,0.3,0.6,0.6,0.6h5.8c0.3,0,0.6-0.3,0.6-0.6l0-18.5\n\t\t\t\th10.2c0.3,0,0.6-0.3,0.6-0.6v-5.8c0-0.3-0.3-0.6-0.6-0.6h-10.2V26h13.4c0.3,0,0.6-0.3,0.6-0.6v-5.8C334.2,19.3,333.9,19,333.6,19\n\t\t\t\tz" }),
                    React.createElement("path", { fill: color, d: "M300.3,19h-7.1c-3.8,0-7,3.2-7,7V54c0,3.9,3.1,7,7,7h7.1c3.9,0,7-3.1,7-7V25.9\n\t\t\t\tC307.3,22.2,304.1,19,300.3,19z M300.2,52.1c0,1.4-1.2,1.9-2.5,1.9h-2c-1.3,0-2.5-0.5-2.5-1.9V27.9c0-1.5,0.5-1.9,2-1.9h3\n\t\t\t\tc1.5,0,2,0.4,2,1.9V52.1z" }),
                    React.createElement("path", { fill: color, d: "M269.5,19h-6.4c-0.3,0-0.6,0.3-0.6,0.6l-0.7,6l-3.3,13.3l-3.3-13.3l-0.8-6c0-0.3-0.3-0.6-0.7-0.6h-6.4\n\t\t\t\tc-0.4,0-0.6,0.3-0.6,0.7c0.4,3.4,0.7,6.2,1.8,10.3c1.8,6.4,5.8,18.6,6.4,20.2v10.2c0,0.3,0.3,0.6,0.6,0.6h5.8\n\t\t\t\tc0.3,0,0.6-0.3,0.6-0.6V50.2c0.6-1.7,4.7-13.5,6.3-20.1c1.2-4.8,1.3-6.1,1.9-10.4C270.2,19.3,269.9,19,269.5,19z" }),
                    React.createElement("path", { fill: color, d: "M242.6,19h-18.1c-0.3,0-0.6,0.3-0.6,0.6v5.8c0,0.3,0.3,0.6,0.6,0.6h5.6v34.3c0,0.3,0.3,0.6,0.6,0.6h5.8\n\t\t\t\tc0.3,0,0.6-0.3,0.6-0.6V26h5.5c0.3,0,0.6-0.3,0.6-0.6v-5.8C243.2,19.3,242.9,19,242.6,19z" }),
                    React.createElement("path", { fill: color, d: "M219.4,19h-5.8c-0.3,0-0.6,0.3-0.6,0.6V54h0v6.4c0,0.3,0.3,0.6,0.6,0.6h5.8c0.3,0,0.6-0.3,0.6-0.6V19.6\n\t\t\t\tC220,19.3,219.7,19,219.4,19z" }))))));
};
export default Logo;
