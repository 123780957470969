import React, {
  createContext,
  useContext,
  FunctionComponent,
  useState,
} from "react"

type SearchLayerContextValue = [
  any | undefined,
  React.Dispatch<any | undefined>
]

const SearchLayerContext = createContext<SearchLayerContextValue | undefined>(
  undefined
)
/**
 * Context to allow base property feature to be set from across the app
 * @param param0
 */
export const SearchLayerProvider: FunctionComponent = ({ children }) => {
  const [parentFeatureGeojson, setParentFeatureGeojson] = useState<
    any | undefined
  >(undefined)
  return (
    <SearchLayerContext.Provider
      value={[parentFeatureGeojson, setParentFeatureGeojson]}
    >
      {children}
    </SearchLayerContext.Provider>
  )
}

export default SearchLayerProvider

export const useSearchLayer = () => {
  const context = useContext(SearchLayerContext)
  if (!context) throw Error("Not inside <SearchLayerProvider />")
  return context
}
