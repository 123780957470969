import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import { useImage } from "../hooks";
/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles(theme => ({
    blurhash: {
        backgroundImage: ({ blurhashUri }) => `url(${blurhashUri})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    image: {
        backgroundImage: ({ imageUrl }) => `url(${imageUrl})`,
        backgroundSize: "cover",
        opacity: ({ imageUrl }) => (imageUrl ? 1 : 0),
        transition: theme.transitions.create("opacity"),
        backgroundPosition: "center",
    },
}));
export const BlurhashImage = ({ blurhash, imageUrl, caption = "image", ...props }) => {
    /** Get our image URIs */
    const blurhashUri = useImage(blurhash);
    /** Get scoped CSS classes */
    const classes = useStyles({ imageUrl, blurhashUri });
    return (React.createElement(Box, { position: "relative", overflow: "hidden", ...props },
        blurhash && (React.createElement(Box, { className: classes.blurhash, position: "absolute", zIndex: 1, top: 0, right: 0, bottom: 0, left: 0 })),
        imageUrl && (React.createElement(Box, { className: classes.image, position: "absolute", zIndex: 2, top: 0, right: 0, bottom: 0, left: 0 }))));
};
