import qs from "query-string"
import { AgolRestAPIParams } from "./types"

export const createAgolUrl = ({
  where,
  f,
  outFields,
  returnDistinctValues,
  returnGeometry,
  outSr,
}: AgolRestAPIParams) => {
  return (
    "/query?" +
    qs.stringify({
      where: where || "1=1",
      f,
      outFields: outFields || ["*"],
      outSr: outSr || 4326,
      returnDistinctValues,
      returnGeometry,
    })
  )
}

export const weaveAuthorization = {
  Authorization: `Basic ${window.btoa(
    process.env.REACT_APP_WEAVE_BEARER_TOKEN as string
  )}`
}
