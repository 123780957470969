import React, { FC, useState } from "react"
import { makeStyles, Theme, Portal } from "@material-ui/core"
import { useCurrentRoom } from "@citydna/platform"
import { PORTAL_OUTLET_ID } from "../constants"
import { Popup } from "../../map/Popup"
import { TourCardWrapper } from "./TourCardWrapper"
import { useTourCard } from "./TourCardProvider"
import { ExpandImageViewer } from "./ExpandImageViewer"

const useStyles = makeStyles<Theme>({
  popup: {
    position: "fixed",
    top: 0,
    left: 0,
    transformOrigin: "left top",
    zIndex: 1051,
  },
})

export const TourCardPortal: FC = () => {
  const { me } = useCurrentRoom()
  const { tourCard, onTourCardRest, onCloseTourCard, onAddTourCard, loading } =
    useTourCard()

  const classes = useStyles()
  const [expand, setExpand] = useState(false)
  const [animate, setAnimate] = useState(true)

  const handleSetExpand = () => {
    setExpand(!expand)
    setAnimate(true)
  }

  const handleReturnFromExpand = () => {
    setAnimate(false)
  }

  return tourCard.properties && tourCard.origin ? (
    <Portal container={document.getElementById(PORTAL_OUTLET_ID)}>
        <ExpandImageViewer
          open={expand}
          handleClose={handleSetExpand}
          imageUrl={tourCard.properties.coverImage}
          imageTag={tourCard.properties.coverImageCaption}
        />
        {!expand && (
          <Popup
            // @ts-ignore
            returnFromExpand={animate}
            setReturnFromExpand={handleReturnFromExpand}
            isAdding={tourCard.added}
            className={classes.popup}
            origin={tourCard.origin}
            visible={tourCard.open}
            height={320}
            onRest={onTourCardRest}
            onAdd={onAddTourCard}
            onClose={onCloseTourCard}
          >
            <TourCardWrapper
              {...tourCard.properties}
              onClose={onCloseTourCard}
              onAdd={onAddTourCard}
              avatar={me}
              loading={loading}
              handleSetExpandImage={handleSetExpand}
              expandImage={expand}
            />
          </Popup>
        )}
      </Portal>
  ) : null
}

export default TourCardPortal
