import { useEffect } from "react";
import { setDefaultOptions, loadModules } from "esri-loader";
import usePromise from "react-use-promise";
setDefaultOptions({ css: true });
/** Load esri modules via a hook */
export const useEsriModules = (esriModules) => {
    /** Load the modules into state */
    const [modules, error] = usePromise(loadModules(esriModules), esriModules);
    /** Warn if there's an error */
    const moduleNames = esriModules.join(", ");
    useEffect(() => {
        if (error) {
            console.warn("Error loading modules: " +
                moduleNames +
                ". You may have spelt the module accessor wrong.", error);
        }
    }, [error, moduleNames]);
    return modules || [];
};
