import { useFeatureLayers } from "./useFeatureLayers";
/** Loads the card's remaining metadata from the AGOL */
export const useLoadFullCardFromAgol = (config, spatialReference, 
/** if card has full information skip this call */
skip = false) => {
    if (skip) {
        return;
    }
    /** Grab the feature layers */
    const { metadataLayer, footprintsLayer } = useFeatureLayers(config);
    const fullCardCallback = async ({ where, polygons = true, }) => {
        if (!metadataLayer) {
            throw new Error("metadataLayer did not load before calling this");
        }
        /** First grab the metadata */
        const featureSet = await metadataLayer.queryFeatures({
            outFields: ["*"],
            outSpatialReference: spatialReference,
            returnGeometry: true,
            where,
        });
        const native = featureSet.features[0];
        let actualFeatureSet;
        // if it's another spatial reference than 4236 then we have to grab the
        // web mercator lnglat for things like google street view
        if (spatialReference.wkid !== 4326) {
            const newSomething = await metadataLayer.queryFeatures({
                outSpatialReference: { wkid: 4326 },
                where,
                returnGeometry: true,
            });
            actualFeatureSet = [native, getLatLng(newSomething.features[0])];
        }
        else {
            actualFeatureSet = [native, getLatLng(native)];
        }
        const [property, lnglat] = actualFeatureSet;
        /** polygons is set to true by default as we are using them for zoom functionality */
        if (polygons && footprintsLayer) {
            /** If it is, we want to grab the structures then  */
            const current = await footprintsLayer.queryFeatures({
                outFields: ["*"],
                outSpatialReference: spatialReference,
                returnGeometry: true,
                returnZ: true,
                where,
            });
            return {
                point: property,
                polygons: current.features,
                lnglat,
            };
        }
        /** Else just resolve the point. */
        return {
            point: property,
            lnglat,
        };
    };
    return fullCardCallback;
};
/** Gets a latlng from an esri graphic */
const getLatLng = (graphic) => {
    const { 
    // @ts-ignore
    longitude, 
    // @ts-ignore
    latitude, 
    // @ts-ignore
    x, 
    // @ts-ignore
    y, } = graphic.geometry;
    return [longitude || x, latitude || y];
};
