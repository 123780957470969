import React, {
  createContext,
  useContext,
  Dispatch,
  useReducer,
  useCallback,
  useState,
  useEffect,
} from "react"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { useCurrentRoom } from "@citydna/platform"
import {
  AddStopPayload,
  REQUEST_ADD_STOP,
  useConfig,
  useFeaturePropertiesAccessor,
} from "@citydna/experience"
import qs from "qs"
import { v4 as uuid } from "uuid"
import { useApp } from "../AppState"
import { appFeaturePropertiesAccessor } from "@citydna/experience"

/** Type return values */
interface TourCardContextValue {
  /** State that the tour card is in */
  tourCard: TourCardState
  /** State dispatcher */
  dispatchTourCard: Dispatch<Partial<TourCardState>>
  /** Function to handle opening the tour card */
  onOpenTourCard: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    feature: Record<string, any>,
    xy: { x: number; y: number }
  ) => void
  /** Function to call when the user requests adding the tour card */
  onAddTourCard: () => void
  /** Function to call when the user requests closing the tour card */
  onCloseTourCard: () => void
  /** Function to call when the adding animation has rested */
  onTourCardRest: () => void
  /** Whether the provider is loading additional data for the card */
  loading: boolean
}

type TourCardState = {
  open: boolean
  added: boolean
  origin?: { x: number; y: number } | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: Record<string, any> | undefined
}

/** Generate the context */
const TourCardContext = createContext<TourCardContextValue | undefined>(
  undefined
)

/** Provider to wrap your app with */
export const TourCardProvider: React.FC = ({ children }) => {
  const { avatarAccessor } = appFeaturePropertiesAccessor
  /** Store tour card properties state */
  const [tourCard, dispatchTourCard] = useReducer(
    (state: TourCardState, action: Partial<TourCardState>) => ({
      ...state,
      ...action,
    }),
    {
      open: false,
      added: false,
      origin: undefined,
      properties: undefined,
    }
  )
  const [{ isUserAdmitted, projectionOpen, referencesOpen }] = useApp()
  const accessor = useFeaturePropertiesAccessor()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation<string>()
  const config = useConfig()
  const [loading, setLoading] = useState(false)

  /** This is called with the properties of just the polygons - namely not much () */
  const onOpenTourCard = useCallback(
    (properties: Record<string, any>, xy: { x: number; y: number }) => {
      dispatchTourCard({
        open: true,
        origin: xy,
        properties: {},
      })

      // TODO: Add any other Strapi applications, send entire data source across
      if (config.id === "aboriginal-melbourne") {
        const accessProperties = accessor(properties)
        /** Map properties to tour card shape */
        dispatchTourCard({
          open: true,
          origin: xy,
          properties: {
            coverImage: accessProperties.image.cover,
            avatarKey: accessProperties.themeId,
            loading: false,
            preTitle: accessProperties.theme,
            themeId: accessProperties.themeId,
            coverImageCaption: accessProperties.image.caption,
            description: accessProperties.description,
            summary: accessProperties.summary,
            title: accessProperties.title,
            lnglat: accessProperties.lnglat,
            primaryArtworkDate: accessProperties.primaryArtworkDate,
            primaryArtistAttribution: accessProperties.primaryArtistAttribution,
            primaryArtworkSourceOrganisationName:
              accessProperties.primaryArtworkSourceOrganisationName,
            primaryArtworkSourceAcquisitionId:
              accessProperties.primaryArtworkSourceAcquisitionId,
            itemId: properties.id,
            artworkTitle: accessProperties.artworkTitle
          },
        })
        return
      }
      if (config.id === "state-library-images") {
        const accessProperties = accessor(properties)
        dispatchTourCard({
          open: true,
          origin: xy,
          properties: {
            loading: false,
            artworkType: accessProperties.artworkType,
            coverImage: accessProperties.image.cover,
            imageCaption: accessProperties.image.caption,
            artistFirstName: accessProperties.artistFirstName,
            artistLastName: accessProperties.artistLastName,
            artistOrganisationName: accessProperties.artistOrganisationName,
            dateYear: accessProperties.dateYear,
            title: accessProperties.title,
            dateYearApproximated: accessProperties.dateYearApproximated,
            artworkMedium: accessProperties.artworkMedium,
            description: accessProperties.description,
            lnglat: accessProperties.lnglat,
            itemId: properties.id,
            avatarKey: accessProperties.artworkType
          }
        })
        return
      }

      /** Load the remaining data if the property only shows a structure_id */
      /** Need to show error if there's no structure id and it's a polygon dataset */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (config && properties[config?.metadataRefField as any]) {
        setLoading(true)
        fetch(
          config.metadataFeatureService +
            "/query?" +
            qs.stringify({
              where: `${config?.metadataRefField}='${
                properties[config?.metadataRefField as any]
              }'`,
              f: "geojson",
              outFields: "*",
            })
        )
          .then((res) => res.json())
          .then((featureSet) => {
            const fullCardProperties = accessor(
              featureSet.features[0].properties,
              config?.metadataRefField
            )
            dispatchTourCard({
              open: true,
              origin: xy,
              properties: {
                ...fullCardProperties,
                loading: false,
                coverImage:
                  config.id === "citydna"
                    ? `https://citydna-images.s3.ap-southeast-2.amazonaws.com/citydna-tourcard-images/${fullCardProperties.imageId}.jpg`
                    : fullCardProperties.coverImage || fullCardProperties.image,
                avatarKey: avatarAccessor(config, fullCardProperties),
              },
            })
          })
          .catch(() => {
            enqueueSnackbar(
              t("map.notifications.errorLoadingProperty", {
                id: properties[config?.metadataRefField as string],
              }),
              {
                variant: "error",
              }
            )
          })
          .finally(() => setLoading(false))
      }
    },
    [accessor, avatarAccessor, config, enqueueSnackbar, t]
  )

  /** Handle adding a tour card to the tour */
  const { emit, myID, me } = useCurrentRoom()
  const onAddTourCard = () => {
    if (tourCard.properties) {

      if (isUserAdmitted === "no") {
        enqueueSnackbar(t("map.notifications.errorGeneric"), {
          variant: "error",
        })
        return
      }
      const {
        itemId,
        title,
        preTitle,
        description,
        blurhash,
        coverImage,
        coverImageCaption,
        themeId,
        lnglat,
        artworkType,
        artworkMedium,
        dateYear,
        dateYearApproximated,
        artistFirstName,
        artistLastName,
        artistOrganisationName,
        primaryArtworkDate,
        primaryArtistAttribution,
        primaryArtworkSourceOrganisationName,
        primaryArtworkSourceAcquisitionId,
        artworkTitle
      } = tourCard.properties
      const params = new URLSearchParams(window.location.search)
      const stack = params.get("s") || undefined

      const payload: AddStopPayload = {
        // add the custom layer id to short circuit the full image loading
        customLayer: tourCard.properties?.customLayer,
        // refId is passed to associate the added stop with the metadata feature service.
        refId: tourCard.properties?.refId,
        // this is potentially not required
        [config?.metadataRefField as string]: tourCard.properties?.refId,
        // generate unique ID for the stack
        id: uuid(),
        owner: {
          id: myID || "",
          ...(me?.info || {}),
        } as AddStopPayload["owner"],
        stack,
        title,
        preTitle,
        description,
        blurhash,
        themeId,
        artworkType,
        artworkMedium,
        dateYear,
        dateYearApproximated,
        artistFirstName,
        artistLastName,
        artistOrganisationName,
        coverImage,
        coverImageCaption,
        lnglat,
        primaryArtworkDate,
        primaryArtistAttribution,
        primaryArtworkSourceOrganisationName,
        primaryArtworkSourceAcquisitionId,
        artworkTitle,
        // the ID of the object coming from the external source e.g. strapi
        itemId
      }

      const result = emit<AddStopPayload>(REQUEST_ADD_STOP, payload)
      if (result) {
        dispatchTourCard({ added: true })
        return
      } else {
        enqueueSnackbar(t("map.notifications.errorTooManyStops"), {
          variant: "error",
          autoHideDuration: 5000,
        })
      }
    }
  }

  /** Handle closing the tour card */
  const onCloseTourCard = useCallback(() => {
    dispatchTourCard({ open: false })
  }, [])

  /** Handle when the tour card rests (after being added or closed) */
  const onTourCardRest = useCallback(() => {
    if (tourCard.open && isUserAdmitted === "yes") {
      enqueueSnackbar(
        t("map.notifications.stopAdded", { title: tourCard?.properties?.title })
      )
    }
    dispatchTourCard({
      open: false,
      added: false,
      origin: undefined,
      properties: undefined,
    })
  }, [t, tourCard, dispatchTourCard, enqueueSnackbar, isUserAdmitted])

  useEffect(() => {
    if (projectionOpen || referencesOpen) {
      onCloseTourCard()
    }
  }, [projectionOpen, referencesOpen, onCloseTourCard])

  return (
    <TourCardContext.Provider
      value={{
        tourCard,
        dispatchTourCard,
        onOpenTourCard,
        onAddTourCard,
        onCloseTourCard,
        onTourCardRest,
        loading,
      }}
    >
      {children}
    </TourCardContext.Provider>
  )
}

export default TourCardProvider

/** Hook to consume the context */
export const useTourCard = () => {
  const context = useContext(TourCardContext)
  if (!context) throw Error("Not inside <TourCardProvider />")
  return context
}
