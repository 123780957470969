import React, { FunctionComponent, useCallback, useEffect } from "react"
import { a, config as springConfig, useSpring } from "react-spring"
import { useDrag } from "react-use-gesture"
import { Box, useTheme, Typography, Modal } from "@material-ui/core"
import { actionCreators, useApp } from "../common/AppState"
import { useTranslation } from "react-i18next"
import useMeasure from "react-use-measure"
import { PORTAL_OUTLET_ID } from "../common/constants"
import { useConfig } from "@citydna/experience"

/** Filter/Layer List component props */
export interface FilterListProps {
  contentHeight: number
  onClick: (event: React.UIEvent | UIEvent) => void
  onTouchStart: (event: React.UIEvent | UIEvent) => void
  onTouchMove: (event: React.UIEvent | UIEvent) => void
  onTouchEnd: (event: React.UIEvent | UIEvent) => void
  onTouchCancel: (event: React.UIEvent | UIEvent) => void
  onPointerDown: (event: React.UIEvent | UIEvent) => void
  onPointerMove: (event: React.UIEvent | UIEvent) => void
  onPointerUp: (event: React.UIEvent | UIEvent) => void
  onPointerCancel: (event: React.UIEvent | UIEvent) => void
}

type filterDrawerProps = {
  drawerHeight: number
  extendThreshold?: number
  shutHeight: number
  ListComponent: FunctionComponent<FilterListProps>
}

const AnimatedBox = a(Box)

const DragBar = () => (
  <Box position="relative" p={1} display="flex" justifyContent="center">
    <Box width={29} height={3} bgcolor="#5D616C" borderRadius="20%" />
  </Box>
)

/**
 * Component that accepts CSS values and a filter/layers list
 * to render in a draggable sheet that extends from the bottom of
 * the mobile device.
 */
export const FilterDrawer: FunctionComponent<filterDrawerProps> = ({
  drawerHeight,
  extendThreshold = 50,
  shutHeight,
  ListComponent,
}) => {
  const config = useConfig()
  const theme = useTheme()
  /** get filterOpen param from app provider */
  const [, dispatch] = useApp()
  /** onRest is a fn that is called when the spring movement stops - thinking this could be when to call dispatch({ filterOpen: false }) */
  const [{ y }, api] = useSpring(() => ({ y: 0, scale: 1 }))
  const [measureRef, { height }] = useMeasure()

  /** callback function to open the sheet */
  const open = useCallback(() => {
    api.start({
      y: -drawerHeight,
      immediate: false,
      config: springConfig.gentle,
    })
  }, [api, drawerHeight])

  /** callback function to close the sheet and set the state of the filterOpen or layerOpen to false */
  const close = useCallback(() => {
    api.start({ y: 0, immediate: false, config: springConfig.gentle })
    setTimeout(() => {
      config.filterComponent === "Layers"
        ? dispatch(actionCreators.updateState({ layersOpen: false }))
        : dispatch(actionCreators.updateState({ filterOpen: false }))
    }, 100)
  }, [api, dispatch, config.filterComponent])

  /** Drag function to control the movement of the sheet */
  const bind = useDrag(
    ({ last, movement: [, my], tap }) => {
      // return on tap
      if (tap) return

      // only let the user drag so high before cancelling
      if (my <= -drawerHeight - extendThreshold) {
        open()
        return
      }
      // if the user drags the drawer down far enough
      if (my >= shutHeight) {
        close()
        return
      }
      // if not closed far enough - then open - else close
      if (last) {
        if (my < -300) {
          open()
        } else {
          close()
        }
      } else {
        // all other conditions - move the drawer
        api.start({
          y: my,
          scale: 1,
          immediate: false,
          config: springConfig.stiff,
        })
      }
    },
    {
      initial: () => [0, y.get()],
      filterTaps: true,
      axis: "y",
    }
  )

  /** open up the drawer on mount each time */
  useEffect(() => {
    open()
  }, [open])

  /** Get translations */
  const { t } = useTranslation<string>()

  const handleClickInner = (event: React.UIEvent | UIEvent): void => {
    event.stopPropagation()
  }

  return (
    <Modal
      container={document.getElementById(PORTAL_OUTLET_ID)}
      open={true}
      onBackdropClick={close}
    >
      <AnimatedBox
        style={{ y, outline: "none" }}
        {...bind()}
        position="absolute"
        bottom={-drawerHeight - extendThreshold}
        left={0}
        right={0}
        bgcolor={
          theme.palette.type === "dark"
            ? theme.palette.grey[600]
            : theme.palette.grey[100]
        }
        borderTop={1}
        borderColor={theme.palette.grey[400]}
        zIndex={11}
        height={drawerHeight}
      >
        <Box
          // @ts-ignore
          ref={measureRef}
        >
          <DragBar />
          <Box p={2} pb={0} pt={0}>
            <Typography variant="h2">
              {t("controls.layers.sheetTitle")}
            </Typography>
            <Box pt={1}>
              <Typography variant="body2">
                {t("controls.layers.sheetDescription")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <ListComponent
          contentHeight={drawerHeight - height - extendThreshold}
          onClick={handleClickInner}
          onTouchStart={handleClickInner}
          onTouchMove={handleClickInner}
          onTouchEnd={handleClickInner}
          onTouchCancel={handleClickInner}
          onPointerDown={handleClickInner}
          onPointerMove={handleClickInner}
          onPointerUp={handleClickInner}
          onPointerCancel={handleClickInner}
        />
      </AnimatedBox>
    </Modal>
  )
}
