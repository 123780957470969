import { useRef, useLayoutEffect, useCallback } from "react";
import { useMap } from "@citydna/maps";
/**
 * Render this component to rotate the map.
 *
 * Updates the bearing of the map, it will not reset the bearing when un-mounted.
 */
export const MapIdleScreen = () => {
    const timeTracker = useRef(performance.now());
    const requestRef = useRef();
    /** used to cancel animation frame, workaround for firefox */
    const isMountedRef = useRef(true);
    const map = useMap();
    /** function to pass to the animation frame will set the state of bearing */
    const rotateCamera = useCallback((timestamp) => {
        if (!isMountedRef.current) {
            cancelAnimationFrame(requestRef.current);
            return;
        }
        const bearing = ((timestamp - timeTracker.current) / 1000) % 360;
        map.rotateTo(bearing, { duration: 0 });
        requestRef.current = requestAnimationFrame(rotateCamera);
    }, [map]);
    useLayoutEffect(() => {
        requestRef.current = requestAnimationFrame(rotateCamera);
        return () => {
            isMountedRef.current = false;
            cancelAnimationFrame(requestRef.current);
        };
    }, [rotateCamera, map]);
    return null;
};
