import React, { useCallback, useEffect, useRef } from "react"
import { useHistory } from "react-router-dom"
import { MapProvider } from "@citydna/maps"
import { LOCAL_CHANNEL_NAME, PublicRoomProvider } from "@citydna/platform"
import { MapActivity } from "./MapActivity"
import { PropertyFilterProvider } from "../common/PropertyFilterProvider"
import SocketProvider from "../common/SocketProvider"
import { DataProvider } from "../common/DataProvider"
import { TourCardProvider } from "../common/TourCard/TourCardProvider"
import { ThemesProvider } from "../common/ThemesProvider"
import { useQueryString } from "../common/useQueryString"
import { useApp, actionCreators } from "../common/AppState"
import { Control } from "@citydna/platform"
import {
  appFeaturePropertiesAccessor,
  Experiences,
  ConfigProvider,
  FeaturePropertiesAccessorProvider,
} from "@citydna/experience"

const featurePropertyAccessors = {
  "aboriginal-melbourne": appFeaturePropertiesAccessor.appAboriginalMelbourne,
  "state-library-images": appFeaturePropertiesAccessor.appMelbourneHistorical,
  citydna: appFeaturePropertiesAccessor.appTownhallExperience,
  "citydna-mkw": appFeaturePropertiesAccessor.appTownhallExperience,
}
interface AuthParams {
  /** jwt */
  j: string
  /** tokenId */
  t: string
  /** channel */
  c: string
}

type ExperienceProps = Omit<Control, "roomCapacity">

export function Experience({
  emitLimit,
  emitLimitResetTime,
  entryTimeLimit,
}: ExperienceProps) {
  const history = useHistory()
  const timerRef = useRef<number | undefined>()
  const { j: jwt, t: tokenId, c: channel } = useQueryString<AuthParams>()
  const [, dispatch] = useApp()

  const authenticatedTrigger = useCallback(() => {
    timerRef.current = window.setTimeout(() => {
      history.replace("/")
    }, (entryTimeLimit || 30) * 60 * 1000)
  }, [entryTimeLimit, history])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  const { e: experience } = useQueryString()
  const accessor = featurePropertyAccessors[experience as Experiences]

  return (
    <SocketProvider
      authenticatedTrigger={authenticatedTrigger}
      jwt={jwt}
      tokenId={tokenId}
      channelId={channel}
    >
      <PublicRoomProvider
        name={channel || LOCAL_CHANNEL_NAME}
        emitLimit={emitLimit || 1}
        emitLimitResetTime={
          emitLimitResetTime ? emitLimitResetTime * 1000 : 1 * 60 * 1000
        }
        userAdmittedCallback={(admitUser: "yes" | "no") => {
          dispatch(
            actionCreators.updateState({
              isUserAdmitted: admitUser,
            })
          )
        }}
      >
        <ConfigProvider
          bucketName={process.env.REACT_APP_CONFIG_BUCKET_NAME || ""}
          experience={experience as Experiences}
          environment={
            ["local", "staging"].includes(
              process.env.REACT_APP_RUNNING_ENVIRONMENT || ""
            )
              ? "nonprod"
              : "prod"
          }
        >
          <MapProvider>
            <DataProvider>
              <PropertyFilterProvider>
                <ThemesProvider>
                  {/* @ts-ignore */}
                  <FeaturePropertiesAccessorProvider accessor={accessor}>
                    <TourCardProvider>
                      <MapActivity />
                    </TourCardProvider>
                  </FeaturePropertiesAccessorProvider>
                </ThemesProvider>
              </PropertyFilterProvider>
            </DataProvider>
          </MapProvider>
        </ConfigProvider>
      </PublicRoomProvider>
    </SocketProvider>
  )
}
