import React, { FC, useEffect, useRef, useState } from "react"
import { makeStyles, IconButton, Typography, Box } from "@material-ui/core"
import { CloseRounded as CloseRoundedIcon } from "@material-ui/icons"
import { useCurrentRoom } from "@citydna/platform"
import { Button, Subtitle } from "@citydna/common"
import { Categories, ProjectionToggleConfirmation } from "@citydna/experience"
import { useSnackbar } from "notistack"
import { ProjectionList } from "./ProjectionList"
import { ProjectionDetail } from "./ProjectionDetail"
import { actionCreators, useApp } from "../../common/AppState"
import { ProjectionCategoriesSelection } from "./ProjectionCategoriesSelection"
import { useProjectionCategories } from "../../common/useProjectionCategories"

export const DEFAULT_PROJECTION_INTERVAL = 20

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100vw",
    zIndex: 999,
    top: 0,
    backgroundColor: theme.palette.grey[600],
    padding: theme.spacing(3),
  },
  closeIcon: {
    position: "absolute",
    right: "16px",
    top: "18px",
    "& .MuiSvgIcon-root": {
      fontSize: "18px",
    },
  },
  top: {
    display: "flex",
    flexDirection: "column",
  },
  subtitle: {
    fontSize: "10px",
    lineHeight: "16px",
  },
  buttonRow: {
    fontSize: "10px",
    borderBottom: "1px solid #3C404B",
    paddingBottom: "6px",
  },
}))

export const getDataset = (
  categories: Categories,
  categoryId: string,
  datasetId: string
) => {
  return categories[categoryId].datasets.find(
    (dataset) => dataset.id === datasetId
  )
}

export const ProjectionDrawer: FC = () => {
  const [{ activeProjection, projectionOpenFromDefaultButton }, dispatch] =
    useApp()

  const { enqueueSnackbar } = useSnackbar()

  /** get the projection categories from aws */
  const [categories, isError, refetch, isLoading] = useProjectionCategories()

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Unable to access projection datasets", {
        variant: "error",
      })
    }
  }, [isError, enqueueSnackbar])

  const { useEvent, emit } = useCurrentRoom()
  const timerRef = useRef<number | undefined>()
  const classes = useStyles()
  const [categoryId, setCategoryId] = useState<keyof Categories>(
    projectionOpenFromDefaultButton
      ? "majorWorks"
      : activeProjection.categoryId || "majorWorks"
  )
  const [datasetId, setDatasetId] = useState<string | undefined>(
    projectionOpenFromDefaultButton ? undefined : activeProjection.id
  )

  const emitProjection = (id: string) => {
    const dataset = getDataset(categories, categoryId, id)
    if (dataset) {
      const result = emit("PROJECTION_TOGGLE_ON", {
        id: dataset.id,
        categoryId,
        visible: true,
        ...dataset.projectionPayload,
      })
      if (!result) {
        enqueueSnackbar("Unable to queue projection", { variant: "error" })
      }
    }
  }

  const handleToggle = (id: string) => {
    if (activeProjection.id === undefined) {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current)
      }
      dispatch(actionCreators.projectionLoading())
      emitProjection(id)
      timerRef.current = window.setTimeout(() => {
        dispatch(actionCreators.projectionFailed())
        enqueueSnackbar("Unable to queue projection", { variant: "error" })
      }, 5000)
    }
  }

  useEvent<ProjectionToggleConfirmation>(
    "PROJECTION_TOGGLE_CONFIRMATION",
    (payload) => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current)
      }
      if (payload.visible) {
        dispatch(
          actionCreators.projectionStarted(payload.id, payload.categoryId)
        )
      }
    }
  )

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        window.clearTimeout(timerRef.current)
      }
    }
  }, [])

  return (
    <div className={classes.container}>
      <IconButton
        edge="end"
        onClick={() =>
          dispatch(
            actionCreators.updateState({
              projectionOpen: false,
              projectionOpenFromDefaultButton: false,
            })
          )
        }
        className={classes.closeIcon}
      >
        <CloseRoundedIcon />
      </IconButton>
      {isError && (
        <Box>
          <Typography variant="h3"> Oops, something went wrong. </Typography>
          <Box mt={1}>
            <Button variant="outlined" onClick={() => refetch()}>
              Try again
            </Button>
          </Box>
        </Box>
      )}
      {categories && !isError && (
        <>
          {datasetId !== undefined ? (
            <ProjectionDetail
              dataset={getDataset(categories, categoryId, datasetId)}
              categoryTitle={categories[categoryId].title}
              categoryId={categoryId}
              setDatasetIndex={setDatasetId}
              handleToggle={handleToggle}
            />
          ) : (
            <>
              <div className={classes.top}>
                <Subtitle primary="Select a projection" />
                <Typography variant="body2" className={classes.subtitle}>
                  View your chosen properties and landmarks amongst activity and
                  major works within the City of Melbourne
                </Typography>
                <ProjectionCategoriesSelection
                  category={categoryId}
                  setCategory={setCategoryId}
                  isLoading={isLoading}
                />
              </div>
              {isLoading ? (
                <ProjectionList isLoading={true} />
              ) : (
                <ProjectionList
                  category={categories[categoryId]}
                  categoryId={categoryId}
                  setDatasetIndex={setDatasetId}
                  isLoading={isLoading}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}
