import React from "react"
import { BoxProps, Box, useTheme } from "@material-ui/core"
import { useTranslation } from "react-i18next"

export const ConnectionDot: React.FC<{ connected: boolean } & BoxProps> = ({
  connected,
  ...props
}) => {
  const { t } = useTranslation<string>()
  const theme = useTheme()

  return (
    <Box
      width={12}
      height={12}
      borderRadius={6}
      border={'1px solid white'}
      bgcolor={connected ? "#00F08C" : theme.palette.error.main}
      title={t(
        `common.${
          connected ? "connectionDotConnected" : "connectionDotDisconnected"
        }`
      )}
      {...props}
    />
  )
}
