import React, { useCallback } from "react"
import { PusherProvider } from "@harelpls/use-pusher"
import { PRESENCE_CHANNEL_PREFIX } from "@citydna/platform"
import { colors } from "@citydna/common"
import { AvatarIconMap } from "@citydna/experience"
import { Channel, AuthorizerGenerator } from "pusher-js"
import { useSnackbar } from "notistack"
import { useTranslation } from "react-i18next"
import { API } from "aws-amplify"
import { useHistory } from "react-router-dom"
import { randomValue, randomValueArray } from "./utils"

export interface AuthData {
  auth: string
  channel_data?: string
  shared_secret?: string
}

/** Create key mirror of AvatarMap */
const avatarKeyMirror = Object.keys(AvatarIconMap).reduce(
  (acc, key) => ({ ...acc, [key]: key }),
  {}
)

/**
 * This is calling the API twice and unsubscribing.
 * Previously: https://support.pusher.com/hc/en-us/articles/360019306433-I-receive-Invalid-signature-Expected-HMAC-SHA256-error-what-is-that-
 */
export const SocketProvider: React.FC<{
  authenticatedTrigger: () => void
  jwt: string
  tokenId: string
  channelId: string
}> = ({
  children,
  authenticatedTrigger,
  jwt,
  tokenId,
  channelId,
  ...props
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation<string>()

  /** Generate authorizer for Pusher. It uses a JWT retrieved from the URL (transferred via QR code) */
  const authorizer = useCallback(
    (channel: Channel) => ({
      authorize: async (
        socketId: string,
        callback: (error: Error | null, auth: AuthData) => void
      ) => {
        try {
          if (!jwt || !tokenId) {
            return
          }
          const auth = await API.post(
            "api466165c9",
            "/authenticatepusherpublic",
            {
              body: {
                socketId,
                jwt,
                tokenId,
                channelName:
                  PRESENCE_CHANNEL_PREFIX + channelId || channel.name,
                user_info: {
                  color: randomValueArray([
                    ...Object.values(colors.pop),
                    ...Object.values(colors.light),
                  ]),
                  icon: randomValue(avatarKeyMirror),
                },
              },
            }
          )
          authenticatedTrigger()
          callback(null, auth)
        } catch (error) {
          history.replace("/")
          enqueueSnackbar(t("pusher.error"), {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "left" },
            preventDuplicate: true,
          })
        }
      },
    }),
    [authenticatedTrigger, channelId, enqueueSnackbar, history, jwt, t, tokenId]
  )

  return (
    <PusherProvider
      clientKey={process.env.REACT_APP_PUSHER_KEY || ""}
      cluster={process.env.REACT_APP_PUSHER_CLUSTER || "ap4"}
      enabledTransports={["ws", "wss"]}
      authorizer={authorizer as AuthorizerGenerator}
      {...props}
    >
      {children}
    </PusherProvider>
  )
}

export default SocketProvider
