import { selector } from "recoil";
import { bearingState, viewState, zoomState } from "./atoms";
export const adjustedViewState = selector({
    key: "adjustedViewState",
    get: ({ get }) => {
        const currentViewport = get(viewState);
        const zoom = get(zoomState);
        const bearing = get(bearingState);
        return {
            ...currentViewport,
            zoom: currentViewport.zoom + zoom,
            bearing: currentViewport.bearing + bearing,
        };
    },
});
