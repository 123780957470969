import { decode } from "blurhash";
export const blurHashToURL = (hash, width, height) => {
    const pixels = decode(hash, width, height);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageData = ctx.createImageData(width, height);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
    return canvas.toDataURL();
};
