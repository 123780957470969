import React, { FC } from "react"
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Theme,
  Box,
  Checkbox,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { HelpRounded as HelpRoundedIcon } from "@material-ui/icons"
import { FilterListProps } from "./FilterDrawer"
import {
  Applied,
  Approved,
  UnderConstruction,
  Landmark,
  BuildingDefault,
} from "@citydna/common"
import { DEVELOPMENT_ACTIVITY_MODEL_COLORS } from "@citydna/experience"
import {
  ActivePropertyFilters,
  usePropertyFilter,
} from "../common/PropertyFilterProvider"

const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    width: "2em",
    height: "2em",
  },
  avatar: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[600]
        : theme.palette.grey[100],
    "& path": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
  },
  applied: {
    backgroundColor: DEVELOPMENT_ACTIVITY_MODEL_COLORS.applied,
  },
  approved: {
    backgroundColor: DEVELOPMENT_ACTIVITY_MODEL_COLORS.approved,
  },
  under_construction: {
    backgroundColor: DEVELOPMENT_ACTIVITY_MODEL_COLORS.underConstruction,
  },
  poi: {
    backgroundColor: " #777b88",
  },
  default: {
    backgroundColor: "#212227",
    "& path": {
      fill: theme.palette.grey[100],
    },
    "& svg": {
      width: "20px",
      height: "18px"
    }
  },
  listItemText: {
    paddingRight: theme.spacing(1),
    "& .MuiListItemText-secondary": {
      color:
        theme.palette.type === "dark" ? theme.palette.grey[200] : "inherit",
    },
    checkBox: {
      "& .MuiIconButton-colorSecondary": {
        color: "#fff",
      },
    },
  },
}))

const icons = {
  applied: Applied,
  approved: Approved,
  under_construction: UnderConstruction,
  poi: Landmark,
  default: BuildingDefault,
}

const propertyTypes: Array<keyof ActivePropertyFilters> = [
  "applied",
  "approved",
  "under_construction",
  "poi",
  "default",
]

export const LayersList: FC<FilterListProps> = ({
  contentHeight,
  ...props
}) => {
  const { activePropertyFilters, setActivePropertyFilters } =
    usePropertyFilter()

  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) =>
    setActivePropertyFilters({
      ...(activePropertyFilters || {}),
      [e.target.name]: e.target.checked,
    })

  const classes = useStyles()

  const { t } = useTranslation<string>()

  return (
    <List
      style={{
        height: contentHeight,
        overflow: "auto",
      }}
      {...props}
    >
      {propertyTypes.map((layer) => {
        const Icon = icons[layer] || HelpRoundedIcon
        return (
          <Box key={layer}>
            <ListItem divider>
              <ListItemAvatar>
                <Avatar
                  className={classes[layer]}
                  title={`icon representing ${layer}`}
                >
                  <Icon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                id={`switch-${layer}`}
                primary={t(`controls.layers.${layer}.checkboxLabel`)}
                className={classes.listItemText}
              />
              <ListItemSecondaryAction>
                <Checkbox
                  size="small"
                  style={{ color: "#fff" }}
                  name={layer}
                  onChange={handleToggle}
                  checked={
                    activePropertyFilters && activePropertyFilters[layer]
                  }
                />
              </ListItemSecondaryAction>
            </ListItem>
          </Box>
        )
      })}
    </List>
  )
}
