import React, { FC } from "react"
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core"

interface PropertySearchResultsProps {
  results: Record<string, any> | undefined
  onClick: (result: Record<string, any>) => void
}

const useStyles = makeStyles<Theme>((theme) => ({
  listItem: {
    borderBottom: "1px solid rgb(93, 97, 108)",
  },
  listItemText: {
    "& .MuiListItemText-secondary": {
      color:
        theme.palette.type === "dark" ? theme.palette.grey[200] : "inherit",
    },
  },
}))

export const PropertySearchResults: FC<PropertySearchResultsProps> = ({
  results,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <List>
      {results?.map((result: Record<string, any>) => (
        <ListItem
          className={classes.listItem}
          key={result.id}
          button
          onClick={() => onClick(result)}
        >
          <ListItemText
            primary={result.display1.split(": ")[1]}
            secondary={result.display2}
            className={classes.listItemText}
          />
        </ListItem>
      ))}
    </List>
  )
}
