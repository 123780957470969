import { useMemo } from "react";
import { useEsriModules } from "./useEsriModules";
/** Creates and return the two feature layers used to grab data from AGOL */
export const useFeatureLayers = (config) => {
    /** First, grab the esri module */
    const [FeatureLayer] = useEsriModules([
        "esri/layers/FeatureLayer",
    ]);
    return useMemo(() => FeatureLayer && config
        ? {
            metadataLayer: new FeatureLayer({
                url: config?.metadataFeatureService,
            }),
            footprintsLayer: config.footprintsFeatureService
                ? new FeatureLayer({
                    url: config.footprintsFeatureService,
                    hasZ: true,
                    elevationInfo: {
                        mode: "absolute-height",
                        featureExpressionInfo: {
                            expression: "Geometry($feature).z",
                        },
                        unit: "meters",
                    },
                })
                : undefined,
        }
        : { metadataLayer: undefined, footprintsLayer: undefined }, [FeatureLayer, config]);
};
