import { useEffect, useState } from "react";
import { API } from "aws-amplify";
export const CONTROL_SUBSCRIPTION = /* GraphQL */ `
  subscription OnUpdateControl {
    onUpdateControl {
      id
      appId
      roomCapacity
      emitLimit
      emitLimitResetTime
      entryTimeLimit
      createdAt
      updatedAt
    }
  }
`;
export const CONTROL_QUERY = /* GraphQL */ `
  query ControlByAppId($appId: String) {
    controlByAppId(appId: $appId) {
      items {
        id
        appId
        roomCapacity
        emitLimit
        emitLimitResetTime
        entryTimeLimit
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
/**
 * A react hook that will fetch control measures using a graphql query. Pass in the app id
 * as 'experience' to fetch different controls based on the app.
 */
export function useControls({ experience, defaultValues, }) {
    const [controls, setControls] = useState(defaultValues || {
        emitLimit: null,
        emitLimitResetTime: null,
        entryTimeLimit: null,
        roomCapacity: null,
    });
    useEffect(() => {
        const subscription = API.graphql({
            query: CONTROL_SUBSCRIPTION,
            authMode: "API_KEY",
        }).subscribe({
            next: ({ value }) => {
                if (value.data?.onUpdateControl?.appId === experience) {
                    const { emitLimit, emitLimitResetTime, entryTimeLimit, roomCapacity, } = value.data.onUpdateControl;
                    setControls({
                        emitLimit,
                        emitLimitResetTime,
                        entryTimeLimit,
                        roomCapacity,
                    });
                }
            },
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [experience]);
    useEffect(() => {
        if (!experience) {
            return;
        }
        ;
        API.graphql({
            query: CONTROL_QUERY,
            variables: { appId: experience },
            authMode: "API_KEY",
        })
            .then((value) => {
            const { emitLimit, emitLimitResetTime, entryTimeLimit, roomCapacity } = value.data.controlByAppId.items[0];
            setControls({
                emitLimit,
                emitLimitResetTime,
                entryTimeLimit,
                roomCapacity,
            });
        })
            .catch((error) => {
            console.log(error);
        });
    }, [experience]);
    return controls;
}
