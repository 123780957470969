import React from "react";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { a, useSpring } from "@react-spring/web";
const useStyles = makeStyles((theme) => createStyles({
    cardContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        borderRadius: "40px",
        boxShadow: theme.shadows[8],
        backgroundColor: theme.palette.type === "dark"
            ? theme.palette.grey[600]
            : theme.palette.common.white,
    },
    bodyContainer: {
        flexGrow: 1,
    },
    footer: {
        position: "relative",
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.type === "dark"
            ? theme.palette.grey[600]
            : theme.palette.common.white,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        [theme.breakpoints.up("xxl")]: {
            padding: `${theme.spacing(4)}px ${theme.spacing(6)}px`,
        },
        width: "100%",
        borderRadius: "40px",
    },
}));
/**
 * Handles the core container of a card, it will only display body if the 'active' state
 * is true.
 */
export const BaseCard = ({ active, body: Body, footer: Footer, }) => {
    const classes = useStyles();
    const styles = useSpring({
        from: { opacity: 0 },
        to: { opacity: active ? 1 : 0 },
        delay: 750,
    });
    return (React.createElement(Box, { className: classes.cardContainer },
        React.createElement(a.div, { className: classes.bodyContainer, style: {
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
                ...styles,
            } }, active && React.createElement(React.Fragment, null, Body)),
        React.createElement(Box, { className: classes.footer }, Footer)));
};
BaseCard.displayName = "BaseCard";
