import { FC } from "react"
import {
  makeStyles,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@material-ui/core"

const useStyles = makeStyles({
  container: {
    fontSize: "10px",
    borderBottom: "1px solid #3C404B",
    paddingBottom: "6px",
  },
  checkboxLabel: {
    fontSize: "10px",
  },
  disabledCheckbox: {
    color: "#ff0000",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
  },
})

type ProjectionCategoriesSelectionProps = {
  setCategory: React.Dispatch<React.SetStateAction<string>>
  category: string
  isLoading: boolean
}

export const ProjectionCategoriesSelection: FC<ProjectionCategoriesSelectionProps> = ({
  category,
  setCategory,
  isLoading
}) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      <FormControl color="secondary" component="fieldset">
        <RadioGroup
          className={classes.radioGroup}
          name="projection"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <FormControlLabel
            value="majorWorks"
            control={<Radio size="small" />}
            label="Major works"
            classes={{
              label: classes.checkboxLabel,
            }}
            disabled={!!isLoading}
          />
          <FormControlLabel
            value="mobility"
            control={<Radio size="small" />}
            label="Mobility"
            classes={{
              label: classes.checkboxLabel,
            }}
            disabled={!!isLoading}
          />
          <FormControlLabel
            value="history"
            control={
              <Radio
                size="small"
                classes={{
                  disabled: classes.disabledCheckbox,
                }}
              />
            }
            label="History"
            classes={{
              label: classes.checkboxLabel,
            }}
            disabled={!!isLoading}
          />
        </RadioGroup>
      </FormControl>
    </Box>
  )
}
