import React from "react"
import { Box, Fab, makeStyles, Theme, Typography } from "@material-ui/core"
import { QuestionMark } from "@citydna/common"
import { useTranslation } from "react-i18next"
import { actionCreators, useApp } from "../common/AppState"

/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    boxShadow: theme.shadows[6],
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(1.5),
    width: "56px",
    height: "56px",
  },
  icon: {
    "& path": {
      fill:
        theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black,
    },
  },
  label: {
    position: "absolute",
    left: 0,
    bottom: -theme.spacing(3),
    width: "100%",
    textAlign: "center",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[100]
        : theme.palette.common.black,
  },
}))

export const InfoButton: React.FC = () => {
  const { t } = useTranslation<string>()

  /** Handle click */
  const [, dispatch] = useApp()
  const handleClick = () => {
    dispatch(actionCreators.updateState({ onboardingOpen: true }))
  }

  /** Get scoped classnames */
  const classes = useStyles()

  return (
    <Box position="relative">
      <Fab
        title={t("controls.help.controlButtonTitle")}
        onClick={handleClick}
        className={classes.fab}
      >
        <QuestionMark className={classes.icon}/>
      </Fab>
      <Typography variant="caption" className={classes.label}>
        {t("controls.help.controlButtonLabel")}
      </Typography>
    </Box>
  )
}
