import * as React from "react";
function SvgPhotograph({ title, titleId, ...props }) {
    return (React.createElement("svg", { width: "1em", height: "1em", viewBox: "0 0 50 50", fill: "none", xmlns: "http://www.w3.org/2000/svg", "aria-labelledby": titleId, ...props },
        title ? React.createElement("title", { id: titleId }, title) : null,
        React.createElement("circle", { cx: 25, cy: 25, r: 25, fill: "#000" }),
        React.createElement("path", { d: "M25.001 30.333a5.333 5.333 0 100-10.666 5.333 5.333 0 000 10.666z", fill: "#F2F3F4" }),
        React.createElement("path", { d: "M20 8.333l-3.05 3.334h-5.283A3.343 3.343 0 008.333 15v20c0 1.833 1.5 3.333 3.334 3.333h26.666c1.834 0 3.334-1.5 3.334-3.333V15c0-1.833-1.5-3.333-3.334-3.333H33.05L30 8.333H20zm5 25A8.336 8.336 0 0116.667 25c0-4.6 3.733-8.333 8.333-8.333S33.333 20.4 33.333 25 29.6 33.333 25 33.333z", fill: "#F2F3F4" })));
}
const MemoSvgPhotograph = React.memo(SvgPhotograph);
export default MemoSvgPhotograph;
