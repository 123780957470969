import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Avatar } from "../Avatar";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "50px",
        height: "50px",
        [theme.breakpoints.up("xxl")]: {
            width: "100px",
            height: "100px",
        },
    },
    text: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up("xxl")]: {
            marginRight: theme.spacing(2),
        },
    },
}));
export const CardFooter = ({ owner, active, expiresInSeconds, }) => {
    const classes = useStyles();
    return (React.createElement(Box, { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", height: "100%" },
        React.createElement(Box, { textOverflow: "ellipsis" },
            React.createElement(Typography, { variant: "caption" }, active ? `Moving on in ${expiresInSeconds} seconds` : "Up next")),
        owner && (React.createElement(Box, { display: "flex", flexDirection: "row", alignItems: "center" },
            React.createElement(Typography, { variant: "caption", className: classes.text }, "Queued by"),
            React.createElement(Avatar, { className: classes.root, icon: owner?.icon, color: owner?.color })))));
};
