import React from "react";
import { Map as ReactMapGlMap, } from "react-map-gl";
import { DEFAULT_MAP_ID } from "../constants";
import { useMapStyle, useSetViewportInBounds } from "../hooks";
import { useGetViewState } from "../state";
import "mapbox-gl/dist/mapbox-gl.css";
/**
 * Wraps [`react-map-gl`'s `Map`](https://visgl.github.io/react-map-gl/docs/api-reference/map)
 * All props are spread onto the react-map-gl Map component
 */
export const Map = ({ children, mapboxApiAccessToken, ...props }) => {
    const viewport = useGetViewState();
    const setViewportInBounds = useSetViewportInBounds();
    // get theme sensitive map style (revert to commaps if there is no theme present)
    const mapStyle = useMapStyle();
    /** remove undefined values */
    const cleanViewport = Object.keys(viewport).reduce((accumulator, next) => {
        if (viewport[next] !== undefined) {
            return {
                ...accumulator,
                [next]: viewport[next],
            };
        }
        return accumulator;
    }, {});
    return (React.createElement(ReactMapGlMap, { id: DEFAULT_MAP_ID, mapboxAccessToken: mapboxApiAccessToken, onMove: (viewport) => {
            setViewportInBounds(viewport.viewState);
        }, mapStyle: mapStyle, reuseMaps: true, ...cleanViewport, ...props }, children));
};
