import React, { useCallback } from "react"
import { Typography, Box, makeStyles } from "@material-ui/core"
import { HourglassHalf } from "@citydna/common"
import { Dialog } from "./Dialog"
import { actionCreators, useApp } from "./AppState"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    padding: theme.spacing(2),
  },
}))

export const WaitingRoomDialogue = () => {
  const [{ waitingRoomDialogOpen }, dispatch] = useApp()
  const classes = useStyles()
  const { t } = useTranslation<string>()

  const handleDialogClose = useCallback(() => {
    dispatch(actionCreators.updateState({ waitingRoomDialogOpen: false }))
  }, [dispatch])

  return (
    <Dialog
      isDialogOpen={waitingRoomDialogOpen}
      dialogCloseCallback={handleDialogClose}
      enableBackdropClick={false}
      body={
        <Typography variant="body2" align="center">
          {t('dialogs.waitingRoom.generic')}
        </Typography>
      }
      lottie={
        <Box className={classes.iconContainer}>
          <HourglassHalf height="6em" width="6em" />
        </Box>
      }
    ></Dialog>
  )
}
