import React, { forwardRef } from "react";
import { Marker } from "react-map-gl";
import useOutsideClickRef from "@rooks/use-outside-click-ref";
import { Box } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { MarkerDot } from "./MarkerDot";
import { MarkerIcon } from "./MarkerIcon";
import { MarkerDialog } from "./MarkerDialog";
/**
 * A controlled marker popup (controlled being state is handled externally instead of internally).
 */
export const ControlledMarkerPopup = forwardRef(({ open, onClose, onClick, color, children, title: propTitle, icon: Icon, ...props }, ref) => {
    /**
     * Unfortunately there is a race condition between the prop `open` and the
     * outsideClickRef handler. Thus we need to track open state internally as
     * well as externally and fire the close when necessary.
     */
    const prevOpenRef = React.useRef(open);
    const internalOnClick = () => {
        prevOpenRef.current = true;
        onClick && onClick();
    };
    const internalOnClose = () => {
        prevOpenRef.current = false;
        onClose && onClose();
    };
    const [outsideClickRef] = useOutsideClickRef(() => {
        if (prevOpenRef.current) {
            internalOnClose();
            prevOpenRef.current = false;
        }
        else {
            prevOpenRef.current = true;
        }
    });
    const title = !open
        ? onClick && "open marker popup"
        : onClose && "close marker popup";
    return (React.createElement(Marker, { ...props, 
        // @ts-ignore
        ref: ref },
        React.createElement(Box, { position: "relative", zIndex: open ? 99 : 1, title: propTitle, "data-testid": "ControlledMarkerPopup" },
            React.createElement("div", { ref: outsideClickRef },
                React.createElement(MarkerDot
                // @ts-ignore
                , { 
                    // @ts-ignore
                    boxShadow: open ? 0 : 4, color: !open && color, onClick: open ? internalOnClose : internalOnClick, "aria-haspopup": !!children, "aria-label": title, title: title },
                    React.createElement(MarkerIcon, { visible: open && !!onClose, title: "close-icon" },
                        React.createElement(CloseIcon, { color: "action" })),
                    Icon && (React.createElement(MarkerIcon, { visible: !open },
                        React.createElement(Icon, { color: "action" })))),
                children && React.createElement(MarkerDialog, { visible: open }, children)))));
});
