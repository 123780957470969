import { PROJECTION_DEFAULT_TIMER } from "../constants"
import { AppState, Action } from "./types"

/**
 *
 * TODO: action type should not be the partial app state, it should be a union of all possible
 * actions
 */
export const reducer = (state: AppState, action: Action) => {
  switch (action.type) {
    case "PROJECTION_STARTED":
      return {
        ...state,
        projectionLoading: false,
        activeProjection: {
          ...(action.payload?.activeProjection || state.activeProjection),
        },
      }
    case "PROJECTION_LOADING":
      return {
        ...state,
        projectionLoading: true,
      }
    case "UPDATE_PROJECTION_TIME":
      return {
        ...state,
        activeProjection: {
          ...state.activeProjection,
          timeLeft: action.payload?.activeProjection?.timeLeft,
        },
      }
    case "PROJECTION_FINISHED":
      return {
        ...state,
        activeProjection: {
          timeLeft: undefined,
          categoryId: undefined,
          id: undefined,
        },
      }
    case "PROJECTION_FAILED":
      return {
        ...state,
        projectionLoading: false,
        activeProjection: {
          timeLeft: undefined,
          categoryId: undefined,
          id: undefined,
        },
      }
    case "UPDATE_STATE":
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export const actionCreators = {
  projectionStarted: (
    id: string,
    categoryId: string,
    time: number = PROJECTION_DEFAULT_TIMER
  ) => ({
    type: "PROJECTION_STARTED",
    payload: {
      activeProjection: {
        timeLeft: time,
        id,
        categoryId,
      },
    },
  }),
  updateProjectionTime: (time: number) => ({
    type: "UPDATE_PROJECTION_TIME",
    payload: {
      activeProjection: {
        timeLeft: time,
      },
    },
  }),
  projectionFinished: () => ({
    type: "PROJECTION_FINISHED",
  }),
  updateState: (newValue: Partial<AppState>) => ({
    type: "UPDATE_STATE",
    payload: newValue,
  }),
  projectionLoading: () => ({
    type: "PROJECTION_LOADING",
  }),
  projectionFailed: () => ({ type: "PROJECTION_FAILED" }),
}
