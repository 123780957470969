import React, { createContext, memo, useContext, useMemo } from "react"
import {
  AMStory,
  MHMArtwork,
  ResponseFormatData,
  useConfig,
} from "@citydna/experience"
import { DataProviderValue } from "./types"
import { useQueries } from "react-query"
import {
  getFeatureCollectionFromConfig,
  getResultsFromStrapi,
} from "./api/queries"

type DataContextValue = DataProviderValue | undefined

const DataContext = createContext<DataContextValue | undefined>(undefined)

/**
 * Based on the application ID, retrieve all data points
 */
export const DataProvider: React.FC = memo(({ children }) => {
  const config = useConfig()

  const results = useQueries(
    config?.resources?.map((resource) => {
      return {
        queryKey: ["resourceLayer", resource],
        queryFn: () =>
          resource.type === "strapi"
            ? getResultsFromStrapi(resource)
            : getFeatureCollectionFromConfig(resource),
        enabled: !!config,
      }
    }) || []
  )

  const data = useMemo(() => {
    if (
      !results ||
      !config ||
      results.some((query) => query.isLoading) ||
      results.some((query) => query.isError)
    ) {
      return
    }

    if (config.id === "aboriginal-melbourne") {
      return {
        pointsOfInterest: results
          ?.map((story) => {
            return story.data.data?.map(
              (item: ResponseFormatData<AMStory>) => ({
                ...item.attributes,
                id: item?.id,
              })
            )
          })
          .flat(),
      }
    }
    if (config.id === "state-library-images") {
      return {
        pointsOfInterest: results
          ?.map((artwork) => {
            return artwork.data.data?.map(
              (item: ResponseFormatData<MHMArtwork>) => ({
                ...item.attributes,
                id: item?.id,
              })
            )
          })
          .flat(),
      }
    }
    return {
      pointsOfInterest: results?.map((result, idx) => ({
        id: config?.resources[idx].id,
        ...result.data,
      })),
    }
  }, [config, results])

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>
})

export default DataProvider

export const useData = () => {
  const context = useContext(DataContext)
  return context
}
