import React from "react";
import { makeStyles, Card, Typography, CardContent, CardActions, IconButton, Box, useTheme, } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useImage } from "../hooks/useImage";
import { Skrim } from "@citydna/common";
import { useWindowWidth } from "@react-hook/window-size";
import { Skeleton } from "@material-ui/lab";
import { BlurhashImage } from "../BlurhashImage";
import { ThemeIconMap } from "../common/ThemeIconMap";
import { HelpOutlineRounded as HelpOutlineRoundedIcon } from "@material-ui/icons";
import { useConfig } from "../ConfigProvider";
/** handy function to leave the user wanting more on the MHM tour card */
const trimString = (length, text, appId) => {
    if (appId !== "state-library-images") {
        return text;
    }
    if (text.split(" ").length <= length) {
        return text;
    }
    return `${text.split(" ").splice(0, length).join(" ")}...`;
};
/** Scoped @material-ui/core stylesheet */
const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: theme.shadows[2] + " !important",
        position: "relative",
        borderRadius: 10,
        display: "flex",
        flexDirection: "column",
        width: ({ width, windowWidth }) => width || windowWidth - theme.spacing(4),
        height: ({ height }) => height,
        backgroundColor: theme.palette.type === "dark"
            ? theme.palette.grey[500]
            : theme.palette.grey[50],
    },
    backgroundImage: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 0,
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        zIndex: 3,
        padding: 0,
    },
    closeIcon: {
        display: "flex",
        borderRadius: "50%",
        width: "32px",
        height: "32px",
        justifyContent: "center",
        alignItems: "center",
    },
    mediaContentContainer: {
        display: "flex",
        flexDirection: "column",
    },
    media: {
        display: "flex",
        flexGrow: 1,
        minHeight: "120px",
        opacity: ({ backgroundImage }) => (backgroundImage ? 0 : 1),
    },
    description: {},
    content: {
        position: "relative",
        zIndex: 2,
        overflow: "hidden",
        "& .MuiTypography-body2": {
            color: ({ backgroundImage }) => backgroundImage ? theme.palette.grey[200] : "inherit",
        },
        "& .MuiTypography-h2": {
            color: ({ backgroundImage }) => backgroundImage ? theme.palette.grey[50] : "inherit",
        },
    },
    actions: {
        height: "60px",
        justifyContent: "flex-end",
        borderTop: ({ backgroundImage }) => backgroundImage
            ? "none"
            : `1px solid ${theme.palette.type === "dark"
                ? theme.palette.grey[400]
                : theme.palette.grey[200]}`,
        position: "relative",
        zIndex: 2,
    },
    icon: ({ appId }) => ({
        position: "absolute",
        zIndex: 3,
        borderRadius: "50%",
        backgroundColor: appId === "state-library-images"
            ? theme.palette.common.black
            : theme.palette.common.white,
        width: "32px",
        height: "32px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        left: theme.spacing(1),
        top: theme.spacing(1),
        padding: "4px",
    }),
    expandImageActions: {
        position: "absolute",
        zIndex: 10,
        bottom: theme.spacing(0),
    },
}));
export const TourCard = ({ actions, address, backgroundImage, backgroundImageCaption, blurhash, body, className, closeButtonTitle = "Close the card", coverImage, coverImageCaption, coverImageHeight, summary, description, loading, onClose, preTitle, artworkType, title, width, height, hideImage, hideContent, avatarAtBottom, backgroundColor, isPublicApp, avatarKey, expandBackgroundImageActions, }) => {
    const coverImageUrl = useImage(coverImage);
    const backgroundImageUrl = useImage(backgroundImage);
    const windowWidth = useWindowWidth();
    const theme = useTheme();
    const config = useConfig();
    /** Get css classes */
    const classes = useStyles({
        backgroundImage: backgroundImageUrl,
        coverImage: coverImageUrl,
        appId: config?.id,
        coverImageHeight,
        windowWidth,
        width,
        height,
    });
    const Icon = ThemeIconMap[avatarKey || ""] || HelpOutlineRoundedIcon;
    return (React.createElement(Card, { elevation: 2, className: `${classes.root} ${className}`, style: {
            ...(avatarAtBottom && { justifyContent: "flex-end" }),
            ...(backgroundColor && { backgroundColor }),
        }, "data-testid": "tour-card-root", title: backgroundImageCaption },
        isPublicApp && (React.createElement(Icon, { className: classes.icon, title: `icon representing ${preTitle}` })),
        expandBackgroundImageActions && coverImageUrl && (React.createElement(CardActions, { className: classes.expandImageActions }, expandBackgroundImageActions)),
        backgroundImageUrl && (React.createElement(React.Fragment, null,
            React.createElement(BlurhashImage, { className: classes.backgroundImage, blurhash: blurhash, imageUrl: backgroundImageUrl, title: backgroundImageCaption }),
            React.createElement(Skrim, { bottom: 0, left: 0, right: 0, height: "50%", zIndex: 1 }))),
        onClose && (React.createElement(IconButton, { className: classes.closeButton, onClick: onClose, title: closeButtonTitle },
            React.createElement(Box, { className: classes.closeIcon },
                React.createElement(CloseIcon, { style: {
                        width: "24px",
                        height: "24px",
                        color: theme.palette.common.white,
                    } })))),
        React.createElement("div", { className: classes.mediaContentContainer, style: { height: isPublicApp ? "auto" : "788px" } },
            !hideImage && (React.createElement(BlurhashImage, { className: classes.media, blurhash: blurhash, imageUrl: coverImageUrl, title: coverImageCaption })),
            !hideContent && (React.createElement(CardContent, { className: classes.content },
                React.createElement(Typography, { variant: "body2", style: { ...(isPublicApp && { marginBottom: "12px" }) } }, loading ? (React.createElement(Box, { component: Skeleton, maxWidth: 120, height: 14.22 })) : (preTitle || artworkType)),
                React.createElement(Typography, { variant: "h2", style: { ...(isPublicApp && { marginBottom: "12px" }) } }, loading ? (React.createElement(Box, { component: Skeleton, maxWidth: 230, height: "32.25px !important" })) : (title)),
                React.createElement(Typography, { variant: "body2", className: classes.description }, loading ? (React.createElement(React.Fragment, null,
                    React.createElement(Box, { component: Skeleton, maxWidth: 375, height: 14.22 }),
                    React.createElement(Box, { component: Skeleton, maxWidth: 300, height: 14.22 }))) : (summary || address || trimString(18, description, config?.id))),
                body))),
        actions && (React.createElement(CardActions, { className: classes.actions }, loading ? (React.createElement(React.Fragment, null,
            React.createElement(Box, { component: Skeleton, width: 100 }),
            React.createElement(Box, { component: Skeleton, height: "32px !important", width: 32, 
                // @ts-ignore
                variant: "circle" }))) : (actions)))));
};
