import { useCallback } from "react";
import { useMap } from "react-map-gl";
import WebMercatorViewport from "@math.gl/web-mercator";
import bbox from "@turf/bbox";
import { DEFAULT_MAP_ID } from "../constants";
export const useFitViewportToFeature = (id = DEFAULT_MAP_ID) => {
    const map = useMap()[id];
    /**
     * Animates the viewport to fit a given feature on-screen.
     * @param feature Feature<any> feature to fit the map to
     */
    const fitViewportToFeature = useCallback((feature, options) => {
        if (!feature)
            throw Error("You must pass a feature to fitMapToFeature");
        if (!map)
            return;
        const bounds = bbox(feature);
        const { clientWidth: width, clientHeight: height } = map.getContainer();
        const vp = new WebMercatorViewport({ width, height });
        /** Clamp horizontal padding if required */
        const { padding, offset, ...rest } = options;
        if (typeof padding === "object" &&
            width < (padding.left || 0) + (padding.right || 0)) {
            padding.left = 0;
            padding.right = 0;
            console.warn("map width is less than padding width, resetting to 0px");
        }
        /** Clamp vertical padding if too wide */
        if (typeof padding === "object" &&
            width < (padding.top || 0) + (padding.bottom || 0)) {
            padding.top = 0;
            padding.bottom = 0;
            console.warn("map height is less than padding height, resetting to 0px");
        }
        const { latitude, longitude, zoom } = vp.fitBounds([
            [bounds[0], bounds[1]],
            [bounds[2], bounds[3]],
        ], { padding, offset });
        map.flyTo({
            center: [longitude, latitude],
            zoom,
            duration: 2000,
            essential: true,
        });
    }, [map]);
    return fitViewportToFeature;
};
