import React, { useContext, createContext } from "react";
const FeaturePropertiesContext = createContext(undefined);
export const FeaturePropertiesAccessorProvider = ({ accessor, children }) => {
    return (React.createElement(FeaturePropertiesContext.Provider, { value: accessor }, children));
};
export const useFeaturePropertiesAccessor = () => {
    const context = useContext(FeaturePropertiesContext);
    if (context === undefined)
        throw Error("Not inside <FeaturePropertiesAccessorProvider />");
    return context;
};
