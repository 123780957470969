import { useCallback } from "react";
import { useEvent as useChannelEvent, useClientTrigger, usePresenceChannel, usePusher, } from "@harelpls/use-pusher";
/**
 * Returns event listeners and publishers for a give room on the citydna platform.
 * @param roomName name of the room you'd like to interact with
 */
export function useRoom(roomName) {
    // wrap channel with presence- prefix
    const { client } = usePusher();
    const { channel, members, me, myID = client && client.id, } = usePresenceChannel("presence-" + roomName);
    // wrap emit with client- prefix
    const defaultEmit = useClientTrigger(channel);
    const emit = useCallback((eventName, data = {}) => {
        defaultEmit("client-" + eventName, data);
        return true;
    }, [defaultEmit]);
    // wrap useEvent with client- prefix
    const useEvent = (eventName, callback) => useChannelEvent(channel, "client-" + eventName, callback);
    const subscribed = Boolean(channel && channel.subscribed);
    // generate map of devices
    const devices = members &&
        Object.entries(members).map(([id, info]) => {
            return {
                id,
                info,
                self: id === myID,
            };
        });
    return {
        useEvent,
        emit,
        devices,
        me,
        myID,
        subscribed,
        _channel: channel,
    };
}
