import React from "react";
import { Box, makeStyles, Typography, useMediaQuery, useTheme, } from "@material-ui/core";
import { AuthQRCode } from "../AuthQRCode";
import { useCurrentRoom, useWaitingRoom, useControls } from "@citydna/platform";
const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        width: 166,
        height: 187,
        [theme.breakpoints.up("xxl")]: {
            width: 332,
            height: 374,
        },
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.type === "dark"
            ? theme.palette.grey[500]
            : theme.palette.common.white,
        border: `1px solid ${theme.palette.type === "dark"
            ? theme.palette.grey[700]
            : theme.palette.grey[200]}`,
        borderRadius: 20,
    },
    qrCode: {
        position: "absolute",
        top: 42,
        left: 20,
        [theme.breakpoints.up("xxl")]: {
            top: 84,
            left: 40,
        },
    },
    qrCodeText: {
        position: "absolute",
        top: 16,
        left: 20,
        [theme.breakpoints.up("xxl")]: {
            top: 32,
            left: 40,
        },
    },
}));
export const TownHallMapQRCode = React.memo(({ publicAppUrl, experience }) => {
    const { roomCapacity } = useControls({
        experience,
        defaultValues: {
            emitLimit: null,
            emitLimitResetTime: null,
            entryTimeLimit: null,
            roomCapacity: 10,
        },
    });
    const classes = useStyles();
    const theme = useTheme();
    const isXxl = useMediaQuery(theme.breakpoints.up("xxl"));
    const { _channel } = useCurrentRoom();
    // no need to return anything, but this needs to be called to let users in
    useWaitingRoom({ roomCapacity });
    return (React.createElement(Box, { className: classes.container },
        React.createElement(Typography, { variant: "h6", className: classes.qrCodeText }, "Scan to join"),
        React.createElement(Box, { className: classes.qrCode },
            React.createElement(AuthQRCode, { publicAppUrl: publicAppUrl, channel: _channel?.name.replace(/presence-/gi, ""), experience: experience, width: isXxl ? 252 : 126, height: isXxl ? 252 : 126, qrProps: {
                    bgColor: theme.palette.common.white,
                    fgColor: theme.palette.common.black,
                } }))));
});
