import React, { FC } from "react"
import { makeStyles, List, ListItem, Divider, Box } from "@material-ui/core"
import { ProjectionDataset } from "./ProjectionDataset"
import { Category } from "@citydna/experience"
import { TouchScrollable } from "react-scrolllock"

const useStyles = makeStyles((theme) => ({
  top: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontSize: "20.25px",
  },
  buttonRow: {
    fontSize: "10px",
    borderBottom: "1px solid #3C404B",
    paddingBottom: "6px",
  },
  list: {
    overflow: "auto",
    height: "100%",
    "-webkit-overflow-scrolling": "touch",
    marginRight: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
  },
  dividerOverride: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    backgroundColor: "#3C404B",
  },
}))

export interface ProjectionListProps {
  category?: Category
  categoryId?: string
  setDatasetIndex?: React.Dispatch<React.SetStateAction<string | undefined>>
  isLoading: boolean
}

export const ProjectionList: FC<ProjectionListProps> = ({
  category,
  categoryId,
  setDatasetIndex,
  isLoading,
}) => {
  const classes = useStyles()

  return (
    <TouchScrollable>
      <Box className={classes.list}>
        <List disablePadding>
          {isLoading
            ? new Array(5).fill(undefined).map((_, idx) => (
                <ListItem key={idx}>
                  <ProjectionDataset isLoading />
                </ListItem>
              ))
            : category &&
              setDatasetIndex &&
              category.datasets.map((dataset) => (
                <React.Fragment key={dataset.title}>
                  <ListItem
                    button
                    onClick={() => setDatasetIndex(dataset.id)}
                    key={dataset.title}
                  >
                    <ProjectionDataset
                      isLoading={isLoading}
                      dataset={dataset}
                      categoryId={categoryId}
                    />
                  </ListItem>
                  <Divider className={classes.dividerOverride} />
                </React.Fragment>
              ))}
        </List>
      </Box>
    </TouchScrollable>
  )
}
