import React, { useEffect, useRef } from "react"
import { useSpring } from "@react-spring/web"
import { DEFAULT_PROJECTION_INTERVAL } from "../../controls/Projection/ProjectionDrawer"

export const useProgressBar = (timeLeft: number) => {
  const [progressBarProps, setProgressBarProps] = useSpring(() => ({
    width: `${
      ((DEFAULT_PROJECTION_INTERVAL - timeLeft) / DEFAULT_PROJECTION_INTERVAL) *
      100
    }%`,
  }))
  const previousExpiryRef = useRef<number>(0)

  useEffect(() => {
    if (timeLeft !== undefined) {
      if (timeLeft > previousExpiryRef.current) {
        setProgressBarProps({
          width: "100%",
          config: { duration: timeLeft * 1000 - 500 },
        })
      }
      previousExpiryRef.current = timeLeft
      if (timeLeft < 1) {
        setProgressBarProps({
          width: "0%",
          config: { duration: 0 },
        })
      }
    }
  }, [timeLeft, setProgressBarProps])

  return [progressBarProps]
}
