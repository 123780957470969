import React, { useEffect, useRef } from "react"
import { useSpring, animated, config } from "react-spring"
import { useDrag } from "react-use-gesture"
import { useTheme, makeStyles, Dialog } from "@material-ui/core"

const popupConfig: Record<string, number> = {
  mass: 0.3,
  tension: 200,
  friction: 7,
}

interface PopupProps {
  /** Whether the card has been added or not */
  isAdding?: boolean
  /** Class to add to the element */
  className?: string
  /** Height of children */
  height: number
  /** Callback when animation finishes */
  onRest?: () => void
  /** Callback when the card has been added */
  onAdd?: () => void
  /** Origin of click before opening */
  origin?: { x: number; y: number }
  /** Whether the card is visible or not */
  visible?: boolean
  /** Callbback when the card has been closed */
  onClose: () => void
}

/** Transform interpolator */
const trans = (x: number, y: number, s: number) => {
  return `translate3d(${x}px,${y}px,0px) scale(${s})`
}

const CONTROLS_HEIGHT = 120

export const Popup: React.FC<PopupProps> = ({
  isAdding,
  height,
  onRest,
  onAdd,
  origin,
  visible,
  children,
  onClose,
  ...props
}) => {
  /** Handle springs */
  const [translationProps, setTranslationProps] = useSpring(() => ({
    /** x: x position, y: y position, s: scale, o: opacity */
    xys: [0, 0, 0],
    config: popupConfig,
  }))
  /** Separate to allow different config use */
  const [opacityProps, setOpacityProps] = useSpring(() => ({ opacity: 0 }))

  /** Handle opening and closing of the popup */
  const { x: xOrigin, y: yOrigin } = origin || {}

  /** calculate the visible position of the popup */
  const yVisiblePosition =
    yOrigin && yOrigin + height < window.innerHeight - CONTROLS_HEIGHT
      ? yOrigin
      : window.innerHeight - height - CONTROLS_HEIGHT - 32

  /** Set visibility spring when visibility prop changes (i.e. a feature is clicked, open the popup) */
  useEffect(() => {
    /** Return early if we're adding. We want to let that sprang do it's thang */
    if (visible && isAdding) {
      return
    }

    /** When transitioning to visible */
    if (visible && xOrigin && yOrigin && yVisiblePosition) {
      /** Immediately set the origin so that the animation begins from the click */
      setTranslationProps({
        xys: [xOrigin, yOrigin, 0],
        immediate: true,
        onRest: undefined,
      })

      /** Set timeout so that the origin is set before the animation begins. */
      setTimeout(() => {
        setTranslationProps({
          xys: [16, yVisiblePosition, 1],
          config: popupConfig,
          immediate: false,
          onRest: undefined,
        })
      }, 1)
    }

    /** Toggle opacity based on visibility */
    setOpacityProps({ opacity: visible ? 1 : 0 })
  }, [
    setTranslationProps,
    setOpacityProps,
    visible,
    xOrigin,
    yOrigin,
    yVisiblePosition,
    height,
    isAdding,
  ])

  /**
   * Handles transitioning to invisible. This is split from above
   * to allow loading skeleton to happen without a re-run of the
   * effect (onRest changes when the tour card changes.)
   */
  useEffect(() => {
    if (!visible && xOrigin && yOrigin) {
      /** When transitioning to invisible */
      setTranslationProps({
        xys: [xOrigin, yOrigin, 0],
        // @ts-ignore
        config: { ...popupConfig, clamp: true },
        onRest,
      })
    }
  }, [visible, xOrigin, yOrigin, onRest, setTranslationProps])

  /** Handle adding the popup */
  useEffect(() => {
    if (isAdding) {
      setTranslationProps({
        xys: [16, -height, 1],
        onRest,
        // @ts-ignore
        config: { ...config.wobbly, mass: 5, clamp: true },
      })
    }
  }, [isAdding, height, onRest, setTranslationProps])

  /** Handle dragging the popup */
  const goneRef = useRef(false)
  const bind = useDrag(
    ({
      cancel,
      direction: [, yDir],
      down,
      last,
      movement: [, yMovement],
      velocity,
    }) => {
      /** If the user drags down too far, reset to original position */
      if (yMovement > 64 && yDir > 0) {
        cancel && cancel()
      }

      /** If it's the last frame, set either gone or original position */
      if (last) {
        setTranslationProps({
          xys: [16, goneRef.current ? -height : yVisiblePosition, 1],
          onRest: goneRef.current ? onRest : undefined,
          // @ts-ignore
          config: { ...config.wobbly, clamp: goneRef.current ? true : false },
        })

        /** Add it to the tour immediately */
        if (goneRef.current) {
          onAdd && onAdd()
        }
        return
      }

      /** If the user has 'flicked' the card, mark it as gone and add it to the tour */
      if (down && ((velocity > 0.8 && yDir < 0) || yMovement < -240)) {
        goneRef.current = true
      }

      const y = down && goneRef.current ? -height : yMovement + yVisiblePosition

      setTranslationProps({
        xys: [16, y, 1],
        onRest: goneRef.current ? onRest : undefined,
        // @ts-ignore
        config: { ...config.wobbly, clamp: goneRef.current ? true : false },
      })
    }
  )

  const theme = useTheme()

  const handleCloseModal = (reason: "escapeKeyDown" | "backdropClick") => {
    if (reason === "backdropClick") {
      onClose()
    }
  }

  return (
    <Dialog
      open
      onClose={(e, reason) => handleCloseModal(reason)}
      style={{ zIndex: 999 }}
      BackdropProps={{
        style: {
          backgroundColor:
            theme.palette.type === "dark"
              ? "rgba(0,0,0,0.5)"
              : "rgba(255,255,255,0.5)",
        },
      }}
    >
      <animated.div
        data-testid="popup-root"
        style={{
          // @ts-ignore
          transform: translationProps.xys.interpolate(trans),
          opacity: opacityProps.opacity,
        }}
        {...props}
        {...bind()}
      >
        {children}
      </animated.div>
    </Dialog>
  )
}
