import React, { Suspense } from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import { Theme, SnackbarProvider } from "@citydna/common"
import { FullscreenProvider } from "./common/FullscreenProvider"
import { PORTAL_OUTLET_ID } from "./common/constants"
import { Loading } from "./common/Loading"
import { Home } from "./pages/Home"
import { useOnboarding } from "./onboarding/useOnboarding"
import { AppProvider } from "./common/AppState"
import { Experience } from "./pages/Experience"
import { useQueryString } from "./common/useQueryString"
import { useControls } from "@citydna/platform"
import { QueryClient, QueryClientProvider } from "react-query"

const queryClient = new QueryClient()

function App() {
  const [showOnboarding] = useOnboarding()
  const { e: experience } = useQueryString()
  // remove roomCapacity as it's used in Experience
  // eslint-disable-next-line
  const { roomCapacity, ...rest } = useControls({
    experience,
  })

  return (
    <Suspense fallback={<Loading />}>
      <FullscreenProvider>
        <Theme variant="dark">
          <AppProvider onboardingOpen={showOnboarding === "true"}>
            <div id={PORTAL_OUTLET_ID} />
            <SnackbarProvider
              domRoot={document.getElementById(PORTAL_OUTLET_ID) || undefined}
            >
              <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                  <Switch>
                    <Route path="/e">
                      <Experience {...rest} />
                    </Route>
                    <Route path="/">
                      <Home />
                    </Route>
                  </Switch>
                </BrowserRouter>
              </QueryClientProvider>
            </SnackbarProvider>
          </AppProvider>
        </Theme>
      </FullscreenProvider>
    </Suspense>
  )
}

export default App
