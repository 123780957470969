import React from "react";
import { Box } from "@material-ui/core";
/** Renders either a simple line or fill block of n legend components */
export const PolygonPolyline = ({ items, shape: Shape, }) => {
    return (React.createElement(Box, null, items.map((item, idx) => (React.createElement(Box, { key: idx, display: "flex", alignItems: "center" },
        React.createElement(Shape, { color: item.color, width: item.width, height: item.height }),
        React.createElement(Box, { fontSize: 12, pl: 1 }, item.label))))));
};
export const DynamicPoint = ({ items, shape: Shape }) => {
    return (React.createElement(Box, { display: "flex", pt: 1 },
        React.createElement(Box, null, items.map((item, idx) => (React.createElement(Box, { key: idx, display: "flex", alignItems: "center", justifyContent: "center", pb: 1 },
            React.createElement(Shape, { color: item.color, height: getProportionalPointSize(item.height, idx), width: getProportionalPointSize(item.width, idx) }))))),
        React.createElement(Box, { p: 1, lineHeight: 3.5 }, items.map((item, idx) => (React.createElement(Box, { key: idx, fontSize: 12, pl: 1 }, roundUpFive(parseInt(item.label))))))));
};
/**
 * note: increments must be in 50%
 * decrease steps for each proportional point legend item.
 * Hence why the ratio is 0.5 units
 * */
const getProportionalPointSize = (start, idx) => {
    const ratio = 0.5;
    return start * ratio ** idx;
};
/** helper function to make the pedestrian data counts multiple of 5 */
const roundUpFive = (num) => Math.ceil(num / 5) * 5;
