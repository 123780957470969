import React, { FunctionComponent } from "react"
import { ThemeIconMap } from "@citydna/experience"
import { HelpRoundedSlv } from "@citydna/common"
import { Box, makeStyles, Theme } from "@material-ui/core"
import { HelpOutlineRounded as HelpOutlineRoundedIcon } from "@material-ui/icons"

/** ThemeIcon component props */
interface ThemeIconProps {
  theme: string
  onClick?: any
  thumbnail?: string
  index?: number
  appId?: string
}

const useStyles = makeStyles<Theme>({
  icon: {
    width: 24,
    height: 24,
  },
})

export const ThemeIcon: FunctionComponent<ThemeIconProps> = ({
  theme,
  onClick,
  appId,
}) => {
  const classes = useStyles()
  const Icon =
    ThemeIconMap[theme] ||
    (appId === "state-library-images" ? HelpRoundedSlv : HelpOutlineRoundedIcon)

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor={
        appId === "state-library-images" ? "common.black" : "common.white"
      }
      width={28}
      height={28}
      borderRadius="50%"
      mb={1}
      position="relative"
      zIndex={7}
      onClick={onClick}
    >
      <Icon className={classes.icon} title={`Icon describing ${theme}`} />
    </Box>
  )
}
