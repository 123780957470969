import { FC, useCallback, useMemo } from "react"
import { makeStyles, Box, Grid } from "@material-ui/core"
import { FeatureExplorer } from "../common/types"
import { useTourCard } from "../common/TourCard/TourCardProvider"
import { TouchScrollable } from "react-scrolllock"
import { useConfig, useFeaturePropertiesAccessor } from "@citydna/experience"
import { AMStory, MHMArtwork } from "@citydna/experience/types"
import { GalleryTile } from "./GalleryTile"
import { useThemes } from "../common/ThemesProvider"

const useStyles = makeStyles({
  galleryContainer: {
    position: "absolute",
    width: "100%",
    maxHeight: "100%",
    padding: "21px",
    // height of controls + controls bottom positioning
    paddingBottom: "140px",
    overflow: "auto",
  },
})

export type GalleryTileClickProps = {
  featureObjectId?: number
  id?: number
  thumbnail: string
  xy: { x: number; y: number }
}

type GalleryProps = {
  pointsOfInterest?: FeatureExplorer | Array<AMStory> | Array<MHMArtwork> | undefined
}

export const Gallery: FC<GalleryProps> = ({ pointsOfInterest }) => {
  const config = useConfig()
  const { onOpenTourCard } = useTourCard()
  const { activeThemes } = useThemes()

  const accessor = useFeaturePropertiesAccessor()

  const handleClick = useCallback(
    ({ featureObjectId, thumbnail, xy, id }: GalleryTileClickProps) => {
      if (featureObjectId && pointsOfInterest) {
        const feature = (pointsOfInterest as FeatureExplorer)[0].features.find(
          (feat) => feat.properties?.OBJECTID === featureObjectId
        )
        if (feature) {
          onOpenTourCard(
            {
              ...feature.properties,
              loadedThumbnail: thumbnail,
            },
            xy
          )
        }
        return
      }

      if (id && pointsOfInterest) {
        let feature
        if (config?.id === "aboriginal-melbourne") {
          feature = (pointsOfInterest as Array<AMStory>).find(
            (feature) => feature.id === id
          )
        }
        if (config?.id === "state-library-images") {
          feature = (pointsOfInterest as Array<MHMArtwork>).find(
            (feature) => feature.id === id
          )
        }
        if (feature) {
          onOpenTourCard(
            {
              ...feature,
              loadedThumbnail: thumbnail,
            },
            xy
          )
        }
        return
      }
    },
    [onOpenTourCard, pointsOfInterest]
  )

  const classes = useStyles()

  const features = useMemo(() => {
    if (config.id === "state-library-images" && pointsOfInterest) {
      return (pointsOfInterest as Array<MHMArtwork>).map(
        (artwork) => {
          const accessedValues = accessor(artwork)
          return {
            id: artwork?.id,
            title: accessedValues.title,
            theme: accessedValues.artworkType,
            image: accessedValues.image,
          }
        }
      )
    }
    if (config.id === "aboriginal-melbourne" && pointsOfInterest) {
      return (pointsOfInterest as Array<AMStory>).map((story) => {
        const accessedValues = accessor(story)
        return {
          id: story?.id,
          title: accessedValues.title,
          theme: accessedValues.themeId,
          image: accessedValues.image,
        }
      })
    }
  }, [accessor, config.id, pointsOfInterest])

  // @ts-ignore
  const filteredFeatures = features?.filter((feature) => {
    /** if every theme is off display all results */
    if (activeThemes?.every((theme) => theme.disabled)) {
      return true
    }

    const result = activeThemes?.some(
      (theme) => !theme.disabled && feature.theme === theme.id
    )

    return result
  })

  return (
    <TouchScrollable>
      <Box className={classes.galleryContainer}>
        <Grid container justifyContent="space-evenly" spacing={2}>
          {filteredFeatures?.map((feature: any, idx: number) => (
            <Grid key={`grid-item-${idx}`} item>
              <GalleryTile
                onClick={handleClick}
                // @ts-ignore
                key={`${feature?.OBJECTID || feature?.id || ""}-${idx}`}
                {...feature}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </TouchScrollable>
  )
}
