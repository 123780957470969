import React, { FC, useMemo, memo } from "react"
import { useConfig } from "@citydna/experience"
import { useData } from "../common/DataProvider"
import { useState, useEffect } from "react"
import { Source, Layer } from "react-map-gl"
import {
  ActivePropertyFilters,
  usePropertyFilter,
} from "../common/PropertyFilterProvider"
import { ENVIRONMENT, S3_CONFIG_BUCKET_URL } from "../common/constants"
import { useQuery } from "react-query"
import { getLayerStylesConfig } from "../common/api/queries"
import { MAP_EASE_TO_TRANSITION_DURATION } from "./Map"

export const Layers: FC<{ is3D: boolean }> = memo(({ is3D }) => {
  const config = useConfig()
  /** Set and react to a delayed state of the 3D / 2D view */
  const [is3DDebounced, setIs3DDebounced] = useState<boolean>(is3D)

  /**
   * The mapbox easeTo animation is interrupted by a change in
   * layer styles. Using a timeout we wait for the animation to
   * stop and then change the layer styles to 3D or 2D
   */
  useEffect(() => {
    const timerRef = setTimeout(() => {
      setIs3DDebounced(is3D)
    }, MAP_EASE_TO_TRANSITION_DURATION + 50)
    return () => clearTimeout(timerRef)
  }, [setIs3DDebounced, is3D])

  const { data: layerStylesConfig } = useQuery(
    "layerStyles",
    () =>
      getLayerStylesConfig({
        bucketUrl: S3_CONFIG_BUCKET_URL,
        environment: ENVIRONMENT,
        configId: config?.id,
      }),
    { enabled: !!config }
  )

  const { activePropertyFilters } = usePropertyFilter()
  const data = useData()

  const layerStyles = useMemo(() => {
    if (is3DDebounced) {
      return layerStylesConfig
    } else {
      return {
        ...layerStylesConfig,
        FEATURES_OF_INTEREST: {
          ...layerStylesConfig?.FEATURES_OF_INTEREST,
          paint: {
            ...layerStylesConfig?.FEATURES_OF_INTEREST.paint,
            "fill-extrusion-height": 0,
          },
        },
        DAM_DEVELOPMENTS: {
          ...layerStylesConfig?.DAM_DEVELOPMENTS,
          paint: {
            ...layerStylesConfig?.DAM_DEVELOPMENTS.paint,
            "fill-extrusion-height": 0,
          },
        },
        ALL_FOOTPRINTS: {
          ...layerStylesConfig?.ALL_FOOTPRINTS,
          paint: {
            ...layerStylesConfig?.ALL_FOOTPRINTS.paint,
            "fill-extrusion-height": 0,
          },
        },
      }
    }
  }, [is3DDebounced, layerStylesConfig])

  if (
    !layerStylesConfig ||
    !layerStyles ||
    !data?.pointsOfInterest ||
    !activePropertyFilters ||
    !config?.resources
  ) {
    return null
  }

  return (
    <>
      {config.resources.map((resource) => {
        // @ts-ignore
        let sourceData: any = data.pointsOfInterest?.find(
          // @ts-ignore
          (featureCollection) => resource?.id === featureCollection.id
        )
        const filteredSourceData = {
          ...sourceData,
          features: sourceData?.features?.filter((feature: any) => {
            if (!feature.properties.dna_tags) {
              return true
            } else {
              const dna_tag: keyof ActivePropertyFilters =
                feature.properties.dna_tags
                  .split(",")
                  .filter((tag: string) => tag !== "development")[0]
              return (
                Object.values(activePropertyFilters).every((val) => !val) ||
                activePropertyFilters?.[dna_tag]
              )
            }
          }),
        }

        return (
          <Source
            key={resource.id}
            id={resource.id}
            type="geojson"
            data={filteredSourceData}
          >
            <Layer id={resource.id} {...layerStyles[resource.id]} />
          </Source>
        )
      })}
    </>
  )
})
