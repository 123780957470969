import React, { createContext, useContext, useState } from "react";
const ActiveFeatureContext = createContext(undefined);
/**
 * Tracks the active id of the story
 */
export const ActiveFeatureProvider = ({ children }) => {
    const [activeRefId, setActiveRefId] = useState();
    return (React.createElement(ActiveFeatureContext.Provider, { value: {
            activeRefId,
            setActiveRefId,
        } }, children));
};
export const useActiveFeature = () => {
    const context = useContext(ActiveFeatureContext);
    if (!context)
        throw Error("Not inside <ActiveFeatureProvider />");
    return context;
};
