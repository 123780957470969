import { useReducer, useEffect } from "react";
import { API } from "aws-amplify";
/**
 * Hook that returns an object to help with creating
 * the Legend component for the TH projection datasets
 */
export const useBuildProjectionLegend = ({ query, visualisation, visible, }) => {
    /** state to hold the legend content grabbed from event payload */
    const [legendState, dispatch] = useReducer((prev, action) => ({
        ...prev,
        ...action,
    }), {
        active: false,
        title: undefined,
        body: undefined,
        items: undefined,
        shape: undefined,
    });
    /** update the legend item to pass back up to the map component */
    useEffect(() => {
        if (visible && visualisation.legend) {
            const { legend } = visualisation;
            // dynamicPoint requires its body to be filled from dynamic data
            if (legend.type === "dynamicPoint") {
                API.get(query?.service, query?.url + "?legend_only=true", {}).then((res) => {
                    const dynamicSizes = [res.maxReading, res.halfReading];
                    const color = legend.items[0].color;
                    const dynamicItems = dynamicSizes.map((val) => ({
                        label: String(val),
                        color,
                        height: 40,
                        width: 40,
                    }));
                    dispatch({
                        title: legend.title,
                        body: "DynamicPoint",
                        items: dynamicItems,
                        shape: "point",
                        active: true,
                    });
                });
            }
            else {
                dispatch({
                    title: legend.title,
                    body: "PolygonPolyline",
                    items: legend.items,
                    shape: legend.type,
                    active: true,
                });
            }
        }
        else
            dispatch({
                active: false,
                title: undefined,
                body: undefined,
                items: undefined,
                shape: undefined,
            });
    }, [
        query?.service,
        query?.url,
        visible,
        visualisation,
        visualisation?.legend,
    ]);
    return legendState;
};
