import React, { FC, useEffect, useState } from "react"
import { SearchBar, useSnackbarError } from "@citydna/common"
import { useConfig } from "@citydna/experience"
import { Box, makeStyles, Theme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { useTranslation } from "react-i18next"
import { useDebounce } from "use-debounce"
import { TouchScrollable } from "react-scrolllock"
import { useQuery } from "react-query"
import { PropertySearchResults } from "./PropertySearchResults"
import { useSearchLayer } from "./SearchLayerProvider"
import {
  getParentPropertyFeature,
  getCoMPropertyFromWeaveApi,
} from "../common/api/queries"

const useStyles = makeStyles<Theme, { searchOpen: boolean }>((theme) => ({
  searchBarContainer: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    zIndex: 11,
    padding: theme.spacing(2),
    background: ({ searchOpen }) =>
      searchOpen ? theme.palette.grey[600] : "initial",
  },
  searchResultsContainer: {
    height: "calc(100vh - 124px)",
    position: "absolute",
    top: "90px",
    left: "0px",
    right: "0px",
    overflow: "scroll",
    backgroundColor: theme.palette.grey[600],
    WebkitOverflowScrolling: "touch",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: "100px",
  },
  paper: {
    border: "none",
    width: "100%",
    height: "100%",
  },
}))

const LoadingResults = () => (
  <Box>
    <Skeleton variant="rect" height={56} style={{ marginBottom: "16px" }} />
    <Skeleton variant="rect" height={56} style={{ marginBottom: "16px" }} />
    <Skeleton variant="rect" height={56} style={{ marginBottom: "16px" }} />
    <Skeleton variant="rect" height={56} style={{ marginBottom: "16px" }} />
    <Skeleton variant="rect" height={56} style={{ marginBottom: "16px" }} />
  </Box>
)

export const PropertySearch: FC = () => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [searchInput, setSearchInput] = useState("")
  const [propertyId, setPropertyId] = useState<number | undefined>()
  const config = useConfig()
  const { t } = useTranslation<string>()
  const classes = useStyles({ searchOpen })
  const [debouncedQuery] = useDebounce(searchInput, 500)
  const {
    data: results,
    error,
    isLoading,
  } = useQuery(["propertySearch", debouncedQuery], () =>
    getCoMPropertyFromWeaveApi(debouncedQuery)
  )
  const [, setParentFeatureGeojson] = useSearchLayer()

  const { data } = useQuery(["parentProperty", propertyId], () =>
    getParentPropertyFeature(propertyId)
  )

  /** set the parent feature once returned from the API */
  useEffect(() => {
    if (data) {
      setParentFeatureGeojson(data)
    }
  }, [data, setParentFeatureGeojson])

  useSnackbarError(error, t("search.notificationError"))

  const handleClear = () => {
    setSearchInput("")
    setSearchOpen(false)
  }

  const handleClick = (result: Record<string, any>) => {
    /** set the property ID from the selected search result */
    setPropertyId(result.id)
    /** Set the name of the property and close the search */
    setSearchInput(result.display1.split(": ")[1])
    setSearchOpen(false)
  }

  if (!config?.propertySearch) {
    return null
  }

  return (
    <Box className={classes.searchBarContainer}>
      <SearchBar
        width={1}
        placeholder={t("search.placeholder")}
        onFocus={() => {
          if (!searchOpen) {
            setSearchOpen(true)
          }
        }}
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        onClear={handleClear}
        forceShowClear={searchOpen}
        searchIconPosition="left"
      />

      {searchOpen && (
        <TouchScrollable>
          <Box className={classes.searchResultsContainer}>
            {isLoading && <LoadingResults />}
            {results?.results?.length && (
              <PropertySearchResults
                results={results?.results}
                onClick={handleClick}
              />
            )}
          </Box>
        </TouchScrollable>
      )}
    </Box>
  )
}

export default PropertySearch
