import { useEffect, useState } from "react";
import { useCurrentRoom } from "./useCurrentRoom";
/**
 * Manage the number of users connected to the room, only use this in one screen of you application.
 *
 * Given a max number of occupancies, will maintain number of users admitted and return those that are only admitted.
 *
 * Hooks used: useCurrentRoom()
 */
export function useWaitingRoom({ roomCapacity, shouldEmit = true, }) {
    const { devices, emit } = useCurrentRoom();
    const [currentlyAdmittedDevices, setCurrentlyAdmittedDevices] = useState([]);
    useEffect(() => {
        setCurrentlyAdmittedDevices((currentlyAdmittedDevices) => {
            return (devices
                .reduce((accumulator, device) => {
                // ensure we only look at users
                if (device.info.deviceType !== "EXTERNAL") {
                    return accumulator;
                }
                // if a user is already in a room don't kick them out
                if (currentlyAdmittedDevices.some((admittedDevice) => admittedDevice.id === device.id)) {
                    return [...accumulator, device];
                }
                // if a user is not included and there is room let them in
                if (!currentlyAdmittedDevices.some((admittedDevice) => admittedDevice.id === device.id) &&
                    accumulator.length < roomCapacity) {
                    if (shouldEmit) {
                        emit("citydna:invitation-to-enter", {
                            member: { id: device.id },
                        });
                    }
                    return [...accumulator, device];
                }
                // no space left, so ensure they are not included
                if (shouldEmit) {
                    emit("citydna:denied-entry", { member: { id: device.id } });
                }
                return accumulator;
            }, [])
                // remove a device that was in the room but is no longer
                .filter((admittedDevice) => devices.some((device) => device.id === admittedDevice.id)));
        });
    }, [devices, roomCapacity, emit, shouldEmit]);
    return currentlyAdmittedDevices;
}
