import { CountryAndConnections as CountryAndConnectionsIcon, UnderstandingTheLand as UnderstandingTheLandIcon, NegotiatingTwoWorlds as NegotiatingTwoWorldsIcon, EnduringCulture as EnduringCultureIcon, ResistanceAndActivism as ResistanceAndActivismIcon, ImpactOfColonisation as ImpactOfColonisationIcon, SpiritualityAndCreation as SpiritualityAndCreationIcon, Illustration, Painting, Photograph, Sketch, Poi, Default, Development, Tree, } from "@citydna/common";
/**
 * Map of all themes representing themes across state library and
 * aboriginal map
 */
export const ThemeIconMap = {
    connectingToCountry: CountryAndConnectionsIcon,
    enduringCulture: EnduringCultureIcon,
    theImpactOfColonization: ImpactOfColonisationIcon,
    knowingBeingAndDoingOnCountry: UnderstandingTheLandIcon,
    negotiatingTwoWorlds: NegotiatingTwoWorldsIcon,
    resistanceAndActivism: ResistanceAndActivismIcon,
    spiritualityAndCreation: SpiritualityAndCreationIcon,
    Illustration,
    Painting,
    Photograph,
    Sketch,
    poi: Poi,
    development: Development,
    default: Default,
    TREE_CANOPIES: Tree,
};
